/** @format */

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { getAllPurchases } from "../../../redux/inventoryRedux/inventoryApiCalls";
import { Breadcrumb } from "react-bootstrap";
import Meta from "../../../Components/Meta";
import { useParams } from "react-router-dom";

const formatDate = (dateString) => {
	if (!dateString) return ""; // Handle edge case where dateString is falsy

	// Convert dateString to string format if it's not already
	dateString = dateString.toString();

	if (dateString.length === 8) {
		// Assuming dateString is in yyyymmdd format
		const year = dateString.substring(0, 4);
		const month = dateString.substring(4, 6);
		const day = dateString.substring(6, 8);
		return `${day}-${month}-${year}`;
	} else {
		// Return empty string if dateString is not in expected format
		return "";
	}
};

const PastPurchaseInventoryScreens = () => {
	const { clinicName } = useParams();

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getAllPurchases(clinicName));
	}, [dispatch]);

	const { getAllpurchase } = useSelector((state) => state.getAllPurchases);

	// Flatten the data to have each product as a separate row
	let rowData = [];
	getAllpurchase?.data?.data.forEach((purchase) => {
		purchase.products.forEach((product) => {
			rowData.push({
				PurchaseDate: formatDate(purchase.PurchaseDate), // Format PurchaseDate
				supplierName: purchase.supplierName,
				QuantityPurchased: product.QuantityPurchased,
				BrandName: product.brand.name,
				CostPrice: product.costPrice,
				ExpiryDate: formatDate(product.expiryDate), // Format ExpiryDate
				ProductName: product.productName,
				TotalCost: product.costPrice * product.QuantityPurchased, // Calculate total cost
			});
		});
	});

	const columnDefs = [
		{
			headerName: "Purchase Date",
			field: "PurchaseDate",
			sortable: true,
			filter: true,
		},
		{
			headerName: "Supplier Name",
			field: "supplierName",
			sortable: true,
			filter: true,
		},
		{
			headerName: "Quantity Purchased",
			field: "QuantityPurchased",
			sortable: true,
			filter: true,
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
		},
		{
			headerName: "Brand Name",
			field: "BrandName",
			sortable: true,
			filter: true,
		},
		{
			headerName: "Cost Price/Piece",
			field: "CostPrice",
			sortable: true,
			filter: true,
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
		},
		{
			headerName: "Total Cost",
			field: "TotalCost",
			sortable: true,
			filter: true,
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
		},
		{
			headerName: "Expiry Date",
			field: "ExpiryDate",
			sortable: true,
			filter: true,
			comparator: (dateA, dateB) => {
				const dateAObj = new Date(dateA);
				const dateBObj = new Date(dateB);
				return dateAObj.getTime() - dateBObj.getTime();
			},
		},
		{
			headerName: "Product Name",
			field: "ProductName",
			sortable: true,
			filter: true,
		},
	];

	const defaultColDef = {
		sortable: true,
		filter: true,
		resizable: true,
		flex: 1,
	};

	const gridOptions = {
		columnDefs: columnDefs,
		defaultColDef: defaultColDef,
		rowData: rowData,
		pagination: true,
		paginationPageSize: 10,
		enableRangeSelection: true,
	};

	return (
		<>
			<Meta />
			<Breadcrumb>
				<Breadcrumb.Item href="/admin/purchaseinventory">
					New Purchase
				</Breadcrumb.Item>
				<Breadcrumb.Item href="/admin/pastpurchaseinventory">
					Past Purchases
				</Breadcrumb.Item>
			</Breadcrumb>
			<h1 className="header-center mt-3">Past Purchase Inventory</h1>

			<div className="ag-theme-alpine" style={{ height: 600, width: "100%" }}>
				<AgGridReact {...gridOptions} />
			</div>
		</>
	);
};

export default PastPurchaseInventoryScreens;

/** @format */

import React, { useRef, useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Loader";
import Meta from "../../Components/Meta";
import SearchPatient from "../../Components/SearchPatients";
import {
  getPatientsInBlockedSlot,
  rescheduleAppointmentForPatientinBlockedSlot,
} from "../../redux/receptionRedux/receptionApiCalls";
import { rescheduleAppointmentForPatientinBlockedSlotReset } from "../../redux/receptionRedux/Appointment_Management/rescheduleAppointmentForPatientinBlockedSlot.js";
import { getTimeslots } from "../../redux/userApiCalls";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Message from "../../Components/Message";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useParams } from "react-router-dom";

const BlockedSlots = () => {
  const dispatch = useDispatch();
  const { clinicName } = useParams();
  // Accordian Collapse
  const [open, setOpen] = useState([]);
  const toggle = (id) => {
    if (open.includes(id)) {
      setOpen(open.filter((key) => key !== id));
    } else {
      setOpen([...open, id]);
    }
  };
  const [lgShow, setLgShow] = useState(false);
  const [selectDate, setSelectDate] = useState("Choose Date");
  const [selectedOrNot, setSelectedOrNot] = useState(false);
  const { receptionInfo } = useSelector((state) => state.receptionLogin);
  const doctorAppointmentDates = receptionInfo?.user?.appointmentDates;
  console.log(doctorAppointmentDates + "line 43");
  const [dateOptionNumeric, setDateOptionNumeric] = useState("Choose Date");
  const [dayIdx, setdayIdx] = useState(-1);
  const handleSelection = (eventKey) => {
    const dateString = doctorAppointmentDates[eventKey].date.toString();

    // Extract year, month, and day
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = ("0" + dateString.slice(6, 8)).slice(-2);
    setSelectDate(`${day}-${month}-${year}`);
    setDateOptionNumeric(doctorAppointmentDates[eventKey].date);
    setdayIdx(eventKey);
    setSelectedOrNot(false);
  };

  const { getBlockedPatients } = useSelector(
    (state) => state.getPatientsInBlockedSlots
  );
  console.log(getBlockedPatients?.data?.data);
  const days = [];
  const { allTimeSlots } = useSelector((state) => state.getTimeSlots);
  const { error, loading } = useSelector(
    (state) => state.rescheduleAppointmentForPatientinBlockedSlot
  );

  const formattedDates = doctorAppointmentDates.map((entry) => {
    const dateString = entry.date.toString();

    // Extract year, month, and day
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = ("0" + dateString.slice(6, 8)).slice(-2);

    // Create the formatted date string

    days.push({ currDay: `${day}-${month}-${year}`, key: entry.date });
  });
  const searchForBlocked = () => {
    if (selectDate !== "Choose Date") {
      dispatch(getPatientsInBlockedSlot(dateOptionNumeric, clinicName));
      setSelectedOrNot(true);
    }
  };

  let timeslot = [];
  let isEmpty = true;
  for (let i = 0; i < allTimeSlots?.length; i++) {
    isEmpty = allTimeSlots[i]?.isEmpty;
    timeslot.push({
      isEmpty: isEmpty,
      timeSlot: allTimeSlots[i]?.timeSlot,
      curCount: allTimeSlots[i]?.curCount,
      maxAppointmentsperhour: allTimeSlots[i]?.maxAppointmentsperhour,
    });
  }
  console.log(timeslot);

  const [curCount, setCurCount] = useState("");
  const handleTimeSlot = (timeslot, curcount) => {
    setChangeSlotOption(timeslot);
    setCurCount(curcount);
  };

  const [shiftDate, setShiftDate] = useState("Choose Date");
  const [ShiftDateNumeric, setShiftDateNumeric] = useState("choose");
  const [ShiftDateNumericIndex, setShiftDateNumericIndex] = useState("choose");
  const [prevTimeSlot, setPrevTimeSlot] = useState("null");
  const handleDateSelection = (eventKey) => {
    const dateString = doctorAppointmentDates[eventKey].date.toString();

    // Extract year, month, and day
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = ("0" + dateString.slice(6, 8)).slice(-2);
    setShiftDate(`${day}-${month}-${year}`);
    setShiftDateNumeric(doctorAppointmentDates[eventKey].date);
    setShiftDateNumericIndex(eventKey);
    dispatch(getTimeslots(receptionInfo?.user?.doctorId, eventKey, clinicName));
  };
  const [selectedPatientToShift, setSelectedPatientToShift] = useState("");
  const openModel = (_id) => {
    setPrevTimeSlot(_id);
    console.log(_id.split("@")[1]);
    setSelectedPatientToShift(_id.split("@")[1]);
    setLgShow(true);
    setShiftDate("Choose Date");
    setChangeSlotOption("");
  };

  const [changeSlotOption, setChangeSlotOption] = useState("");
  const handleReschedule = (changedSlot) => {
    console.log(prevTimeSlot.split("@"));
    var params = prevTimeSlot.split("@");
    console.log(params + "line131");
    console.log(changedSlot);
    //656863a81ef700bb6fe66f35 656c54d82d8db5cdd2db70c2 20231208 14.00-15.00 0 20231205 9.00-10.00 3
    console.log(
      params[1],
      params[2],
      ShiftDateNumeric,
      changedSlot,
      curCount + 1,
      dateOptionNumeric,
      params[3],
      ShiftDateNumericIndex,
      params[4],
      dayIdx + "line153"
    );
    dispatch(
      rescheduleAppointmentForPatientinBlockedSlot(
        params[1],
        params[2],
        ShiftDateNumeric,
        changedSlot,
        curCount + 1,
        dateOptionNumeric,
        params[3],
        parseInt(ShiftDateNumericIndex),
        params[4],
        parseInt(dayIdx),
        clinicName
      )
    );
    setLgShow(false);
    dispatch(getPatientsInBlockedSlot(dateOptionNumeric, clinicName));
  };
  console.log(
    JSON.stringify(getBlockedPatients?.data?.data?.patients_in_blockslot) +
      "line152"
  );
  const [showPopup, setShowPopup] = React.useState(false);
  const toggleShowInfoPopup = (e) => {
    setShowPopup(!showPopup);
    e.preventDefault();
    console.log("error");
    dispatch(rescheduleAppointmentForPatientinBlockedSlotReset());
  };
  return (
    <div className="pageSize">
      <Meta />
      {error && (
        <Message variant="danger">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={toggleShowInfoPopup}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {error}
        </Message>
      )}
      {loading && <Loader />}
      <h1 className="header-center">Blocked Slots</h1>
      <Container className="header-center mt-4 mb-5">
        <Row className="justify-content-center">
          <Col md="auto" className="text-center mt-1">
            <h6>
              Select the filters{" "}
              <span className="text-danger">
                <b>*</b>
              </span>
            </h6>
          </Col>
          <Col md="auto" className="text-center mt-1">
            <DropdownButton
              key="down-centered"
              id={`dropdown-button-drop-down-centered`}
              drop="down-centered"
              variant="success"
              title={selectDate}
              onSelect={handleSelection}
            >
              {" "}
              <div className="dropScroll">
                {days.map((day, index) => (
                  <Dropdown.Item eventKey={index}>{day.currDay}</Dropdown.Item>
                ))}
              </div>
            </DropdownButton>
          </Col>
          <Col md="auto" className="text-center mt-1">
            <Button
              id="searchButton1"
              className="btn-primary"
              onClick={searchForBlocked}
            >
              Search
            </Button>
          </Col>
        </Row>
      </Container>

      <Container>
        {selectedOrNot && (
          <div className="scheduled-table">
            {getBlockedPatients?.data?.data?.patients_in_blockslot?.length >
            0 ? (
              getBlockedPatients?.data?.data?.patients_in_blockslot?.map(
                (blockedTimeSlots, i) => (
                  <div>
                    {blockedTimeSlots.blockedPatientsInTimeSlot.length > 0 ? (
                      <Accordion open={open} toggle={toggle} className="mt-2">
                        <AccordionItem>
                          <AccordionHeader targetId={i}>
                            {blockedTimeSlots.timeSlot}
                          </AccordionHeader>
                          <AccordionBody accordionId={i}>
                            <Table
                              striped
                              bordered
                              hover
                              className="custom-table"
                            >
                              <thead>
                                <tr>
                                  <th>S.no</th>
                                  <th>Patient name</th>
                                  <th>Mobile number</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {/* <div className={blockedTimeSlots.timeSlot}></div> */}
                                {blockedTimeSlots.blockedPatientsInTimeSlot?.map(
                                  (
                                    { bookingId, name, user, userPhone, _id },
                                    index
                                  ) => (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>{name}</td>
                                      <td>{userPhone}</td>
                                      <td>
                                        <Button
                                          className="btn btn-primary"
                                          onClick={() =>
                                            openModel(
                                              _id +
                                                "@" +
                                                user +
                                                "@" +
                                                bookingId +
                                                "@" +
                                                blockedTimeSlots.timeSlot +
                                                "@" +
                                                name
                                            )
                                          }
                                        >
                                          <i className="fa fa-arrow-up"></i>
                                        </Button>
                                        <Modal
                                          size="xl"
                                          show={lgShow}
                                          onHide={() => setLgShow(false)}
                                          aria-labelledby="example-modal-sizes-title-xl"
                                        >
                                          <Modal.Header closeButton>
                                            <Modal.Title id="example-modal-sizes-title-xl">
                                              Select Slot
                                            </Modal.Title>
                                          </Modal.Header>
                                          <Modal.Body>
                                            <div className="row offset-md-4">
                                              <DropdownButton
                                                className="col-2 btn"
                                                key="down-centered"
                                                id={`dropdown-button-drop-down-centered`}
                                                drop="down-centered"
                                                variant="success"
                                                title={shiftDate}
                                                onSelect={(eventKey) =>
                                                  handleDateSelection(eventKey)
                                                }
                                              >
                                                {days.map((day, index) => (
                                                  <Dropdown.Item
                                                    eventKey={index}
                                                  >
                                                    {day.currDay}
                                                  </Dropdown.Item>
                                                ))}
                                              </DropdownButton>
                                            </div>
                                            <div className="row offset-md-2">
                                              {shiftDate !== "Choose Date" &&
                                                allTimeSlots?.map(
                                                  (timeslots, index) =>
                                                    !timeslots?.isEmpty ||
                                                    timeslots?.isBlocked ? (
                                                      <Button
                                                        className="btn btn-danger col-md-3 m-1"
                                                        disabled
                                                      >
                                                        <b>
                                                          {timeslots?.timeSlot}{" "}
                                                          Not avaliable
                                                        </b>
                                                      </Button>
                                                    ) : (
                                                      <button
                                                        className={
                                                          changeSlotOption ===
                                                          timeslots?.timeSlot
                                                            ? "btn btn-success col-md-3 col-10 m-1"
                                                            : "btn btn-outline-success col-md-3 col-10 m-1"
                                                        }
                                                        onClick={() =>
                                                          handleTimeSlot(
                                                            timeslots?.timeSlot,
                                                            timeslots?.curCount
                                                          )
                                                        }
                                                      >
                                                        <b>{`${
                                                          timeslots?.timeSlot
                                                        } Avaliable slots: ${
                                                          timeslots?.maxAppointmentsperhour -
                                                          timeslots?.curCount
                                                        } `}</b>
                                                      </button>
                                                      // <Form.Check
                                                      //   onChange={() =>
                                                      //     handleTimeSlot(
                                                      //       timeslots?.timeSlot,
                                                      //       timeslots?.curCount
                                                      //     )
                                                      //   }
                                                      //   className="btn btn-success col-md-3 m-1 col-10"
                                                      //   type="radio"
                                                      //   id={timeslots?.timeSlot}
                                                      //   label={`${
                                                      //     timeslots?.timeSlot
                                                      //   } Avaliable slots: ${
                                                      //     timeslots?.maxAppointmentsperhour -
                                                      //     timeslots?.curCount
                                                      //   } `}
                                                      //   checked={
                                                      //     changeSlotOption ===
                                                      //     timeslots?.timeSlot
                                                      //   }
                                                      // />
                                                    )
                                                )}
                                            </div>
                                          </Modal.Body>
                                          <Modal.Footer>
                                            <Button
                                              onClick={
                                                () =>
                                                  handleReschedule(
                                                    changeSlotOption
                                                  )
                                                // user,
                                                // bookingId,
                                                // changeSlotOption
                                                // getBlockedPatients?.data?.data
                                                // 	?.patients_in_blockslot?.[i]
                                                // 	?.timeSlot
                                              }
                                              className="btn btn-primary"
                                            >
                                              Submit
                                              {/* {
																						getBlockedPatients?.data?.data
																							?.patients_in_blockslot?.[i]
																							?.timeSlot
																					} */}
                                            </Button>
                                          </Modal.Footer>
                                        </Modal>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </AccordionBody>
                        </AccordionItem>
                      </Accordion>
                    ) : (
                      <h6 className="header-center text-danger"></h6>
                    )}
                  </div>
                )
              )
            ) : (
              <h6 className="header-center text-danger">
                No appointments are blocked for the selected date.
              </h6>
            )}
          </div>
        )}
      </Container>
    </div>
  );
};

export default BlockedSlots;

/** @format */

import { useRef, React } from 'react';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { getPrescription } from '../redux/prescriptionRedux/prescriptionApiCalls';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import ReactDOMServer from 'react-dom/server';
import HomeScreens from '../Screens/HomeScreen';
import ViewPrescriptionComponent from './ViewPrescriptionComponent';
import { useReactToPrint } from 'react-to-print';
import './styles.css'; // Import your CSS file with print styles
import { useParams } from 'react-router-dom';

const ViewPrescription = (uid) => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const location = useLocation();
	const reportTemplateRef = useRef(null);
	let element = (
		<div style={{ display: 'flex', flexWrap: 'wrap' }}>Sample Text</div>
	); // ViewPrescription html component needs to be passed;

	const handleGeneratePdf = useReactToPrint({
		content: () => reportTemplateRef.current,
		documentTitle: 'Visitor Pass',
		onBeforeGetContent: () => {
			// Apply styles to the content to add margins
			const content = reportTemplateRef.current;
			content.style.margin = '30mm 0 0 0'; // Adjust the margin as needed
			return content;
		},
		onAfterPrint: () => console.log('Printed PDF successfully!'),
	});

	const backButton = () => {
		history(-1);
	};
	const backURL = '/patientstobevisited';
	console.log(uid);
	return (
		<div className='prescribe'>
			<ViewPrescriptionComponent uid={uid} />
		</div>
	);
};

export default ViewPrescription;

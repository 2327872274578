/** @format */

import { createSlice } from "@reduxjs/toolkit";

const createHistoryTemplateInDoctor = createSlice({
  name: "user",
  initialState: {},
  reducers: {
    createHistoryTemplateInDoctorStart: (state) => {
      state.loading = true;
    },
    createHistoryTemplateInDoctorSuccess: (state, action) => {
      state.loading = false;
      state.createHistoryTemplateInDoctor = action.payload;
      state.error = false;
      state.success = true;
    },
    createHistoryTemplateInDoctorFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    createHistoryTemplateInDoctorReset: (state, action) => {
      state.error = false;
      state.success = false;
    },
  },
});

export const {
  createHistoryTemplateInDoctorStart,
  createHistoryTemplateInDoctorSuccess,
  createHistoryTemplateInDoctorFailure,
  createHistoryTemplateInDoctorReset,
} = createHistoryTemplateInDoctor.actions;
export default createHistoryTemplateInDoctor.reducer;

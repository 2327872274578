/** @format */
import Message from '../../../Components/Message';
import React, { useEffect, useState } from 'react';
import { Container, Col, Row, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, useLocation } from 'react-router-dom';
import Loader from '../../../Components/Loader';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Meta from '../../../Components/Meta';
import { useNavigate } from 'react-router-dom';
//API calls
import { createTemplate } from '../../../redux/doctorRedux/doctorApiCalls';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { createTemplateReset } from '../../../redux/doctorRedux/Template_Management/createTemplate';

import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from '../../../redux/allMessages';
import { useParams } from 'react-router-dom';

//Here we create child for Templates management

const DynamicTemplateCreateRow = ({
	index,
	values,
	handleChange,
	handleDelete,
}) => {
	const [activeBox, setActiveBox] = useState(
		values[`dosage-${index}`]?.includes('O@@') ? false : true
	);
	const [Time, setTime] = useState(
		values[`dosage-${index}`]?.includes('B/F')
			? 'B/F'
			: values[`dosage-${index}`]?.includes('A/F')
			? 'A/F'
			: ''
	);
	const [M, setM] = useState(
		values[`dosage-${index}`]?.includes('M,') ? 'M' : ''
	);
	const [A, setA] = useState(
		values[`dosage-${index}`]?.includes('A,') ? 'A' : ''
	);
	const [N, setN] = useState(
		values[`dosage-${index}`]?.includes('N') ? 'N' : ''
	);
	const [quantity, setQuantity] = useState('');
	const handleFreq = (value) => {
		if (value === 'M') {
			if (M === 'M') setM('');
			else setM('M');
		} else if (value === 'A') {
			if (A === 'A') setA('');
			else setA('A');
		} else if (value === 'N') {
			if (N === 'N') setN('');
			else setN('N');
		}
	};
	useEffect(() => {
		if (Time !== '' && (M === 'M' || A === 'A' || N === 'N')) {
			handleChange(`dosage-${index}`, Time + ',' + M + ',' + A + ',' + N);
			debugger;
			let durationFromMedication =
				duration === 'Days'
					? 1
					: duration === 'Weeks'
					? 7
					: duration === 'Months'
					? 30
					: 365;
			let frequencyFromMedication = M === 'M' ? 1 : 0;
			frequencyFromMedication = frequencyFromMedication + (A === 'A' ? 1 : 0);
			frequencyFromMedication = frequencyFromMedication + (N === 'N' ? 1 : 0);
			frequencyFromMedication =
				frequencyFromMedication *
				Number(values[`duration-${index}`]?.split(' ')[0]) *
				durationFromMedication;
			setQuantity(frequencyFromMedication);
		} else {
			setQuantity(0);
		}
	}, [Time, M, A, N]);

	const activeBoxSetting = () => {
		setActiveBox(!activeBox);
		handleChange(`dosage-${index}`, '');
		setTime('');
		setM('');
		setA('');
		setN('');
	};

	useEffect(() => {
		if (!values[`duration-${index}`]) {
			handleChange(`duration-${index}`, '1 ' + duration + ' ' + repeat);
		}
	}, []);

	const [duration, setDuration] = useState(
		!values[`duration-${index}`]
			? 'Days'
			: values[`duration-${index}`]?.split(' ')[1]
	);
	const [repeat, setRepeat] = useState(
		!values[`duration-${index}`]
			? 'Daily'
			: values[`duration-${index}`]?.split(' ')[2]
	);
	const changeRepeat = (eventKey) => {
		setRepeat(eventKey);
		handleChange(`duration-${index}`, '1 ' + duration + ' ' + eventKey);
	};
	const changeDur = (eventKey) => {
		setDuration(eventKey);
		handleChange(`duration-${index}`, '1 ' + eventKey + ' ' + repeat);
		let durationFromMedication =
			eventKey === 'Days'
				? 1
				: eventKey === 'Weeks'
				? 7
				: eventKey === 'Months'
				? 30
				: 365;
		let frequencyFromMedication = M === 'M' ? 1 : 0;
		frequencyFromMedication = frequencyFromMedication + (A === 'A' ? 1 : 0);
		frequencyFromMedication = frequencyFromMedication + (N === 'N' ? 1 : 0);
		frequencyFromMedication =
			frequencyFromMedication * durationFromMedication * 1;
		setQuantity(frequencyFromMedication);
	};
	const incr = (quan) => {
		let quan1 = Number(quan);
		quan1++;
		console.log(quan, 'hi');
		handleChange(
			`duration-${index}`,
			quan1.toString() + ' ' + duration + ' ' + repeat
		);
		let durationFromMedication =
			duration === 'Days'
				? 1
				: duration === 'Weeks'
				? 7
				: duration === 'Months'
				? 30
				: 365;
		let frequencyFromMedication = M === 'M' ? 1 : 0;
		frequencyFromMedication = frequencyFromMedication + (A === 'A' ? 1 : 0);
		frequencyFromMedication = frequencyFromMedication + (N === 'N' ? 1 : 0);
		frequencyFromMedication =
			frequencyFromMedication * durationFromMedication * quan1;
		setQuantity(frequencyFromMedication);
	};
	const decr = (quan) => {
		quan = Number(quan);
		quan--;
		handleChange(
			`duration-${index}`,
			quan.toString() + ' ' + duration + ' Daily'
		);
		let durationFromMedication =
			duration === 'Days'
				? 1
				: duration === 'Weeks'
				? 7
				: duration === 'Months'
				? 30
				: 365;
		let frequencyFromMedication = M === 'M' ? 1 : 0;
		frequencyFromMedication = frequencyFromMedication + (A === 'A' ? 1 : 0);
		frequencyFromMedication = frequencyFromMedication + (N === 'N' ? 1 : 0);
		frequencyFromMedication =
			frequencyFromMedication * durationFromMedication * quan;
		setQuantity(frequencyFromMedication);
	};

	return (
		<Card style={{ width: '22rem', marginLeft: '1%', marginTop: '1%' }}>
			<Card.Body>
				<Card.Text>
					<Row className='justify-content-end'>
						<button
							className='btn btn-outline-danger'
							onClick={() => handleDelete(index)}>
							<i className='fa fa-trash'></i>
						</button>
					</Row>
					<Row className='mt-2'>
						<Col>
							<input
								className='form-control'
								name={`name-${index}`}
								value={values[`name-${index}`]}
								onChange={(e) => handleChange(`name-${index}`, e.target.value)}
								placeholder='Enter Medicine'
							/>
						</Col>
					</Row>
					<Row className='mt-2'>
						<Col>
							<button
								className='btn btn-primary'
								onClick={() => activeBoxSetting()}>
								<i class='fas fa-sync-alt'></i>
							</button>
							{activeBox ? (
								<>
									<ButtonGroup aria-label='Basic example'>
										<Button
											onClick={() => setTime('B/F')}
											// variant="secondary"
											variant={Time === 'B/F' ? 'success' : 'secondary'}>
											B/F
										</Button>
										<Button
											onClick={() => setTime('A/F')}
											variant={Time === 'A/F' ? 'success' : 'secondary'}>
											A/F
										</Button>
									</ButtonGroup>
									<Button
										onClick={() => handleFreq('M')}
										variant={M === 'M' ? 'success' : 'outline-success'}>
										M
									</Button>
									<Button
										onClick={() => handleFreq('A')}
										variant={A === 'A' ? 'success' : 'outline-success'}>
										A
									</Button>
									<Button
										onClick={() => handleFreq('N')}
										variant={N === 'N' ? 'success' : 'outline-success'}>
										N
									</Button>
									<DropdownButton
										className='mt-2'
										key='down-centered'
										id={`dropdown-button-drop-down-centered`}
										drop='down-centered'
										variant='success'
										title={repeat}
										onSelect={changeRepeat}>
										<Dropdown.Item eventKey={"Doesn't Repeat"}>
											{"Doesn't Repeat"}
										</Dropdown.Item>
										<Dropdown.Item eventKey={'Daily'}>{'Daily'}</Dropdown.Item>
										<Dropdown.Item eventKey={'Weekly'}>
											{'Weekly'}
										</Dropdown.Item>
										<Dropdown.Item eventKey={'Monthly'}>
											{'Monthly'}
										</Dropdown.Item>
										<Dropdown.Item eventKey={'Yearly'}>
											{'Yearly'}
										</Dropdown.Item>
									</DropdownButton>
								</>
							) : (
								<>
									<input
										type='text'
										name={`dosage-${index}`}
										value={values[`dosage-${index}`].split('@@')[1]}
										onChange={(e) =>
											handleChange(`dosage-${index}`, 'O@@' + e.target.value)
										}
									/>
								</>
							)}
						</Col>
					</Row>
					<Row className='mt-2'>
						<Col md='2'>
							<button
								className='btn btn-primary '
								onClick={() =>
									decr(values[`duration-${index}`]?.split(' ')[0])
								}>
								<i className='fas fa-minus'></i>
							</button>
						</Col>
						<Col md='4'>
							<input
								className='form-control'
								type='text'
								name={`duration-${index}`}
								value={values[`duration-${index}`]?.split(' ')[0]}
								onChange={(e) =>
									handleChange(
										`duration-${index}`,
										e.target.value + ' ' + duration + ' ' + repeat
									)
								}
							/>
						</Col>
						<Col md='2'>
							<button
								className='btn btn-primary '
								onClick={() =>
									incr(values[`duration-${index}`]?.split(' ')[0])
								}>
								<i className='fas fa-plus'></i>
							</button>
						</Col>
						<Col md='4'>
							<DropdownButton
								key='down-centered'
								id={`dropdown-button-drop-down-centered`}
								drop='down-centered'
								variant='success'
								title={duration}
								onSelect={changeDur}>
								<Dropdown.Item eventKey={'Days'}>{'Days'}</Dropdown.Item>
								<Dropdown.Item eventKey={'Weeks'}>{'Weeks'}</Dropdown.Item>
								<Dropdown.Item eventKey={'Months'}>{'Months'}</Dropdown.Item>
								<Dropdown.Item eventKey={'Years'}>{'Years'}</Dropdown.Item>
							</DropdownButton>
						</Col>
					</Row>
					{/* <Row className='mt-2'>
						<Col>
							<input
								className='form-control'
								type='text'
								value={`Quantity: ${quantity}`}
							/>
						</Col>
					</Row> */}
				</Card.Text>
			</Card.Body>
		</Card>
	);
};

const AddTemplateScreen = () => {
	const { clinicName } = useParams();
	const dispatch = useDispatch();
	const history = useNavigate();
	const location = useLocation();
	// success and failure Message

	const createTemplateData = useSelector((state) => state.createTemplate);
	const createTemplatesuccess = createTemplateData.success;
	const createTemplateerror = createTemplateData.error;
	const createTemplateloading = createTemplateData.loading;

	/////////////////////////////////////////////////////

	const doctorLogin = useSelector((state) => state.doctorLogin);
	const { doctorInfo } = doctorLogin;

	console.log(doctorInfo?.user?._id);

	// Create Template

	const [templateName, setTemplateName] = useState('');
	const [rows, setRows] = useState([]);
	const [values, setValues] = useState({});

	const handleChange = (name, value) => {
		// const { name, value } = event.target;
		setValues((prevValues) => ({ ...prevValues, [name]: value }));
	};

	const handleAddRow = () => {
		setRows((prevRows) => [...prevRows, Date.now()]);
	};

	const handleDeleteRow = (index) => {
		const updatedValues = { ...values };
		Object.keys(updatedValues)
			.filter((key) => key.includes(index))
			.forEach((key) => delete updatedValues[key]);

		setValues(updatedValues);

		setRows((prevRows) => prevRows.filter((rowIndex) => rowIndex !== index));
	};
	useEffect(() => {
		handleAddRow();
	}, []);

	console.log(rows);
	console.log(values);

	const createSubmitHandler = () => {
		if (templateName === '') {
			alert('Template name cannot be empty');
		} else if (
			Object.keys(values).length % 3 !== 0 ||
			Object.keys(values).length / 3 !== rows.length
		) {
			alert('Enter all the required medication fields');
		} else {
			// console.log(Object.keys(valuesTest).length % 3);
			// console.log(Object.keys(valuesTest).length / 3);

			let extractedValues = [];
			let flag = false;
			Object.keys(values).forEach((key) => {
				let id = key.replace(/-\d+$/, '');

				if (key.includes('dosage')) {
					let index = key.split('-')[1];
					let dosage = values[`dosage-${index}`];
					if (
						!dosage?.includes('A/F') &&
						!dosage?.includes('B/F') &&
						!dosage?.includes('O@@')
					) {
						flag = true;
					}
				}
			});

			if (flag) {
				alert('Please Select B/F or A/F for the entered medicines.');
			} else {
				Object.keys(values).forEach((key) => {
					let id = key.replace(/-\d+$/, '');

					if (key.includes('name')) {
						let index = key.split('-')[1];
						let name = values[`name-${index}`];
						let dosage = values[`dosage-${index}`];
						let duration = values[`duration-${index}`];

						extractedValues?.push({
							name: name,
							dosage: dosage,
							duration: duration,
						});
					}
				});
				const medicines = extractedValues;

				dispatch(
					createTemplate(
						doctorInfo?.user?._id,
						templateName,
						medicines,
						clinicName
					)
				);
				setTemplateName('');
				setRows([]);
				handleAddRow();
				setValues([]);
			}
		}
	};

	// const createSubmitHandler = () => {
	//   if (templateName === "") {
	//     alert("Template name cannot be empty");
	//   } else {
	//     const medicinesData = JSON.parse(JSON.stringify(values));
	//     let flag = 1;
	//     const extractedValues = [];
	//     const keys = Object.keys(medicinesData);
	//     if (keys.length == 0) {
	//       alert("Please add medicines");
	//       flag = 0;
	//     } else {
	//       for (let i = 0; i < keys.length; i += 3) {
	//         const nameKey = keys[i];
	//         const dosageKey = keys[i + 1];
	//         const durationKey = keys[i + 2];

	//         const nameValue = medicinesData[nameKey];
	//         const dosageValue = medicinesData[dosageKey];
	//         const durationValue = medicinesData[durationKey];

	//         if (nameValue == "") {
	//           flag = 0;
	//           alert("Medicine name cannot be empty");
	//           break;
	//         } else if (dosageValue == "") {
	//           flag = 0;
	//           alert("Dosage cannot be empty");
	//           break;
	//         } else if (durationValue == "") {
	//           flag = 0;
	//           alert("Duration cannot be empty");
	//           break;
	//         } else if (
	//           Object.keys(values).length % 3 !== 0 ||
	//           Object.keys(values).length / 3 !== rows.length
	//         ) {
	//           alert("Enter all the required medication fields");
	//         } else {
	//           extractedValues.push({
	//             name: nameValue,
	//             dosage: dosageValue,
	//             duration: durationValue,
	//           });
	//         }
	//       }
	//     }
	//     console.log(extractedValues);
	//     const medicines = extractedValues;
	//     console.log(flag);

	//     // console.log(medicines);
	//     // console.log(templateName);
	//     if (flag == 1) {
	//       dispatch(
	//         createTemplate(doctorInfo?.user?._id, templateName, medicines)
	//       );
	//       setTemplateName("");
	//       setRows([]);
	//       handleAddRow();
	//       setValues([]);
	//     }
	//   }
	// };

	//ALerts//////////////////////////////////////////////////////////////////

	const [timerRunning, setTimerRunning] = useState(false);
	useEffect(() => {
		dispatch(allMessagesStart());
	}, []);
	const alertmsg = useSelector((state) => state.allMessages);

	const [showPopup8, setShowPopup8] = React.useState(false);
	const toggleShowInfoPopup8 = (e) => {
		setShowPopup8(!showPopup8);
		e.preventDefault();
		console.log('error');
		dispatch(createTemplateReset());
	};

	useEffect(() => {
		if (createTemplatesuccess && !timerRunning) {
			setTimerRunning(true);

			const timer = setTimeout(() => {
				// Clear the alert and stop the timer after 3 seconds
				dispatch(createTemplateReset());

				setTimerRunning(false);
			}, 3000);

			// Clean up the timer if the component unmounts or the alert changes
		}
	}, [createTemplatesuccess, dispatch]);
	const handleClear = () => {
		setTemplateName('');
		setValues({});
		setRows([]);
	};

	return (
		<div className='pageSize'>
			<Meta />

			<h2 className='header-center mt-3 mb-3'>Add Medicine Template</h2>
			{createTemplateerror && (
				<Message variant='danger'>
					<button
						type='button'
						className='close'
						data-dismiss='alert'
						aria-label='Close'
						onClick={toggleShowInfoPopup8}>
						<span aria-hidden='true'>&times;</span>
					</button>
					{createTemplateerror}
				</Message>
			)}
			{createTemplateloading && <Loader />}

			<div>
				<Container>
					<Row className='justify-content-center mt-3'>
						<Col md='auto' className='text-center mt-1'>
							<div>Template Name: </div>
						</Col>
						<Col md='6' className='text-center mt-1'>
							<input
								type='text'
								className='col-12 form-control'
								value={templateName}
								onChange={(e) => setTemplateName(e.target.value)}
							/>
						</Col>
					</Row>

					<Row className='justify-content-center mt-3'>
						{rows.map((rowIndex) => (
							<Col md='4' className='text-center mt-1'>
								<DynamicTemplateCreateRow
									key={rowIndex}
									index={rowIndex}
									values={values}
									handleChange={handleChange}
									handleDelete={handleDeleteRow}
								/>
							</Col>
						))}
					</Row>

					<div className='row mt-4 mb-2 section'>
						<button
							className='btn btn-secondary offset-md-5'
							onClick={handleAddRow}>
							+ Add more medicines
						</button>
					</div>
				</Container>
				<Container className='mt-4'>
					<div className='row mt-4 mb-2 offset-md-10 section'>
						<button className='btn btn-success' onClick={createSubmitHandler}>
							Submit
						</button>
						<button
							className='offset-md-1 btn btn-danger'
							onClick={handleClear}>
							Clear
						</button>
					</div>
				</Container>
			</div>
		</div>
	);
};

export default AddTemplateScreen;

/** @format */

import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from '../../redux/allMessages';

import { persistor } from '../store';

import axios from 'axios';
import { SERVER_URL } from '../../App';

import {
	createInventoryCategorySuccess,
	createInventoryCategoryFailure,
	createInventoryCategoryStart,
} from './CategoryRedux/createInventoryCategory';

import {
	getInventoryCategorySuccess,
	getInventoryCategoryFailure,
	getInventoryCategoryStart,
} from './CategoryRedux/getInventoryCategory';

import {
	createSupplierSuccess,
	createSupplierFailure,
	createSupplierStart,
} from './SupplierRedux/createSupplier';

import {
	editSupplierSuccess,
	editSupplierFailure,
	editSupplierStart,
} from './SupplierRedux/editSupplier';

import {
	getSuppliersSuccess,
	getSuppliersFailure,
	getSuppliersStart,
} from './SupplierRedux/getSuppliers';

import {
	createInventoryItemSuccess,
	createInventoryItemFailure,
	createInventoryItemStart,
} from './InventoryItem/createInventoryItem';

import {
	editInventoryItemSuccess,
	editInventoryItemFailure,
	editInventoryItemStart,
} from './InventoryItem/editInventoryItem';

import {
	getInventoryItemsForBillingSuccess,
	getInventoryItemsForBillingFailure,
	getInventoryItemsForBillingStart,
} from './InventoryItem/getInventoryItemsForBilling';

import {
	newPurchaseFromSupplierSuccess,
	newPurchaseFromSupplierFailure,
	newPurchaseFromSupplierStart,
} from './SupplierRedux/newPurchaseFromSupplier';

import {
	getPurchaseByIdSuccess,
	getPurchaseByIdFailure,
	getPurchaseByIdStart,
} from './SupplierRedux/getPurchaseById';

import {
	patientInventoryPurchaseSuccess,
	patientInventoryPurchaseFailure,
	patientInventoryPurchaseStart,
} from './InventoryItem/patientInventoryPurchase';

import {
	clearDueBillSuccess,
	clearDueBillFailure,
	clearDueBillStart,
} from './InventoryItem/clearDueBill';
import {
	getAllPurchaseStart,
	getAllPurchaseSuccess,
	getAllPurchaseFailure,
	getAllPurchaseReset,
} from './SupplierRedux/getAllPurchases';
import {
	getBillFailure,
	getBillReset,
	getBillStart,
	getBillSuccess,
} from './InventoryItem/getBill';
export const createInventoryCategory =
	(categoryName, description, clinicname) => async (dispatch) => {
		try {
			console.log('11223344');
			dispatch(createInventoryCategoryStart());
			const username = localStorage.getItem('username');
			const token = localStorage.getItem('token-user');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': 'true',
					username: username,
					Authorization: `Bearer ${token}`,
					clinicname: 'kavithadentalcare',
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/inventory/createInventoryCategory`,
				{ categoryName, description },
				config
			);

			// console.log(data.user);
			dispatch(createInventoryCategorySuccess(data));
			dispatch(
				allMessagesSuccess(`Succesfully created category : ${categoryName}`)
			);
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(
				createInventoryCategoryFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error creating category'));
		}
	};

export const getInventoryCategory = (clinicname) => async (dispatch) => {
	try {
		console.log('11223344', clinicname);
		dispatch(getInventoryCategoryStart());
		const username = localStorage.getItem('username');
		const token = localStorage.getItem('token-user');
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
				clinicname: 'kavithadentalcare',
				username: username,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/inventory/getInventoryCategory`,
			config
		);

		// console.log(data.user);
		dispatch(getInventoryCategorySuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
		dispatch(
			getInventoryCategoryFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
		dispatch(allMessagesFailure('Error fetching categories'));
	}
};

export const createSupplier =
	(name, email, contactNumber, presentAddress, clinicname) =>
	async (dispatch) => {
		try {
			console.log('11223344');
			dispatch(createSupplierStart());
			const username = localStorage.getItem('username');
			const token = localStorage.getItem('token-user');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': 'true',
					username: username,
					Authorization: `Bearer ${token}`,
					clinicname: 'kavithadentalcare',
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/inventory/createSupplier`,
				{ name, email, contactNumber, presentAddress },
				config
			);

			// console.log(data.user);
			dispatch(createSupplierSuccess(data));
			dispatch(allMessagesSuccess('New Supplier Created Succesfully'));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(
				createSupplierFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error creating new supplier'));
		}
	};

export const editSupplier =
	(name, email, contactNumber, presentAddress, id, clinicname) =>
	async (dispatch) => {
		try {
			console.log('11223344');
			dispatch(editSupplierStart());
			const username = localStorage.getItem('username');
			const token = localStorage.getItem('token-user');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': 'true',
					username: username,
					Authorization: `Bearer ${token}`,
					clinicname: 'kavithadentalcare',
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/inventory/editSupplier`,
				{ name, email, contactNumber, presentAddress, id },
				config
			);

			// console.log(data.user);
			dispatch(editSupplierSuccess(data));
			dispatch(allMessagesSuccess('Supplier details edited succesfully'));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(
				editSupplierFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error editing supplier details'));
		}
	};

export const getSuppliers = (clinicname) => async (dispatch) => {
	try {
		console.log('11223344');
		dispatch(getSuppliersStart());
		const username = localStorage.getItem('username');
		const token = localStorage.getItem('token-user');
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': 'true',
				username: username,
				Authorization: `Bearer ${token}`,
				clinicname: 'kavithadentalcare',
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/inventory/getSuppliers`,

			config
		);

		// console.log(data.user);
		dispatch(getSuppliersSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
		dispatch(
			getSuppliersFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
		dispatch(allMessagesFailure('Error fetching supplier details'));
	}
};

export const createInventoryItem =
	(
		name,
		description,
		unit,
		selling_price,
		category,
		categoryId,
		minStockLevel,
		clinicname
	) =>
	async (dispatch) => {
		try {
			console.log('11223344');
			dispatch(createInventoryItemStart());
			const username = localStorage.getItem('username');
			const token = localStorage.getItem('token-user');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': 'true',
					username: username,
					Authorization: `Bearer ${token}`,
					clinicname: 'kavithadentalcare',
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/inventory/createInventoryItem`,
				{
					name,
					description,
					unit,
					selling_price,
					category,
					categoryId,
					minStockLevel,
				},
				config
			);

			// console.log(data.user);
			dispatch(createInventoryItemSuccess(data));
			dispatch(allMessagesSuccess('Item added to inventory succesfully'));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(
				createInventoryItemFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error adding inventory item'));
		}
	};

export const editInventoryItem =
	(
		name,
		description,
		unit,
		selling_price,
		category,
		categoryId,
		minStockLevel,
		discountinue,
		id,
		status,
		clinicname
	) =>
	async (dispatch) => {
		try {
			console.log('11223344');
			dispatch(editInventoryItemStart());
			const username = localStorage.getItem('username');
			const token = localStorage.getItem('token-user');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': 'true',
					username: username,
					Authorization: `Bearer ${token}`,
					clinicname: 'kavithadentalcare',
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/inventory/editInventoryItem`,
				{
					name,
					description,
					unit,
					selling_price,
					category,
					categoryId,
					minStockLevel,
					discountinue,
					id,
					status,
				},
				config
			);

			// console.log(data.user);
			dispatch(editInventoryItemSuccess(data));
			dispatch(allMessagesSuccess('Inventory item edited succesfully'));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(
				editInventoryItemFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error editing inventory item'));
		}
	};

export const getInventoryItemsForBilling = (clinicname) => async (dispatch) => {
	try {
		console.log('11223344');
		dispatch(getInventoryItemsForBillingStart());
		const username = localStorage.getItem('username');
		const token = localStorage.getItem('token-user');
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': 'true',
				username: username,
				Authorization: `Bearer ${token}`,
				clinicname: 'kavithadentalcare',
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/inventory/getInventoryItemsForBilling`,

			config
		);

		// console.log(data.user);
		dispatch(getInventoryItemsForBillingSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
		dispatch(
			getInventoryItemsForBillingFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
		dispatch(allMessagesFailure('Error fetching inventory items'));
	}
};

export const newPurchaseFromSupplier =
	(
		products,
		PurchaseDate,
		TotalPurchaseAmount,
		supplierName,
		supplierId,
		updatedInventoryItems,
		clinicname
	) =>
	async (dispatch) => {
		try {
			console.log('11223344');
			dispatch(newPurchaseFromSupplierStart());
			const username = localStorage.getItem('username');
			const token = localStorage.getItem('token-user');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': 'true',
					username: username,
					Authorization: `Bearer ${token}`,
					clinicname: 'kavithadentalcare',
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/inventory/newPurchaseFromSupplier`,
				{
					products,
					PurchaseDate,
					TotalPurchaseAmount,
					supplierName,
					supplierId,
					updatedInventoryItems,
				},
				config
			);

			// console.log(data.user);
			dispatch(newPurchaseFromSupplierSuccess(data));
			dispatch(allMessagesSuccess('Purchase created succesfully'));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(
				newPurchaseFromSupplierFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error creating purchase'));
		}
	};

export const getPurchaseById = (purchaseId, clinicname) => async (dispatch) => {
	try {
		console.log('11223344');
		dispatch(getPurchaseByIdStart());
		const username = localStorage.getItem('username');
		const token = localStorage.getItem('token-user');
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': 'true',
				username: username,
				Authorization: `Bearer ${token}`,
				clinicname: 'kavithadentalcare',
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/inventory/getPurchaseById/${purchaseId}`,

			config
		);

		// console.log(data.user);
		dispatch(getPurchaseByIdSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
		dispatch(
			getPurchaseByIdFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
		dispatch(allMessagesFailure('Error fetching purchase details'));
	}
};

export const getAllPurchases = (clinicname) => async (dispatch) => {
	try {
		console.log('11223344');
		dispatch(getAllPurchaseStart());
		const username = localStorage.getItem('username');
		const token = localStorage.getItem('token-user');
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': 'true',
				username: username,
				Authorization: `Bearer ${token}`,
				clinicname: 'kavithadentalcare',
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/inventory/getAllPurchases`,

			config
		);

		console.log(data);
		dispatch(getAllPurchaseSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
		dispatch(
			getAllPurchaseFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
		dispatch(allMessagesFailure('Error fetching purchase details'));
	}
};

export const patientInventoryPurchase =
	(
		products,
		billDetails,
		userId,
		userIdx,
		purchaseBasedonCategory,
		paidAmount,
		clinicname
	) =>
	async (dispatch) => {
		try {
			console.log('11223344');
			dispatch(patientInventoryPurchaseStart());
			const username = localStorage.getItem('username');
			const token = localStorage.getItem('token-user');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': 'true',
					username: username,
					Authorization: `Bearer ${token}`,
					clinicname: 'kavithadentalcare',
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/inventory/patientInventoryPurchase`,
				{
					products,
					billDetails,
					userId,
					userIdx,
					purchaseBasedonCategory,
					paidAmount,
				},
				config
			);

			// console.log(data.user);
			dispatch(patientInventoryPurchaseSuccess(data));
			dispatch(allMessagesSuccess('Bill created succesfully'));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(
				patientInventoryPurchaseFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error creating bill'));
		}
	};

export const clearDueBill =
	(
		type,
		billId,
		billIdx,
		userId,
		userIdx,
		AmountPaid,
		prescription_Amount,
		clinicname
	) =>
	async (dispatch) => {
		try {
			console.log('11223344');
			dispatch(clearDueBillStart());
			const username = localStorage.getItem('username');
			const token = localStorage.getItem('token-user');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': 'true',
					username: username,
					Authorization: `Bearer ${token}`,
					clinicname: 'kavithadentalcare',
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/inventory/clearDueBill`,
				{
					type,
					billId,
					billIdx,
					userId,
					userIdx,
					AmountPaid,
					prescription_Amount,
				},
				config
			);

			// console.log(data.user);
			dispatch(clearDueBillSuccess(data));
			dispatch(allMessagesSuccess('Due bill cleared succesfully'));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(
				clearDueBillFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error clearing Due bill'));
		}
	};

export const getBill = (billId, clinicname) => async (dispatch) => {
	try {
		console.log('11223344');
		dispatch(getBillStart());
		const username = localStorage.getItem('username');
		const token = localStorage.getItem('token-user');
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': 'true',
				username: username,
				Authorization: `Bearer ${token}`,
				clinicname: 'kavithadentalcare',
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/inventory/getBill/${billId}`,

			config
		);

		console.log(data);
		dispatch(getBillSuccess(data));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
		dispatch(
			getBillFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
		dispatch(allMessagesFailure('Error fetching bill'));
	}
};

/** @format */

import { createSlice } from "@reduxjs/toolkit";

const editPrescriptionTemplateInDoctor = createSlice({
  name: "prescription",
  initialState: {},
  reducers: {
    editPrescriptionTemplateInDoctorStart: (state) => {
      state.loading = true;
    },
    editPrescriptionTemplateInDoctorSuccess: (state, action) => {
      state.loading = false;
      state.editPrescriptionTemplateInDoctor = action.payload;
      state.error = false;
      state.success = true;
    },
    editPrescriptionTemplateInDoctorFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    editPrescriptionTemplateInDoctorReset: (state, action) => {
      state.error = false;
      state.success = false;
    },
  },
});

export const {
  editPrescriptionTemplateInDoctorStart,
  editPrescriptionTemplateInDoctorSuccess,
  editPrescriptionTemplateInDoctorFailure,
  editPrescriptionTemplateInDoctorReset,
} = editPrescriptionTemplateInDoctor.actions;
export default editPrescriptionTemplateInDoctor.reducer;

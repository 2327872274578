/** @format */

import { persistor } from '../store';
import {
	loginStart,
	loginFailure,
	loginSuccess,
} from './MyProfile_Management/doctorLogin';
import { SERVER_URL } from '../../App';
import {
	getMyAppointmentsFailure,
	getMyAppointmentsRequest,
	getMyAppointmentsSuccess,
} from './Appointment_Management/getMyAppointments';
import {
	getMyTimeSlotGetFailure,
	getMyTimeSlotGetRequest,
	getMyTimeSlotGetSuccess,
} from './Appointment_Management/getMyTimeslots';
import {
	createLeaveSuccess,
	createLeaveFailure,
	createLeaveStart,
} from './Leave_Management/createLeave';
import {
	blockSlotStart,
	blockSlotSuccess,
	blockSlotFailure,
} from './Slot_Management/blockSlots';
import {
	unBlockSlotStart,
	unBlockSlotSuccess,
	unBlockSlotFailure,
} from './Slot_Management/unBlockSlots';
import {
	verifyFailure,
	verifyReset,
	verifySuccess,
	verifyStart,
	logout,
} from './MyProfile_Management/VerifyCode';

import {
	createTemplateStart,
	createTemplateSuccess,
	createTemplateFailure,
	createTemplateReset,
} from './Template_Management/createTemplate';

import {
	getTemplateNamesRequest,
	getTemplateNamesSuccess,
	getTemplateNamesFailure,
	getTemplateNamesReset,
} from './Template_Management/getTemplateNames';

import {
	getTemplateRequest,
	getTemplateSuccess,
	getTemplateFailure,
	getTemplateReset,
} from './Template_Management/getTemplate';

import {
	UpdatePasswordFailure,
	UpdatePasswordStart,
	UpdatePasswordSuccess,
} from './MyProfile_Management/updatePassword';

import {
	getLeavesRequest,
	getLeavesSuccess,
	getLeavesFailure,
	getLeavesReset,
} from './Leave_Management/getLeaves';

import {
	deleteLeavesFailure,
	deleteLeavesRequest,
	deleteLeavesSuccess,
} from './Leave_Management/deleteLeave';

import {
	UpdateTemplateStart,
	UpdateTemplateFailure,
	UpdateTemplateSuccess,
} from './Template_Management/updateTemplate';

import {
	deleteTemplateSuccess,
	deleteTemplateFailure,
	deleteTemplateRequest,
} from './Template_Management/deleteTemplate';

import {
	resendOtpStart,
	resendOtpReset,
	resendOtpFailure,
	resendOtpSuccess,
} from './MyProfile_Management/resendOtp';

import {
	forgotPasswordFailure,
	forgotPasswordStart,
	forgotPasswordSuccess,
} from './MyProfile_Management/forgotPassword';

import {
	resetPasswordFailure,
	resetPasswordStart,
	resetPasswordSuccess,
} from './MyProfile_Management/resetPassword';

import {
	editDoctorRequest,
	editDoctorSuccess,
	editDoctorFailure,
	editDoctorReset,
} from './MyProfile_Management/editDoctor';

import {
	editUserRequest,
	editUserSuccess,
	editUserFailure,
	editUserReset,
} from '../userEdit';

import {
	changeBookingPeriodStart,
	changeBookingPeriodSuccess,
	changeBookingPeriodFailure,
} from './Slot_Management/changeBookingPeriod';

import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from '../../redux/allMessages';

import {
	registerStart,
	registerFailure,
	registerSuccess,
} from '../userRegister';

import {
	paymentStatusFailure,
	paymentStatusRequest,
	paymentStatusSuccess,
} from '../paymentStatus';

import {
	paymentStatusvisitedPatientsRequest,
	paymentStatusvisitedPatientsFailure,
	paymentStatusvisitedPatientsSuccess,
} from '../updatePaymentStatusinVisitedPatient';

import {
	getRevenueForYearFailure,
	getRevenueForYearRequest,
	getRevenueForYearSuccess,
} from './MyProfile_Management/getRevenueForYear';

import {
	getRevenueForParticularYearFailure,
	getRevenueForParticularYearRequest,
	getRevenueForParticularYearSuccess,
} from './MyProfile_Management/getRevenueForParticularYear';

import { getUserDataByCategorySuccess } from '../adminRedux/User_Management/getUserDataByCategory';

import axios from 'axios';

import {
	editClinicDetailsStart,
	editClinicDetailsSuccess,
	editClinicDetailsFailure,
	editClinicDetailsReset,
} from './MyClinic/editClinicDetails';
import {
	prescriptionCreatorStart,
	prescriptionCreatorSuccess,
	prescriptionCreatorFailure,
} from './Prescription_Management/prescriptionCreator';
import {
	GetPrescriptionTemplateNamesStart,
	GetPrescriptionTemplateNamesSuccess,
	GetPrescriptionTemplateNamesFailure,
} from './Prescription_Management/GetPrescriptionTemplateNames';
import {
	GetPrescriptionTemplateStart,
	GetPrescriptionTemplateSuccess,
	GetPrescriptionTemplateFailure,
} from './Prescription_Management/GetPrescriptionTemplate';
import {
	editPrescriptionTemplateInDoctorStart,
	editPrescriptionTemplateInDoctorSuccess,
	editPrescriptionTemplateInDoctorFailure,
} from './Prescription_Management/editPrescriptionTemplateInDoctor.js';

export const logout3 = () => (dispatch) => {
	dispatch(logout());
	// dispatch(userUpdateProfileReset());
	// dispatch(bookingListMyReset());
	// dispatch(tourReviewCreateReset());
	localStorage.removeItem('userInfo');
	localStorage.removeItem('username');
	localStorage.removeItem('adminInfo');
	localStorage.removeItem('doctorInfo');
	localStorage.removeItem('token-user');
	localStorage.removeItem('date');
	localStorage.removeItem('persist:root');
	localStorage.removeItem('sessionStartTime');
	persistor.pause();
	persistor.flush().then(() => {
		return persistor.purge();
	});
};

const api = axios.create({
	baseURL: 'your_api_base_url',
});

api.interceptors.response.use(
	(response) => {
		// Do something with the response data
		return response;
	},
	(error) => {
		// Handle API errors, including JWT expiration
		console.log(error);
		if (error.response && error.response.status === 400) {
			// Logout the user when the token has expired
			//logout3();
		}

		return Promise.reject(error);
	}
);

export const doctorLogin1 =
	(username, password, isMobile, clinicname) => async (dispatch) => {
		try {
			console.log('11223344');
			dispatch(loginStart());

			const config = {
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': 'true',
					clinicname: 'kavithadentalcare',
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/doctor/login`,
				{ username, password, isMobile },
				config
			);
			console.log(data);
			//localStorage.setItem('token-user', data.token);
			// localStorage.setItem("name", data.user.name);
			//localStorage.setItem('doctorInfo', JSON.stringify(data));

			// console.log(data.user);
			localStorage.setItem('sessionStartTime', Date.now().toString());
			dispatch(loginSuccess());
			dispatch(
				allMessagesSuccess(
					'Credentials verified, Please verify the otp sent to your registered email'
				)
			);
		} catch (error) {
			dispatch(allMessagesFailure('Incorrect mobile number or password'));
			dispatch(
				loginFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
		}
	};

export const resendOtp =
	(username, isMobile, clinicname) => async (dispatch) => {
		try {
			console.log('11223344');
			dispatch(resendOtpStart());

			const config = {
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': 'true',
					clinicname: 'kavithadentalcare',
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/doctor/resendOtp`,
				{ username, isMobile },
				config
			);
			console.log(data);
			//localStorage.setItem('token-user', data.token);
			// localStorage.setItem("name", data.user.name);
			//localStorage.setItem('doctorInfo', JSON.stringify(data));

			// console.log(data.user);
			dispatch(resendOtpSuccess());
			dispatch(allMessagesSuccess('OTP sent successfully'));
		} catch (error) {
			dispatch(
				resendOtpFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesSuccess('Error sending OTP'));
		}
	};

export const verifyCode = (mobileNo, otp, clinicname) => async (dispatch) => {
	try {
		console.log('11223344');
		dispatch(verifyStart());

		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': 'true',
				clinicname: 'kavithadentalcare',
			},
		};
		console.log(config);
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/doctor/otpVerification`,
			{ mobilenumber: mobileNo, otp },
			config
		);
		console.log(data);
		localStorage.setItem('token-user', data.token);
		// localStorage.setItem("name", data.user.name);
		localStorage.setItem('doctorInfo', JSON.stringify(data));
		localStorage.setItem(
			'username',
			JSON.stringify('2e09a5b01eac28408404f266726d465c')
		);
		// console.log(data.user);
		dispatch(verifySuccess(data));
		dispatch(loginSuccess(data));
		dispatch(allMessagesSuccess('OTP Verified'));
	} catch (error) {
		dispatch(
			verifyFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
		dispatch(allMessagesSuccess('Error verifying OTP'));
	}
};

export const getMyAppointments =
	(doctor, date, clinicname) => async (dispatch) => {
		try {
			//loader function is called from getMyAppointmentss.js file
			dispatch(getMyAppointmentsRequest());
			console.log(clinicname);
			const token = localStorage.getItem('token-user');
			const username = localStorage.getItem('username');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
					clinicname: 'kavithadentalcare',
					username: username,
				},
			};
			console.log(date + 'line286');

			//get request getMyAppointments function from doctor routes
			const { data } = await axios.get(
				`${SERVER_URL}/api/v1/doctor/getMyAppointments/${doctor}/${date}/${username}`,
				config
			);

			console.log(data);
			dispatch(getMyAppointmentsSuccess(data));
		} catch (error) {
			dispatch(
				getMyAppointmentsFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error fetching the appointments'));
		}
	};

export const getMyTimeslots =
	(selectedname, date, clinicname) => async (dispatch) => {
		try {
			console.log('11223344');
			const tempArr = date.split('/');
			const dateModified = tempArr[2] + '-' + tempArr[1] + '-' + tempArr[0];
			dateModified.trim();
			console.log(dateModified);
			dispatch(getMyTimeSlotGetRequest());
			const username = localStorage.getItem('username');
			const token = localStorage.getItem('token-user');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
					username: username,
					clinicname: 'kavithadentalcare',
				},
			};
			const { data } = await axios.get(
				`${SERVER_URL}/api/v1/booking/getTimeSlots/${selectedname}/${dateModified}`,
				config
			);
			console.log(data.data.docs);
			dispatch(getMyTimeSlotGetSuccess(data.data.docs));
		} catch (error) {
			dispatch(
				getMyTimeSlotGetFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesSuccess('Error fetching timeslots'));
		}
	};

export const createLeave =
	(id, startDate, endDate, reason, clinicname) => async (dispatch) => {
		try {
			dispatch(createLeaveStart());

			const token = localStorage.getItem('token-user');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
					clinicname: 'kavithadentalcare',
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/doctor/createLeave`,
				{ id, reason, startDate, endDate },
				config
			);
			console.log(data);
			dispatch(createLeaveSuccess(data));
			dispatch(allMessagesSuccess('Leave applied successfully'));
		} catch (error) {
			dispatch(
				createLeaveFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesSuccess('Error applying leave'));
		}
	};

export const getLeave = (id, clinicname) => async (dispatch) => {
	try {
		dispatch(getLeavesRequest());

		const token = localStorage.getItem('token-user');
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
				clinicname: 'kavithadentalcare',
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/doctor/getLeaves/${id}`,
			config
		);
		console.log(data);
		dispatch(getLeavesSuccess(data));
	} catch (error) {
		dispatch(
			getLeavesFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
		dispatch(allMessagesSuccess('Error fetching leaves'));
	}
};

export const deleteLeave = (id, arr, clinicname) => async (dispatch) => {
	try {
		dispatch(deleteLeavesRequest());

		const token = localStorage.getItem('token-user');
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
				clinicname: 'kavithadentalcare',
			},
		};
		arr.sort();
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/doctor/deleteLeave`,
			{ id, leavesArr: arr },
			config
		);
		dispatch(deleteLeavesSuccess(data));
		dispatch(allMessagesSuccess('Leaves deleted Successfully'));
	} catch (error) {
		dispatch(
			deleteLeavesFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
		dispatch(allMessagesSuccess('Error deleting leave'));
	}
};

export const blockSlot =
	(
		id,
		blockedSlots,
		speciality,
		dateModified,
		dateIndex,
		slotIdsIdx,
		clinicname
	) =>
	async (dispatch) => {
		try {
			dispatch(blockSlotStart());

			const token = localStorage.getItem('token-user');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
					clinicname: 'kavithadentalcare',
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/doctor/blockSlots`,
				{ id, blockedSlots, speciality, dateModified, dateIndex, slotIdsIdx },
				config
			);
			console.log(data);
			dispatch(blockSlotSuccess(data));
			localStorage.setItem('doctorInfo', JSON.stringify(data));

			dispatch(loginSuccess(data));
			dispatch(allMessagesSuccess('Slot blocked Successfully'));
		} catch (error) {
			dispatch(
				blockSlotFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesSuccess('Error blocking slots'));
		}
	};

export const unBlockSlot =
	(id, unBlockedSlots, dateModified, dateIndex, slotIdsIdx, clinicname) =>
	async (dispatch) => {
		try {
			console.log(id, unBlockedSlots, dateModified);

			dispatch(unBlockSlotStart());

			const token = localStorage.getItem('token-user');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
					clinicname: 'kavithadentalcare',
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/doctor/unBlockSlots`,
				{ id, unBlockedSlots, dateModified, dateIndex, slotIdsIdx },
				config
			);
			console.log(data);
			dispatch(unBlockSlotSuccess());
			dispatch(loginSuccess(data));
			dispatch(allMessagesSuccess('Slot unblocked Successfully'));
		} catch (error) {
			dispatch(
				unBlockSlotFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesSuccess('Error unblocking slots'));
		}
	};

export const createTemplate =
	(id, name, medicines, clinicname) => async (dispatch) => {
		try {
			dispatch(createTemplateStart());

			const token = localStorage.getItem('token-user');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
					clinicname: 'kavithadentalcare',
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/doctor/createTemplate`,
				{ id, name, medicines },
				config
			);
			dispatch(createTemplateSuccess(data));
			dispatch(allMessagesSuccess('Template created Successfully'));
		} catch (error) {
			dispatch(
				createTemplateFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesSuccess('Error creating templates'));
		}
	};

export const getTemplateNames = (id, clinicname) => async (dispatch) => {
	try {
		dispatch(getTemplateNamesRequest());

		const token = localStorage.getItem('token-user');
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
				clinicname: 'kavithadentalcare',
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/doctor/getTemplateNames/${id}`,
			config
		);
		console.log(data);
		dispatch(getTemplateNamesSuccess(data));
	} catch (error) {
		dispatch(
			getTemplateNamesFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
		dispatch(allMessagesSuccess('Error fetching templates'));
	}
};

export const getTemplate1 =
	(id, templateId, clinicname) => async (dispatch) => {
		try {
			dispatch(getTemplateRequest());

			const token = localStorage.getItem('token-user');
			const username = localStorage.getItem('username');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
					clinicname: 'kavithadentalcare',
					username: username,
				},
			};
			console.log(id, templateId);
			const { data } = await axios.get(
				`${SERVER_URL}/api/v1/doctor/getTemplate/${id}/${templateId}`,
				config
			);
			dispatch(getTemplateSuccess(data));
		} catch (error) {
			dispatch(
				getTemplateFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesSuccess('Error fetching template'));
		}
	};

export const updateTemplate =
	(id, templateId, templateData, clinicname) => async (dispatch) => {
		try {
			dispatch(UpdateTemplateStart());

			const token = localStorage.getItem('token-user');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
					clinicname: 'kavithadentalcare',
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/doctor/updateTemplate/${id}/${templateId}`,
				{ templateData: templateData },
				config
			);
			dispatch(UpdateTemplateSuccess(data));
			dispatch(allMessagesSuccess('Template updated Successfully'));
		} catch (error) {
			dispatch(
				UpdateTemplateFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesSuccess('Error updating template'));
		}
	};

export const deleteTemplate =
	(id, templateId, clinicname) => async (dispatch) => {
		try {
			dispatch(deleteTemplateRequest());

			const token = localStorage.getItem('token-user');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
					clinicname: 'kavithadentalcare',
				},
			};
			const { data } = await axios.delete(
				`${SERVER_URL}/api/v1/doctor/deleteTemplate/${id}/${templateId}`,
				config
			);
			dispatch(deleteTemplateSuccess(data));
			dispatch(allMessagesSuccess('Template deleted Successfully'));
		} catch (error) {
			dispatch(
				deleteTemplateFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesSuccess('Error deleting template'));
		}
	};

export const updatePassword =
	(password, clinicname) => async (dispatch, getState) => {
		try {
			dispatch(UpdatePasswordStart());
			console.log('getting3221');

			console.log(password);
			const token = localStorage.getItem('token-user');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
					clinicname: 'kavithadentalcare',
				},
			};

			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/doctor/updatePassword`,
				{ password },
				config
			);
			console.log(data);
			dispatch(UpdatePasswordSuccess(data));
			dispatch(
				allMessagesSuccess('Password updated Successfully. Please login again')
			);
			dispatch(logout());
			// dispatch(userUpdateProfileReset());
			// dispatch(bookingListMyReset());
			// dispatch(tourReviewCreateReset());
			localStorage.removeItem('doctorInfo');
			localStorage.removeItem('token-user');
			localStorage.removeItem('persist:root');
			persistor.pause();
			persistor.flush().then(() => {
				return persistor.purge();
			});
			window.location.href = '/';
		} catch (error) {
			const message =
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message;
			if (message === 'Not authorized, token failed') {
				localStorage.removeItem('persist:root');
				dispatch(logout());
			}
			dispatch(UpdatePasswordFailure(message));
		}
	};

export const forgotPassword =
	(username, email, clinicname) => async (dispatch) => {
		try {
			dispatch(forgotPasswordStart());
			const config = {
				headers: {
					'Content-Type': 'application/json',
					clinicname: 'kavithadentalcare',
				},
			};

			const message = await axios.post(
				`${SERVER_URL}/api/v1/doctor/forgotPassword`,
				{
					username,
					email,
				},
				config
			);
			dispatch(forgotPasswordSuccess());
			dispatch(
				allMessagesSuccess(
					`Reset password link has been sent to the registered email, please reset your password`
				)
			);
		} catch (error) {
			dispatch(
				forgotPasswordFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error seding reset password link to mail'));
		}
	};

export const resetPassword =
	(password, token, clinicname) => async (dispatch) => {
		try {
			dispatch(resetPasswordStart());
			const config = {
				headers: {
					'Content-Type': 'application/json',
					clinicname: 'kavithadentalcare',
				},
			};

			console.log(password, token);
			const data = await axios.post(
				`${SERVER_URL}/api/v1/doctor/resetPassword`,
				{
					password: password,
					token: token,
				},
				config
			);
			dispatch(resetPasswordSuccess());
		} catch (error) {
			dispatch(
				resetPasswordFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
		}
	};

export const editDoctor =
	(
		name,
		mobilenumber,
		email,
		speciality,
		dob,
		gender,
		designation,
		education,
		experience,
		personality,
		address,
		id,
		opConsultationFee,
		clinicname
	) =>
	async (dispatch) => {
		try {
			dispatch(editDoctorRequest());

			const token = localStorage.getItem('token-user');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
					clinicname: 'kavithadentalcare',
				},
			};

			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/doctor/editMyself`,
				{
					name,
					mobilenumber,
					email,
					speciality,
					dob,
					gender,
					designation,
					education,
					experience,
					personality,
					address,
					id,
					opConsultationFee,
				},
				config
			);
			dispatch(editDoctorSuccess(data));
			localStorage.setItem('doctorInfo', JSON.stringify(data));
			console.log(data);
			// console.log(data.user);

			dispatch(loginSuccess(data));
			dispatch(allMessagesSuccess('Successfully changed the information'));
		} catch (error) {
			dispatch(
				editDoctorFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error changing the information'));
		}
	};

export const editUser =
	(
		_id,
		name,
		mobilenumber,
		gender,
		dob,
		address,
		patientIndex,
		isRegisterPatient,
		clinicname,
		bloodGroup
	) =>
	async (dispatch) => {
		try {
			dispatch(editUserRequest());

			const token = localStorage.getItem('token-user');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
					clinicname: 'kavithadentalcare',
				},
			};
			console.log('Api called' + name, mobilenumber, gender, dob, address);
			const username = localStorage.getItem('username');
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/users/editPatient`,
				{
					_id,
					name,
					mobilenumber,
					gender,
					dob,
					address,
					username,
					patientIndex,
					isRegisterPatient,
					bloodGroup,
				},
				config
			);
			dispatch(editUserSuccess(data));
			//   localStorage.setItem("doctorInfo", JSON.stringify(data));
			dispatch(getUserDataByCategorySuccess(data));
			dispatch(allMessagesSuccess('Successfully changed the information'));
		} catch (error) {
			dispatch(
				editUserFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error changing the information'));
		}
	};

export const changeSlots =
	(days, id, appointmentDatesLength, bookingPeriod, clinicname) =>
	async (dispatch) => {
		try {
			dispatch(editDoctorRequest());

			const token = localStorage.getItem('token-user');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
					clinicname: 'kavithadentalcare',
				},
			};

			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/doctor/editMySlots`,
				{
					days,
					id,
					appointmentDatesLength,
					bookingPeriod,
				},
				config
			);
			//dispatch(editDoctorSuccess(data));
			localStorage.setItem('doctorInfo', JSON.stringify(data));

			// console.log(data.user);

			dispatch(loginSuccess(data));
			dispatch(editDoctorSuccess());
			dispatch(allMessagesSuccess('Successfully changed the slots'));
		} catch (error) {
			dispatch(
				editDoctorFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error changing the slots'));
		}
	};

export const register =
	(name, mobilenumber, gender, dob, address, bloodGroup, clinicname) =>
	async (dispatch) => {
		try {
			dispatch(registerStart());

			const token = localStorage.getItem('token-user');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
					clinicname: 'kavithadentalcare',
				},
			};
			const username = localStorage.getItem('username');
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/users/registerPatient`,
				{
					name,
					mobilenumber,
					gender,
					dob,
					address,
					bloodGroup,
					username,
				},
				config
			);
			console.log(data);
			dispatch(registerSuccess(data));
			dispatch(allMessagesSuccess('Patient registered Succesfully.'));
		} catch (error) {
			console.log(error?.response);
			dispatch(
				registerFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error registering patient'));
		}
	};

export const changeBookingPeriod =
	(
		newBookingPeriod,
		existingBookingPeriod,
		tempDays,
		appointmentsArrLength,
		_id,
		lastAppointmentDate,
		clinicname
	) =>
	async (dispatch) => {
		try {
			dispatch(changeBookingPeriodStart());

			const token = localStorage.getItem('token-user');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
					clinicname: 'kavithadentalcare',
				},
			};

			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/doctor/changeBookingPeriod`,
				{
					newBookingPeriod,
					existingBookingPeriod,
					tempDays,
					appointmentsArrLength,
					_id,
					lastAppointmentDate,
				},
				config
			);
			console.log(data);

			dispatch(verifySuccess(data));
			dispatch(loginSuccess(data));
			dispatch(allMessagesSuccess(' Booking Period changed Succesfully.'));
		} catch (error) {
			console.log(error?.response);
			dispatch(
				changeBookingPeriodFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error changing booking period'));
		}
	};

export const paymentStatusUpdate =
	(
		discount,
		reason,
		amount,
		bookingId,
		modeOfPayment,
		billDetails,
		clinicname
	) =>
	async (dispatch) => {
		try {
			dispatch(paymentStatusRequest());
			console.log('line966' + discount);
			const token = localStorage.getItem('token-user');
			const username = localStorage.getItem('username');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
					clinicname: 'kavithadentalcare',
					username: username,
				},
			};

			const data = await axios.post(
				`${SERVER_URL}/api/v1/booking/updatePaymentStatus`,
				{
					discount,
					reason,
					amount,
					bookingId,
					modeOfPayment,
					billDetails,
				},
				config
			);
			dispatch(paymentStatusSuccess());
			dispatch(allMessagesSuccess('Payment updated Succesfully.'));
		} catch (error) {
			dispatch(
				paymentStatusFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error updating payment'));
		}
	};

export const paymentStatusvisitedPatients =
	(
		discount,
		reason,
		amount,
		prescriptionId,
		modeOfPayment,
		billDetails,
		doctorId,
		clinicname
	) =>
	async (dispatch) => {
		try {
			dispatch(paymentStatusvisitedPatientsRequest());
			console.log('line966' + discount);
			const username = localStorage.getItem('username');
			const token = localStorage.getItem('token-user');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
					clinicname: 'kavithadentalcare',
					username: username,
				},
			};

			console.log(username);
			const data = await axios.post(
				`${SERVER_URL}/api/v1/booking/updatePaymentStatusinVisitedPatient`,
				{
					discount,
					reason,
					amount,
					prescriptionId,
					modeOfPayment,
					billDetails,
					doctorId,
				},
				config
			);
			dispatch(paymentStatusvisitedPatientsSuccess());
			dispatch(allMessagesSuccess('Payment updated Succesfully.'));
		} catch (error) {
			dispatch(
				paymentStatusvisitedPatientsFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error updating payment'));
		}
	};

export const getRevenueForYear =
	(doctorId, year, clinicname) => async (dispatch) => {
		try {
			dispatch(getRevenueForYearRequest());

			const token = localStorage.getItem('token-user');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
					clinicname: 'kavithadentalcare',
				},
			};
			console.log('line1088');
			const data = await axios.get(
				`${SERVER_URL}/api/v1/doctor/getRevenueForYear/${doctorId}/${year}`,

				config
			);
			console.log(data);
			dispatch(getRevenueForYearSuccess(data.data.data));
		} catch (error) {
			dispatch(
				getRevenueForYearFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error fetching revenue'));
		}
	};

export const getRevenueForParticularYear =
	(doctorId, year, clinicname) => async (dispatch) => {
		try {
			dispatch(getRevenueForParticularYearRequest());

			const token = localStorage.getItem('token-user');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
					clinicname: 'kavithadentalcare',
				},
			};
			console.log('line1088');
			const data = await axios.get(
				`${SERVER_URL}/api/v1/doctor/getRevenueForParticularYear/${doctorId}/${year}`,

				config
			);
			console.log(data);
			dispatch(getRevenueForParticularYearSuccess(data.data.data));
		} catch (error) {
			dispatch(
				getRevenueForParticularYearFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error fetching revenue'));
		}
	};

export const editClinicMetadata =
	(
		logoUrl,
		address,
		mapLocation,
		clinicSummary,
		contactNumber,
		images,
		services,
		clinicname
	) =>
	async (dispatch) => {
		try {
			dispatch(editClinicDetailsStart());
			const token = localStorage.getItem('token-user');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
					clinicname: 'kavithadentalcare',
				},
			};
			const username = localStorage.getItem('username');
			console.log(username);
			const data = await axios.post(
				`${SERVER_URL}/api/v1/doctor/editClinicMetadata`,
				{
					logoUrl,
					address,
					mapLocation,
					clinicSummary,
					contactNumber,
					images,
					services,
				},
				config
			);
			dispatch(editClinicDetailsSuccess());
			dispatch(allMessagesSuccess('clinic information changed succesfully.'));
		} catch (error) {
			dispatch(
				editClinicDetailsFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error changing clinic information'));
		}
	};
export const PrescriptionCreator =
	(prescriptionTemplateName, prescriptionTemplate, doctorId, clinicname) =>
	async (dispatch) => {
		try {
			dispatch(prescriptionCreatorStart());

			const token = localStorage.getItem('token-user');
			const username = localStorage.getItem('username');

			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
					clinicname: 'kavithadentalcare',
				},
			};

			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/doctor/createPrescriptionTemplateInDoctor`,
				{
					prescriptionTemplateName,
					prescriptionTemplate,
					doctorId,
				},
				config
			);

			localStorage.setItem('doctorInfo', JSON.stringify(data));

			// console.log(data.user);
			dispatch(verifySuccess(data));
			dispatch(loginSuccess(data));
			dispatch(prescriptionCreatorSuccess());
			dispatch(allMessagesSuccess('Template Saved Succesfully.'));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(
				prescriptionCreatorFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error creating template'));
		}
	};

export const GetPrescriptionTemplateNames =
	(doctorId, clinicname) => async (dispatch) => {
		try {
			//loader function is called from getMyAppointmentss.js file
			dispatch(GetPrescriptionTemplateNamesStart());
			console.log(doctorId);
			const token = localStorage.getItem('token-user');
			const username = localStorage.getItem('username');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
					clinicname: 'kavithadentalcare',
				},
			};

			const { data } = await axios.get(
				`${SERVER_URL}/api/v1/doctor/getPrescriptionTemplateNamesandHistoryTemplateNamesInDoctor/${doctorId}`,
				config
			);

			console.log(data);
			dispatch(GetPrescriptionTemplateNamesSuccess(data));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(
				GetPrescriptionTemplateNamesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error fetching templates'));
		}
	};

export const GetPrescriptionTemplate =
	(doctorId, templateIdx, clinicname) => async (dispatch) => {
		try {
			//loader function is called from getMyAppointmentss.js file
			dispatch(GetPrescriptionTemplateStart());

			const token = localStorage.getItem('token-user');
			const username = localStorage.getItem('username');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
					clinicname: 'kavithadentalcare',
				},
			};

			const { data } = await axios.get(
				`${SERVER_URL}/api/v1/doctor/getPrescriptionTemplateInDoctor/${doctorId}`,
				config
			);

			console.log(data);
			dispatch(GetPrescriptionTemplateSuccess(data));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(
				GetPrescriptionTemplateFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error fetching template'));
		}
	};

export const editPrescriptionTemplateInDoctor =
	(
		prescriptionTemplate,
		prescriptionTemplateName,
		prescriptionTemplateIdx,
		doctorId,
		clinicname
	) =>
	async (dispatch) => {
		try {
			dispatch(editPrescriptionTemplateInDoctorStart());

			const token = localStorage.getItem('token-user');
			const username = localStorage.getItem('username');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
					clinicname: 'kavithadentalcare',
				},
			};

			console.log(config, 'line748');
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/doctor/editPrescriptionTemplateInDoctor`,
				{
					prescriptionTemplate,
					prescriptionTemplateName,
					prescriptionTemplateIdx,
					doctorId,
				},
				config
			);

			console.log(data);
			dispatch(editPrescriptionTemplateInDoctorSuccess(data));
			dispatch(allMessagesSuccess(`Succesfully edited template`));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(
				editPrescriptionTemplateInDoctorFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error editing template'));
		}
	};

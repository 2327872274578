/** @format */

import React, { useEffect } from 'react';
import {
	Container,
	Navbar,
	Nav,
	Button,
	Card,
	Row,
	Col,
} from 'react-bootstrap';
import CountUp from 'react-countup';
import './styles.css';
import Hero from './Hero';
import Services from './Services';

function App() {
	useEffect(() => {
		const images = document.querySelectorAll('.gallery-item img');
		images.forEach((img) => {
			img.onload = () => {
				img.classList.add('loaded');
			};
		});
	}, []);

	return (
		<div className='App'>
			<Hero />

			<Container id='about' className='my-5'>
				<h2 className='text-center'>About Us</h2>
				<Row className='align-items-center'>
					<Col md={4} className='text-center'>
						<img
							src='/DocCartoonFemale.png'
							className='img-fluid rounded-circle'
							alt='About Us'
						/>
					</Col>
					<Col md={8}>
						<h3>Excellence in Dentistry in Warangal</h3>
						<p>
							At Kavitha Dental Care, we are deeply committed to delivering
							top-quality dental care tailored to meet the unique needs of every
							patient. We understand that visiting the dentist can be a daunting
							experience for many, which is why we have created a warm,
							welcoming, and comfortable environment designed to put you at ease
							from the moment you step through our doors. Our highly skilled and
							experienced team of dental professionals is not only dedicated to
							providing exceptional care but also passionate about helping you
							and your family achieve and maintain healthy, beautiful smiles for
							life.
						</p>
						<Row>
							{/* <Col md={4} className='text-center'>
								<CountUp start={0} end={25} duration={2} delay={0}>
									{({ countUpRef }) => (
										<div>
											<span ref={countUpRef} />
											<p>Years of Experience</p>
										</div>
									)}
								</CountUp>
							</Col>
							<Col md={4} className='text-center'>
								<CountUp start={0} end={25000} duration={2} delay={0}>
									{({ countUpRef }) => (
										<div>
											<span ref={countUpRef} />+<p>Happy Patients</p>
										</div>
									)}
								</CountUp>
							</Col> */}
							{/* <Col md={4} className='text-center'>
								<CountUp start={0} end={20} duration={2} delay={0}>
									{({ countUpRef }) => (
										<div>
											<span ref={countUpRef} />
											<p>Dentists</p>
										</div>
									)}
								</CountUp>
							</Col> */}
						</Row>
					</Col>
				</Row>
			</Container>

			<Services />

			<Container id='gallery' className='my-5'>
				<h2 className='text-center'>Clinic Gallery</h2>
				<Row className='justify-content-center'>
					<Col md={4} className='gallery-item'>
						<img
							src='/dentalclinicimage.png'
							className='img-fluid'
							alt='Gallery 1'
						/>
					</Col>
					<Col md={4} className='gallery-item'>
						<img
							src='/dentalclinicimage.png'
							className='img-fluid'
							alt='Gallery 1'
						/>
					</Col>
					<Col md={4} className='gallery-item'>
						<img
							src='/dentalclinicimage.png'
							className='img-fluid'
							alt='Gallery 1'
						/>
					</Col>
				</Row>
			</Container>

			{/* <footer
				className='text-center py-4'
				style={{ backgroundColor: '#f8f9fa' }}>
				<Container>
					<Row>
						<Col>
							<h5>Kavitha's Dental Clinic</h5>
							<p>© Copyright 2024 NextGen</p>
						</Col>
						<Col>
							<h5>Get in Touch</h5>
							<p>
								<i className='fas fa-phone'></i> +91-9494260222
							</p>
							<p>
								<i className='fas fa-envelope'></i> kavitha.dental@gmail.com
							</p>
						</Col>
						<Col>
							<h5>Follow Us</h5>
							<div className='social-icons'>
								<i className='fab fa-facebook-f'></i>
								<i className='fab fa-twitter'></i>
								<i className='fab fa-instagram'></i>
								<i className='fab fa-linkedin-in'></i>
							</div>
						</Col>
					</Row>
				</Container>
			</footer> */}
		</div>
	);
}

export default App;

/** @format */

import React from 'react';
import { Button } from 'react-bootstrap';

function HeroSection() {
	return (
		<div>
			<div
				className='hero-section d-none d-md-flex'
				style={{ background: '#98b4d8', color: 'black' }}>
				{/* <img
					src='/hospital_animated.png'
					alt='Hospital'
					className='img-fluid'
				/> */}
				<div className='hero-content'>
					<h1>Kavitha Dental Care</h1>
					<h3>A Complete Multi-Speciality Dental Care</h3>
					{/* <Button variant='primary'>Login</Button> */}
				</div>
			</div>
			<div
				className='hero-section d-flex d-md-none flex-column align-items-center'
				style={{ background: '#98b4d8', color: 'black' }}>
				{/* <img
					src='/hospital_animated.png'
					alt='Hospital'
					className='img-fluid'
				/> */}
				<div className='hero-content'>
					<h6>Kavitha Dental Care</h6>
					<h6>A Complete Multi-Speciality Dental Care</h6>
					{/* <Button variant='primary'>Login</Button> */}
				</div>
			</div>
		</div>
	);
}

export default HeroSection;

/** @format */

import React, { useEffect, useRef } from 'react';
import { Container, Row, Col, Table, Card } from 'react-bootstrap';
import '../styles/Bill.css'; // Add custom styles here
import { getBill } from '../redux/inventoryRedux/inventoryApiCalls';
import { useDispatch, useSelector } from 'react-redux';
import Meta from './Meta';
import { useReactToPrint } from 'react-to-print';
import { useParams } from 'react-router-dom';

const BillScreen = () => {
	const dispatch = useDispatch();

	const { clinicName } = useParams();

	const { receptionInfo } = useSelector((state) => state.receptionLogin);
	const { doctorInfo } = useSelector((state) => state.doctorLogin);

	const billId = useSelector((state) => state.billData);

	useEffect(() => {
		dispatch(getBill(billId?.billDetails?.billId, clinicName));
	}, [billId?.loading]);

	const getbillDetails = useSelector((state) => state.getBill);
	const getbill = getbillDetails?.getbill?.data?.data;

	console.log(getbill);

	const reportTemplateRef = useRef(null);

	const handleDownloadPdf = useReactToPrint({
		content: () => reportTemplateRef.current,
		documentTitle: getbill?.invoiceNumber,
		onBeforeGetContent: () => {
			const content = reportTemplateRef.current;
			return content;
		},
		onAfterPrint: () => console.log('Printed PDF successfully!'),
	});

	return (
		<>
			<Meta />
			<div className='row justify-content-end mt-3 mb-3'>
				<Col md='auto' className='mt-1'>
					<button className='btn btn-success' onClick={handleDownloadPdf}>
						Download as PDF
					</button>
				</Col>
			</div>
			<Container className='bill-container' ref={reportTemplateRef}>
				<Card className='border-primary mb-3'>
					<Card.Header className='d-flex justify-content-between align-items-center'>
						<div>
							<strong>Invoice Number:</strong> {getbill?.invoiceNumber}
						</div>
						<div>
							<strong>Bill Date:</strong> {getbill?.billDate}
						</div>
					</Card.Header>

					<Card.Body>
						<table>
							<tbody>
								<tr>
									<td className='col-4'>
										<img
											src='/kavithaslogo.png'
											alt='logo'
											className='hospital-logo'
											width='160'
											height='140'
										/>
									</td>
									<td>
										<h3>KAVITHA DENTAL CARE</h3>
										{/* <p>Nethaji road, Kamareddy, Telangana 503111</p>
										<p>Mobile : +91 8688044440</p> */}
									</td>
								</tr>
							</tbody>
						</table>

						<div className='d-flex justify-content-between align-items-center mt-3'>
							<h6>
								Patient Name: {billId?.billDetails?.userdata?.data?.data?.name}
							</h6>
							<h6>
								Mobile Number:{' '}
								{billId?.billDetails?.userdata?.data?.data?.mobilenumber}
							</h6>

							<h6>Age: {billId?.billDetails?.userdata?.data?.data?.age}</h6>
							<h6>
								Gender: {billId?.billDetails?.userdata?.data?.data?.gender}
							</h6>
						</div>

						<hr />
						{getbill?.inventory_charges?.AmountToBePaid !== 0 && (
							<Section title='Inventory Charges'>
								<Table bordered style={{ fontSize: '90%', marginBottom: '5%' }}>
									<thead>
										<tr>
											<th>Item Name</th>
											<th>Quantity</th>
											<th>Price</th>
										</tr>
									</thead>
									<tbody>
										{getbill?.inventory_charges?.inventoryDetails?.map(
											(detail) => (
												<tr key={detail._id}>
													<td>{detail.inventory_items.name}</td>
													<td>{detail.inventory_items.quantity}</td>
													<td>{detail.inventory_items.price}</td>
												</tr>
											)
										)}
									</tbody>
								</Table>
							</Section>
						)}
						{getbill?.prescription_charges?.AmountToBePaid !== 0 && (
							<Section title='Consultation Charges'>
								<div className='d-flex justify-content-between align-items-center'></div>
								{
									<Table style={{ fontSize: '90%', marginBottom: '5%' }}>
										<thead>
											<tr>
												<th>Services</th>
												<th>Amount</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>
													Dr {getbill?.prescription_charges?.doctorName}{' '}
													(Consultation Fee)
												</td>
												<td>
													{getbill?.prescription_charges?.prescriptionAmount}
												</td>
											</tr>
											{getbill?.prescription_charges?.additionalCharges
												?.length > 0 &&
												getbill?.prescription_charges?.additionalCharges.map(
													(charge) => (
														<tr key={charge._id}>
															<td>{charge.additional_charge_key}</td>
															<td>{charge.additional_charge_amount}</td>
														</tr>
													)
												)}
										</tbody>
									</Table>
								}
							</Section>
						)}
						<hr />
						<Row>
							<Col>
								{getbill?.inventory_charges?.AmountToBePaid !== 0 && (
									<div className='d-flex justify-content-between'>
										<h6>
											Total Amount: Rs{' '}
											{getbill?.inventory_charges?.AmountToBePaid}
										</h6>
										<h6>
											Due Amount: Rs {getbill?.inventory_charges?.AmountDue}
										</h6>
										{getbill?.inventory_charges?.discount?.discountAmount !==
											0 && (
											<h6>
												Discount: Rs{' '}
												{getbill?.inventory_charges?.discount?.discountAmount}
											</h6>
										)}
										{(receptionInfo || doctorInfo) &&
											getbill?.inventory_charges?.discount?.discountReason !==
												'' && (
												<h6>
													Discount Reason:{' '}
													{getbill?.inventory_charges?.discount?.discountReason}
												</h6>
											)}
									</div>
								)}
								{getbill?.prescription_charges?.AmountToBePaid !== 0 && (
									<div className='d-flex justify-content-between'>
										<h6>
											Due Amount: Rs {getbill?.prescription_charges?.AmountDue}
										</h6>
										<h6>
											Total Amount: Rs{' '}
											{getbill?.prescription_charges?.AmountToBePaid}
										</h6>
										{getbill?.prescription_charges?.discount?.discountAmount !==
											'0' && (
											<h6>
												Discount: Rs{' '}
												{
													getbill?.prescription_charges?.discount
														?.discountAmount
												}
											</h6>
										)}
										{(receptionInfo || doctorInfo) &&
											getbill?.prescription_charges?.discount
												?.discountReason !== '' && (
												<h6>
													Discount Reason:{' '}
													{
														getbill?.prescription_charges?.discount
															?.discountReason
													}
												</h6>
											)}
									</div>
								)}
								<hr />
								<div className='text-right'>
									<h6>Total Amount Paid: Rs {getbill?.AmountPaid}</h6>
									{(receptionInfo || doctorInfo) && (
										<>
											<h6>Amount Paid (Cash): Rs {getbill?.amountPaidCash}</h6>
											<h6>
												Amount Paid (Online): Rs {getbill?.amountPaidOnline}
											</h6>
										</>
									)}
								</div>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			</Container>
		</>
	);
};

const Section = ({ title, children }) => (
	<div className='section'>
		<h4>{title}</h4>
		{children}
	</div>
);

export default BillScreen;

/** @format */

import { createSlice } from '@reduxjs/toolkit';

const createHistoryForPatient = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		createHistoryForPatientStart: (state) => {
			state.loading = true;
		},
		createHistoryForPatientSuccess: (state, action) => {
			state.loading = false;
			state.createHistoryForPatient = action.payload;
			state.error = false;
			state.success = true;
		},
		createHistoryForPatientFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		createHistoryForPatientReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	createHistoryForPatientStart,
	createHistoryForPatientSuccess,
	createHistoryForPatientFailure,
	createHistoryForPatientReset,
} = createHistoryForPatient.actions;
export default createHistoryForPatient.reducer;

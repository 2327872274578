/** @format */

import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from '../../redux/allMessages';

import { persistor } from '../store';

import axios from 'axios';
import { SERVER_URL } from '../../App';

import {
	createHistoryTemplateInDoctorStart,
	createHistoryTemplateInDoctorSuccess,
	createHistoryTemplateInDoctorFailure,
	createHistoryTemplateInDoctorReset,
} from './History_Management/createHistoryTemplateInDoctor';

import {
	editHistoryByIdxStart,
	editHistoryByIdxSuccess,
	editHistoryByIdxFailure,
	editHistoryByIdxReset,
} from './History_Management/editHistoryByIdx';

import {
	editHistoryTemplateInDoctorStart,
	editHistoryTemplateInDoctorSuccess,
	editHistoryTemplateInDoctorFailure,
	editHistoryTemplateInDoctorReset,
} from './History_Management/editHistoryTemplateInDoctor';

import {
	createHistoryForPatientStart,
	createHistoryForPatientSuccess,
	createHistoryForPatientFailure,
} from './History_Management/createHistoryForPatient';

import {
	getHistoryByIdxStart,
	getHistoryByIdxSuccess,
	getHistoryByIdxFailure,
	getHistoryByIdxReset,
} from './History_Management/getHistoryByIdx';

import {
	getHistoryTemplateInDoctorStart,
	getHistoryTemplateInDoctorSuccess,
	getHistoryTemplateInDoctorFailure,
	getHistoryTemplateInDoctorReset,
} from './History_Management/getHistoryTemplateInDoctor';

import {
	getRecentHistoryForDoctorStart,
	getRecentHistoryForDoctorSuccess,
	getRecentHistoryForDoctorFailure,
	getRecentHistoryForDoctorReset,
} from './History_Management/getRecentHistoryForDoctor';

import {
	createCaseInPatientStart,
	createCaseInPatientSuccess,
	createCaseInPatientFailure,
	createCaseInPatientReset,
} from './Patient_Case_Management/createCaseInPatient';

import {
	editCaseInPatientStart,
	editCaseInPatientSuccess,
	editCaseInPatientFailure,
	editCaseInPatientReset,
} from './Patient_Case_Management/editPatientCase';

import {
	getCaseInPatientStart,
	getCaseInPatientSuccess,
	getCaseInPatientFailure,
	getCaseInPatientReset,
} from './Patient_Case_Management/getCaseInPatient';

const username = localStorage.getItem('username');

export const createHistoryTemplateInDoctor =
	(historyTemplate, historyTemplateName, doctorId, clinicname) =>
	async (dispatch) => {
		try {
			console.log('11223344');
			dispatch(createHistoryTemplateInDoctorStart());
			const token = localStorage.getItem('token-user');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': 'true',
					Authorization: `Bearer ${token}`,
					clinicname,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/history/createHistoryTemplateInDoctor`,
				{ historyTemplate, historyTemplateName, doctorId },
				config
			);

			// console.log(data.user);
			dispatch(createHistoryTemplateInDoctorSuccess(data));
			dispatch(
				allMessagesSuccess(
					`Succesfully created template : ${historyTemplateName}`
				)
			);
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(
				createHistoryTemplateInDoctorFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error creating template'));
		}
	};

export const editHistoryByIdx =
	(historyId, index, newHistory, clinicname) => async (dispatch) => {
		try {
			console.log('11223344');
			dispatch(editHistoryByIdxStart());
			const token = localStorage.getItem('token-user');
			const username = localStorage.getItem('username');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': 'true',
					Authorization: `Bearer ${token}`,
					clinicname,
					username: username,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/history/editHistoryByIdx`,
				{ historyId, index, newHistory },
				config
			);

			// console.log(data.user);
			dispatch(editHistoryByIdxSuccess(data));
			dispatch(
				allMessagesSuccess(`Succesfully edited the history for patient`)
			);
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(
				editHistoryByIdxFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error editing history for patient'));
		}
	};

export const editHistoryTemplateInDoctor =
	(
		historyTemplate,
		historyTemplateName,
		historyTemplateIdx,
		doctorId,
		clinicname
	) =>
	async (dispatch) => {
		try {
			console.log('11223344');
			dispatch(editHistoryTemplateInDoctorStart());
			const token = localStorage.getItem('token-user');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': 'true',
					Authorization: `Bearer ${token}`,
					clinicname,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/history/editHistoryTemplateInDoctor`,
				{
					historyTemplate,
					historyTemplateName,
					historyTemplateIdx: Number(historyTemplateIdx),
					doctorId,
				},
				config
			);

			// console.log(data.user);
			dispatch(editHistoryTemplateInDoctorSuccess(data));
			dispatch(allMessagesSuccess(`Succesfully edited the template`));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(
				editHistoryTemplateInDoctorFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error editing history template'));
		}
	};

export const getPatientHistory =
	(historyId, clinicName) => async (dispatch) => {
		try {
			console.log('11223344');
			dispatch(getHistoryByIdxStart());
			const token = localStorage.getItem('token-user');
			const username = localStorage.getItem('username');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': 'true',
					Authorization: `Bearer ${token}`,
					clinicName,
					username: username,
				},
			};
			const { data } = await axios.get(
				`${SERVER_URL}/api/v1/history/getPatientHistory/${historyId}`,

				config
			);

			// console.log(data.user);
			dispatch(getHistoryByIdxSuccess(data));
			//dispatch(allMessagesSuccess(`Succesfully edited the template`));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(
				getHistoryByIdxFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error fetching patient history'));
		}
	};

export const getHistoryTemplateInDoctor =
	(doctorId, index, clinicname) => async (dispatch) => {
		try {
			console.log('11223344');
			dispatch(getHistoryTemplateInDoctorStart());
			const token = localStorage.getItem('token-user');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': 'true',
					Authorization: `Bearer ${token}`,
					clinicname,
				},
			};
			const { data } = await axios.get(
				`${SERVER_URL}/api/v1/history/getHistoryTemplateInDoctor/${doctorId}/${index}`,

				config
			);

			// console.log(data.user);
			dispatch(getHistoryTemplateInDoctorSuccess(data));
			//dispatch(allMessagesSuccess(`Succesfully edited the template`));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(
				getHistoryTemplateInDoctorFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error fetching history templates'));
		}
	};

export const getRecentHistoryForDoctor =
	(historyId, doctorId, clinicname) => async (dispatch) => {
		try {
			console.log('11223344');
			dispatch(getRecentHistoryForDoctorStart());
			const token = localStorage.getItem('token-user');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': 'true',
					Authorization: `Bearer ${token}`,
					clinicname,
				},
			};
			const { data } = await axios.get(
				`${SERVER_URL}/api/v1/history/getRecentHistoryForDoctor/${historyId}/${doctorId}`,

				config
			);

			// console.log(data.user);
			dispatch(getRecentHistoryForDoctorSuccess(data));
			//dispatch(allMessagesSuccess(`Succesfully edited the template`));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(
				getRecentHistoryForDoctorFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error fetching history'));
		}
	};

export const createCaseInPatient =
	(
		historyIdx,
		historyId,
		caseName,
		prescripitionIdArray,
		caseStartDate,
		caseCloseDate,
		userId,
		userIdx,
		doctorId,
		doctorName,
		clinicname
	) =>
	async (dispatch) => {
		try {
			console.log('11223344');
			dispatch(createCaseInPatientStart());
			const token = localStorage.getItem('token-user');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': 'true',
					Authorization: `Bearer ${token}`,
					clinicname,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/history/createCaseInPatient`,
				{
					historyIdx,
					historyId,
					caseName,
					prescripitionIdArray,
					caseStartDate,
					caseCloseDate,
					userId,
					userIdx,
					doctorId,
					doctorName,
				},

				config
			);

			// console.log(data.user);
			dispatch(createCaseInPatientSuccess(data));
			//dispatch(allMessagesSuccess(`Succesfully edited the template`));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(
				createCaseInPatientFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
		}
	};

export const editPatientCase =
	(
		historyIdx,
		historyId,
		caseName,
		prescripitionIdArray,
		caseStartDate,
		caseCloseDate,
		userId,
		userIdx,
		doctorId,
		doctorName,
		clinicname
	) =>
	async (dispatch) => {
		try {
			console.log('11223344');
			dispatch(editCaseInPatientStart());
			const token = localStorage.getItem('token-user');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': 'true',
					Authorization: `Bearer ${token}`,
					clinicname,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/history/editPatientCase`,
				{
					historyIdx,
					historyId,
					caseName,
					prescripitionIdArray,
					caseStartDate,
					caseCloseDate,
					userId,
					userIdx,
					doctorId,
					doctorName,
				},

				config
			);

			// console.log(data.user);
			dispatch(editCaseInPatientSuccess(data));
			//dispatch(allMessagesSuccess(`Succesfully edited the template`));
		} catch (error) {
			dispatch(
				allMessagesFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(
				editCaseInPatientFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
		}
	};

export const getCaseInPatient = (userId, userIdx) => async (dispatch) => {
	try {
		console.log('11223344');
		dispatch(getCaseInPatientStart());
		const token = localStorage.getItem('token-user');
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': 'true',
				username: username,
				Authorization: `Bearer ${token}`,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/history/getCaseInPatient`,
			{
				userId,
				userIdx,
			},

			config
		);

		// console.log(data.user);
		dispatch(getCaseInPatientSuccess(data));
		//dispatch(allMessagesSuccess(`Succesfully edited the template`));
	} catch (error) {
		dispatch(
			allMessagesFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
		dispatch(
			getCaseInPatientFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
	}
};

export const createHistoryForPatient =
	(
		userId,
		userIdx,
		doctorName,
		doctorId,
		createdAt,
		historyData,
		historyId,
		templateName,
		clinicname
	) =>
	async (dispatch) => {
		try {
			console.log('11223344');
			dispatch(createHistoryForPatientStart());
			const token = localStorage.getItem('token-user');
			const username = localStorage.getItem('username');
			const config = {
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': 'true',
					Authorization: `Bearer ${token}`,
					clinicname,
					username: username,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/history/createHistoryForPatient`,
				{
					userId,
					userIdx,
					doctorName,
					doctorId,
					createdAt,
					historyData,
					historyId,
					templateName,
				},

				config
			);

			// console.log(data.user);
			dispatch(createHistoryForPatientSuccess(data));
			dispatch(allMessagesSuccess(`Succesfully created the history`));
		} catch (error) {
			dispatch(
				createHistoryForPatientFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(
				getCaseInPatientFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
			dispatch(allMessagesFailure('Error creating history'));
		}
	};

/** @format */

import React, { useState, useEffect } from 'react';
import '../../Components/Revenue_Components/chart.css';
import Chart from '../../Components/Revenue_Components/Chart';
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
} from 'recharts';
import FeaturedInfo from '../../Components/Revenue_Components/FeaturedInfo';
import './home.css';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../../Components/Message';
import Loader from '../../Components/Loader';
import { getRevenueForParticularYearReset } from '../../redux/doctorRedux/MyProfile_Management/getRevenueForParticularYear.js';
import { getRevenueForYearReset } from '../../redux/doctorRedux/MyProfile_Management/getRevenueForYear.js';
import {
	getRevenueForYear,
	getRevenueForParticularYear,
} from '../../redux/doctorRedux/doctorApiCalls';
import { getDoctorVisitedPatientsforParticularDate } from '../../redux/adminRedux/adminApiCalls';
import { getDoctorVisitedPatientsforParticularDateReset } from '../../redux/adminRedux/Doctor_Management/getDoctorVisitedPatientsforParticularDate.js';
import { useParams } from 'react-router-dom';

const calculatePercentageChange = (currentValue, previousValue) => {
	console.log('percentage function called');
	console.log(previousValue + 'line30');
	if (previousValue === 0 || currentValue === 0) {
		return 0; // Avoid division by zero
	}

	console.log(currentValue, previousValue);
	var max = Math.max(currentValue, previousValue);
	var min = Math.min(currentValue, previousValue);
	return ((max - min) / max) * 100;
	// const percentageChange =
	// 	((currentValue - previousValue) / Math.abs(previousValue)) * 100;
	// return Math.max(Math.min(percentageChange, 100), -100);

	// return (((currentValue - previousValue) / currentValue) * 100).toFixed(1);
};

const calculateTodaysStatistics = (visitedPatientsData, lastDayData) => {
	let cashAmount = 0;
	let onlineAmount = 0;
	console.log('line42');
	console.log(visitedPatientsData);
	if (!visitedPatientsData || visitedPatientsData.length === 0) {
		return [0, 0, 0, 0, 0, 0, 0, 0];
	}
	visitedPatientsData.forEach((appointment) => {
		if (appointment.hasPaid) {
			if (appointment.modeOfPayment === 'Cash') {
				cashAmount += appointment.paidAmount;
			} else if (appointment.modeOfPayment === 'Online') {
				onlineAmount += appointment.paidAmount;
			}
		}
	});
	console.log(lastDayData, cashAmount, onlineAmount + 'line32');
	if (lastDayData === 0) {
		console.log('line58');
		return [
			visitedPatientsData.length,
			cashAmount + onlineAmount,
			cashAmount,
			onlineAmount,
			0,
			0,
			0,
			0,
		];
	} else {
		console.log(
			visitedPatientsData.length,
			lastDayData.opPatientsCount,
			'line70'
		);
		const a = calculatePercentageChange(
			visitedPatientsData.length,
			lastDayData.opPatientsCount
		);
		const x = calculatePercentageChange(
			cashAmount + onlineAmount,
			lastDayData.opPatientsRevenueCash + lastDayData.opPatientsRevenueOnline
		);
		const y = calculatePercentageChange(
			cashAmount,
			lastDayData.opPatientsRevenueCash
		);
		const z = calculatePercentageChange(
			onlineAmount,
			lastDayData.opPatientsRevenueOnline
		);

		return [
			visitedPatientsData.length,
			cashAmount + onlineAmount,
			cashAmount,
			onlineAmount,
			a,
			x,
			y,
			z,
		];
	}
};

// Array of day names

const dayNames = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
];

const last_seven_days_statistics = (last_seven_days_data) => {
	if (!last_seven_days_data) {
		return;
	}
	const last7DaysData = last_seven_days_data.map((dayData) => {
		const dateAsString = dayData.date.toString();
		const year = dateAsString.slice(0, 4);
		const month = dateAsString.slice(4, 6) - 1; // Months are zero-indexed
		const day = dateAsString.slice(6, 8);

		const formattedDate = new Date(year, month, day);
		const dayName = dayNames[formattedDate.getDay()];

		// Calculate total revenue for the day (cash + online)
		const totalRevenue =
			dayData.opPatientsRevenueCash + dayData.opPatientsRevenueOnline;

		return {
			name: dayName,
			revenue: totalRevenue,
		};
	});
	return last7DaysData;
};

const last_Thirty_days_statistics = (last_thirty_days_data) => {
	if (!last_thirty_days_data) {
		return;
	}
	console.log(last_thirty_days_data);
	const last30DaysData = last_thirty_days_data?.map((dayData) => {
		const dateAsString = dayData.date.toString();
		const year = dateAsString.slice(0, 4);
		const month = dateAsString.slice(4, 6) - 1; // Months are zero-indexed
		const day = dateAsString.slice(6, 8);
		let formattedDay = day < 10 ? `0${day}` : day;
		let formattedMonth = month < 10 ? `0${month}` : month;

		let currentDate = `${formattedDay}/${formattedMonth + 1}`;

		// Calculate total revenue for the day (cash + online)
		const totalRevenue =
			dayData.opPatientsRevenueCash + dayData.opPatientsRevenueOnline;

		return {
			name: currentDate, // Get YYYY-MM-DD format
			revenue: totalRevenue,
		};
	});
	console.log(last30DaysData);
	return last30DaysData;
};

const monthNames = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'June',
	'July',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
];
const lastTwelveMonthsWithDefaultRevenue = Array.from(
	{ length: 12 },
	(_, index) => {
		const currentDate = new Date();
		currentDate.setMonth(currentDate.getMonth() - index);
		return {
			monthNumber: currentDate.getMonth() + 1, // Months are zero-indexed, so add 1
			opPatientsRevenueCash: 0,
			opPatientsRevenueOnline: 0,
		};
	}
);

const last_twelve_months_statistics = (last_twelve_months_data) => {
	if (!last_twelve_months_data) {
		return;
	}
	const mergedMonthData = lastTwelveMonthsWithDefaultRevenue?.map(
		(defaultMonth) => {
			const existingDataForMonth = last_twelve_months_data.find(
				(monthData) => monthData.monthNumber === defaultMonth.monthNumber
			);

			return {
				name: monthNames[defaultMonth.monthNumber - 1], // Months are zero-indexed
				revenue:
					(existingDataForMonth
						? existingDataForMonth.opPatientsRevenueCash
						: 0) +
					(existingDataForMonth
						? existingDataForMonth.opPatientsRevenueOnline
						: 0),
			};
		}
	);
	console.log(mergedMonthData);
	return mergedMonthData.reverse();
};

const year_statistics = (last_twelve_months_data) => {
	if (!last_twelve_months_data) {
		return;
	}
	console.log(last_twelve_months_data);
	const mergedMonthData = last_twelve_months_data?.map((defaultMonth) => {
		return {
			name: monthNames[defaultMonth.monthNumber - 1], // Months are zero-indexed
			revenue:
				(defaultMonth ? defaultMonth.opPatientsRevenueCash : 0) +
				(defaultMonth ? defaultMonth.opPatientsRevenueOnline : 0),
		};
	});
	console.log(mergedMonthData);
	return mergedMonthData;
};

export default function Home() {
	const dispatch = useDispatch();
	const { clinicName } = useParams();
	const { doctorInfo } = useSelector((state) => state.doctorLogin);
	const [title, setTitle] = useState('Last 7 days Revenue');
	const getRevenueForParticularYearData = useSelector(
		(state) => state.getRevenueForParticularYear
	);
	const [showMonthDropdown, setShowMonthDropdown] = useState(false);
	const getRevenueForParticularYearDatasuccess =
		getRevenueForParticularYearData.success;
	const getRevenueForParticularYearDataerror =
		getRevenueForParticularYearData.error;
	const getRevenueForParticularYearDataloading =
		getRevenueForParticularYearData.loading;

	const getRevenueForYearData = useSelector((state) => state.getRevenueForYear);
	const getRevenueForYearDatasuccess = getRevenueForYearData.success;
	const getRevenueForYearDataerror = getRevenueForYearData.error;
	const getRevenueForYearDataloading = getRevenueForYearData.loading;
	const getDoctorVisitedPatientsforParticularDateData = useSelector(
		(state) => state.getDoctorVisitedPatientsforParticularDate
	);
	const getDoctorVisitedPatientsforParticularDateDatasuccess =
		getDoctorVisitedPatientsforParticularDateData.success;
	const getDoctorVisitedPatientsforParticularDateDataerror =
		getDoctorVisitedPatientsforParticularDateData.error;
	const getDoctorVisitedPatientsforParticularDateDataloading =
		getDoctorVisitedPatientsforParticularDateData.loading;

	const [timeInterval, setTimeInterval] = useState('7d');

	useEffect(() => {
		const year = new Date().getFullYear();
		const month = new Date().getMonth() + 1;
		const day = new Date().getDate();

		const numericDate = year * 10000 + month * 100 + day;
		dispatch(getRevenueForYear(doctorInfo?.user?._id, 2023, clinicName));
		dispatch(
			getDoctorVisitedPatientsforParticularDate(
				doctorInfo?.user?._id,
				parseInt(numericDate),
				clinicName
			)
		);
	}, []);

	const lastDay =
		getRevenueForYearData?.yearlyCounter?.last_thirtydays.length > 0
			? getRevenueForYearData?.yearlyCounter?.last_thirtydays[
					getRevenueForYearData?.yearlyCounter?.last_thirtydays.length - 1
			  ]
			: 0;

	const statisticsToday = calculateTodaysStatistics(
		getDoctorVisitedPatientsforParticularDateData
			?.getDoctorVisitedPatientsforParticularDate?.data?.data?.visited_patients,
		lastDay
	);

	const last_seven_days =
		getRevenueForYearData?.yearlyCounter?.last_thirtydays.length >= 7
			? 7
			: getRevenueForYearData?.yearlyCounter?.last_thirtydays.length;

	const [lastSevenDaysStatistics, setlastSevenDaysStatistics] = useState([]);
	const [lastThirtyDaysStatistics, setlastThirtyDaysStatistics] = useState([]);
	const [lastTwelveMonthsStatistics, setlastTwelveMonthsStatistics] = useState(
		[]
	);
	const lastSevenDaysStatisticsData = last_seven_days_statistics(
		getRevenueForYearData?.yearlyCounter?.last_thirtydays.slice(
			-1 * last_seven_days
		)
	);

	const lastThirtyDaysStatisticsData = last_Thirty_days_statistics(
		getRevenueForYearData?.yearlyCounter?.last_thirtydays
	);

	const lastTwelveMonthsStatisticsData = last_twelve_months_statistics(
		getRevenueForYearData?.yearlyCounter?.last_twelve_months
	);

	const getStatisticsForParticularYear = year_statistics(
		getRevenueForParticularYearData?.getRevenueForParticularYear
	);

	useEffect(() => {
		setlastSevenDaysStatistics(lastSevenDaysStatisticsData);
		setlastThirtyDaysStatistics(lastThirtyDaysStatisticsData);
		setlastTwelveMonthsStatistics(lastTwelveMonthsStatisticsData);
	}, [
		getRevenueForYearDatasuccess,
		getDoctorVisitedPatientsforParticularDateDatasuccess,
	]);
	const [chartData, setchartData] = useState(lastSevenDaysStatistics);
	const generateChartData = (e) => {
		// Generate chart data based on the selected time interval
		// Replace this with your logic to fetch data for different time intervals
		if (e === '7d') {
			setchartData(lastSevenDaysStatistics);

			setTitle('Last 7 days Revenue');
		} else if (e === '30d') {
			setchartData(lastThirtyDaysStatistics);

			setTitle('Last 30 days Revenue');
		} else {
			setchartData(lastTwelveMonthsStatistics);

			setTitle('Last 12 months Revenue');
		}
	};
	console.log(getRevenueForYearData?.yearlyCounter?.last_thirtydays);
	useEffect(() => {
		setTimeInterval('7d');

		setTitle('Last 7 days Revenue');
		const lastSevenDaysStatisticsData = last_seven_days_statistics(
			getRevenueForYearData?.yearlyCounter?.last_thirtydays.slice(
				-1 * last_seven_days
			)
		);
		setlastSevenDaysStatistics(lastSevenDaysStatisticsData);
		setchartData(lastSevenDaysStatisticsData);
	}, [getRevenueForYearDataloading]);
	const [selectedYear, setSelectedYear] = useState(
		getRevenueForYearData?.yearlyCounter?.year_counts[0]
	);
	const [clickedYear, setclickedYear] = useState(1);
	const [selectedMonth, setSelectedMonth] = useState();
	const [clickedMonth, setclickedMonth] = useState(0);
	const [chartDataForYear, setchartDataForYear] = useState(
		getStatisticsForParticularYear
	);

	const handleYearChange = (event) => {
		setSelectedYear(event.target.value);
		setShowMonthDropdown(false);
		setclickedYear(1);
		setclickedMonth(0);
	};

	const handleMonthChange = (event) => {
		setSelectedMonth(event.target.value);
		setclickedMonth(1);
		setclickedYear(0);
	};

	const getYearData = () => {
		dispatch(
			getRevenueForParticularYear(
				doctorInfo?.user?._id,
				selectedYear,
				clinicName
			)
		);
	};
	console.log(clickedMonth, clickedYear);
	useEffect(() => {
		console.log(clickedMonth + '111111111');
		if (clickedYear === 1) {
			const getStatisticsForParticularYear = year_statistics(
				getRevenueForParticularYearData?.getRevenueForParticularYear
			);
			setchartDataForYear(getStatisticsForParticularYear);
		} else {
			console.log(
				getRevenueForParticularYearData?.getRevenueForParticularYear[
					selectedMonth
				]
			);
			const getStatisticsForParticularMonth = last_Thirty_days_statistics(
				getRevenueForParticularYearData?.getRevenueForParticularYear[
					selectedMonth
				].countForDate
			);
			console.log(getStatisticsForParticularMonth);
			setchartDataForYear(getStatisticsForParticularMonth);
		}
	}, [clickedYear, clickedMonth, selectedMonth, selectedYear]);
	const [showPopup, setShowPopup] = React.useState(false);
	const toggleShowInfoPopup = (e) => {
		setShowPopup(!showPopup);
		e.preventDefault();
		console.log('error');
		dispatch(getRevenueForYearReset());
	};
	const [showPopup1, setShowPopup1] = React.useState(false);
	const toggleShowInfoPopup1 = (e) => {
		setShowPopup1(!showPopup1);
		e.preventDefault();
		console.log('error');
		dispatch(getRevenueForParticularYearReset());
	};
	const [showPopup2, setShowPopup2] = React.useState(false);
	const toggleShowInfoPopup2 = (e) => {
		setShowPopup2(!showPopup2);
		e.preventDefault();
		console.log('error');
		dispatch(getDoctorVisitedPatientsforParticularDateReset());
	};
	const data = [
		{ name: 'Category 1', value: 10 },
		{ name: 'Category 2', value: 15 },
		{ name: 'Category 3', value: 8 },
		// Add more data as needed
	];

	return (
		<div className='home'>
			{getRevenueForYearDataerror && (
				<Message variant='danger'>
					<button
						type='button'
						className='close'
						data-dismiss='alert'
						aria-label='Close'
						onClick={toggleShowInfoPopup}>
						<span aria-hidden='true'>&times;</span>
					</button>
					{getRevenueForYearDataerror}
				</Message>
			)}
			{getRevenueForParticularYearDataerror && (
				<Message variant='danger'>
					<button
						type='button'
						className='close'
						data-dismiss='alert'
						aria-label='Close'
						onClick={toggleShowInfoPopup1}>
						<span aria-hidden='true'>&times;</span>
					</button>
					{getRevenueForParticularYearDataerror}
				</Message>
			)}

			{getDoctorVisitedPatientsforParticularDateDataerror && (
				<Message variant='danger'>
					<button
						type='button'
						className='close'
						data-dismiss='alert'
						aria-label='Close'
						onClick={toggleShowInfoPopup2}>
						<span aria-hidden='true'>&times;</span>
					</button>
					{getDoctorVisitedPatientsforParticularDateDataerror}
				</Message>
			)}

			{(getRevenueForYearDataloading ||
				getDoctorVisitedPatientsforParticularDateDataloading ||
				getRevenueForParticularYearDataloading) && <Loader />}

			<h1 className='offset-md-5 offset-2 mb-4'>Revenue</h1>
			<FeaturedInfo statistics={statisticsToday} />
			<div className='button-container mb-4'>
				<button
					className={`time-interval-button ${
						timeInterval === '7d' ? 'active-button' : ''
					}`}
					onClick={() => {
						setTimeInterval('7d');
						generateChartData('7d');
					}}>
					Last 7 Days
				</button>
				<button
					className={`time-interval-button ${
						timeInterval === '30d' ? 'active-button' : ''
					}`}
					onClick={() => {
						setTimeInterval('30d');
						generateChartData('30d');
					}}>
					Last 30 Days
				</button>
				<button
					className={`time-interval-button ${
						timeInterval === 'last12m' ? 'active-button' : ''
					}`}
					onClick={() => {
						setTimeInterval('last12m');
						generateChartData('last12m');
					}}>
					Last 12 Months
				</button>
			</div>
			<Chart data={chartData} title={`${title}`} grid dataKey='revenue' />
			{/* <div className="input-container">
        <label htmlFor="yearDropdown">Select Year:</label>
        <select
          id="yearDropdown"
          name="year"
          value={selectedYear}
          onChange={handleYearChange}
        >
          comments Populate years dynamically if needed
          {getRevenueForYearData?.yearlyCounter?.year_counts?.map((year) => (
            <option value={year} key={year}>
              {year}
            </option>
          ))}
          comments ... (add more years as needed)
        </select>
        {showMonthDropdown && (
	@@ -512,13 +519,13 @@ export default function Home() {
              value={selectedMonth} // This should be the index, not the month name
              onChange={handleMonthChange}
            >
              comments Populate months dynamically if needed
              {monthNames.map((month, idx) => (
                <option value={idx} key={month}>
                  {month}
                </option>
              ))}
             comments ... (add more months as needed)
            </select>
          </>
        )}
	@@ -539,7 +546,7 @@ export default function Home() {
        title={`${selectedYear} Revenue`}
        grid
        dataKey="revenue"
      /> */}

			<div className='input-container'>
				<label htmlFor='yearDropdown1'>Select Year:</label>
				<select
					id='yearDropdown1'
					name='year'
					value={selectedYear}
					onChange={handleYearChange}>
					{/* Populate years dynamically if needed */}
					{getRevenueForYearData?.yearlyCounter?.year_counts?.map((year) => (
						<option value={year} key={year}>
							{year}
						</option>
					))}

					{/* ... (add more years as needed) */}
				</select>

				{showMonthDropdown && (
					<>
						<label htmlFor='monthDropdown'>Select Month:</label>
						<select
							id='monthDropdown'
							name='month'
							value={selectedMonth} // This should be the index, not the month name
							onChange={handleMonthChange}>
							{/* Populate months dynamically if needed */}
							{monthNames.map((month, idx) => (
								<option value={idx} key={month}>
									{month}
								</option>
							))}
							{/* ... (add more months as needed) */}
						</select>
					</>
				)}
				<button
					className={`time-interval-button ${
						timeInterval === 'last12m' ? 'active-button' : ''
					}`}
					onClick={() => {
						getYearData();
						setShowMonthDropdown(true);
					}}>
					Get Statistics
				</button>
			</div>
			<div className='chart'>
				<ResponsiveContainer width='100%' aspect={4 / 1}>
					<BarChart data={chartDataForYear}>
						<CartesianGrid strokeDasharray='3 3' />
						<XAxis dataKey='name' stroke='#5550bd' />
						<YAxis />
						<Tooltip />
						<Bar
							dataKey='revenue'
							fill='#8884d8'
							title={`${selectedYear} Revenue`}
							grid
						/>
					</BarChart>
				</ResponsiveContainer>
			</div>
		</div>
	);
}

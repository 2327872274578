/** @format */

import React, { useRef, useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import Meta from "../../../Components/Meta";
import SearchPatient from "../../../Components/SearchPatients";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { editDoctor } from "../../../redux/doctorRedux/doctorApiCalls";
import { useParams } from "react-router-dom";

const MyProfileScreen = () => {
	const { clinicName } = useParams();
	const dispatch = useDispatch();
	const { doctorInfo } = useSelector((state) => state.doctorLogin);
	console.log(JSON.stringify(doctorInfo) + "line141");
	const [editClicked, setEditClicked] = useState(false);
	const [newName, setNewName] = useState(doctorInfo?.user?.name);
	const [mobilenumber, setMobileNumber] = useState(
		doctorInfo?.user?.mobilenumber
	);
	const [email, setEmail] = useState(doctorInfo?.user?.email);
	const [dob, setDob] = useState(doctorInfo?.user?.dob);
	const [gender, setGender] = useState(doctorInfo?.user?.gender);
	const [speciality, setSpeciality] = useState(doctorInfo?.user?.speciality);
	const [designation, setDesignation] = useState(
		doctorInfo?.user?.aboutMe?.designation
	);
	const [education, setEducation] = useState(
		doctorInfo?.user?.aboutMe?.education
	);
	const [experience, setExperience] = useState(
		doctorInfo?.user?.aboutMe?.experience
	);
	const [personality, setPersonality] = useState(
		doctorInfo?.user?.aboutMe?.personality
	);
	const [address, setAddress] = useState(doctorInfo?.user?.address);
	const [opConsultationFee, setOpConsultationFee] = useState(
		doctorInfo?.user?.opConsultationFee
	);

	const handleSubmit = (e) => {
		// console.log(speciality)
		e.preventDefault();
		var letters = /^[A-Za-z\s]+$/;
		const cleanNumber = mobilenumber.replace(/\D/g, "");

		// Check if the cleaned number has a length of 10 digits
		console.log(experience + "Line 49");

		if (
			newName === "" ||
			mobilenumber === "" ||
			email === "" ||
			speciality === "" ||
			dob === "" ||
			gender === "" ||
			designation === "" ||
			education === "" ||
			experience === "" ||
			personality === "" ||
			address === "" ||
			opConsultationFee === ""
		) {
			alert("Fields cannot be empty");
		} else if (!newName.match(letters)) {
			alert("Username must contain only alphabets");
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
			alert("Email address invalid");
		} else if (cleanNumber.length !== 10) {
			alert("mobile number  invalid");
		} else if (yearsDiff < 18) {
			alert("your not eligible");
		} else {
			let temp = window.confirm(
				"Are you sure you want to create \n" +
					"name : " +
					newName +
					"\n" +
					"mobilenumber : " +
					mobilenumber +
					"\n" +
					"email: " +
					email +
					"\n" +
					"gender : " +
					gender +
					"\n" +
					"date of birth : " +
					dob +
					"\n" +
					"address : " +
					address +
					"\n" +
					"speciality : " +
					speciality +
					"\n" +
					"designation : " +
					designation +
					"\n" +
					"education : " +
					education +
					"\n" +
					"experience : " +
					experience +
					"\n" +
					"personality : " +
					personality +
					"\n" +
					"opconsultationfee :" +
					opConsultationFee
			);
			if (!temp) {
				alert("Please enter correct Doctor details");
			} else {
				dispatch(
					editDoctor(
						newName,
						mobilenumber,
						email,
						speciality,
						dob,
						gender,
						designation,
						education,
						experience,
						personality,
						address,
						doctorInfo?.user?._id,
						opConsultationFee,
						clinicName
					)
				);
				setEditClicked(false);
			}
		}
	};
	// Current date
	const currentDate = new Date();

	// Format current date as a string
	const year = currentDate.getFullYear();
	const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
	const day = currentDate.getDate().toString().padStart(2, "0");
	const formattedDate = `${year}-${month}-${day}`;

	// Date of Birth (replace with your actual DOB)

	// Calculate the difference in years
	var yearsDiff = year - parseInt(dob.slice(0, 4));

	// Adjust for variations in day and month
	if (
		month < dob.slice(5, 7) ||
		(month === dob.slice(5, 7) && day < dob.slice(8, 10))
	) {
		// If the birthday hasn't occurred yet this year, subtract 1 from the difference
		console.log("Age:", yearsDiff - 1);
		yearsDiff = yearsDiff - 1;
	} else {
		console.log("Age:", yearsDiff);
	}

	console.log("Current Date:", formattedDate);
	console.log("DOB:", dob);

	//discard function
	const handleDiscard = () => {
		setEditClicked(false);
		setNewName(doctorInfo?.user?.name);
		setMobileNumber(doctorInfo?.user?.mobilenumber);
		setEmail(doctorInfo?.user?.email);
		setDob(doctorInfo?.user?.dob);
		setGender(doctorInfo?.user?.gender);
		setSpeciality(doctorInfo?.user?.speciality);
		setDesignation(doctorInfo?.user?.aboutMe?.designation);
		setEducation(doctorInfo?.user?.aboutMe?.education);
		setExperience(doctorInfo?.user?.aboutMe?.experience);
		setPersonality(doctorInfo?.user?.aboutMe?.personality);
		setOpConsultationFee(doctorInfo?.user?.opConsultationFee);
		setAddress(doctorInfo?.user?.address);
	};

	return (
		<div className="pageSize">
			<Meta />

			<h3 className="header-center mt-5">About Me</h3>
			<button
				className="btn btn-outline-success offset-md-10 mt-3"
				onClick={() => setEditClicked(true)}
			>
				<i className="fa fa-edit"></i>
				{"  "}Edit
			</button>
			<div className="card col-12 col-md-10 offset-md-1 mt-2 mb-3">
				<div class="card-body">
					<div className="card-text">
						<div className="row">
							<div className="col-4 col-md-3 mt-2 cardHeading">Name:</div>
							{editClicked ? (
								<input
									type="text"
									className="col-8 form-control"
									value={newName}
									onChange={(e) => setNewName(e.target.value)}
									disabled
								/>
							) : (
								<div className="col-8">{doctorInfo?.user?.name}</div>
							)}
						</div>
						<div className="row">
							<div className="col-4 col-md-3 mt-2 cardHeading">Mobile No:</div>
							{editClicked ? (
								<input
									type="text"
									className="col-8 form-control"
									value={mobilenumber}
									onChange={(e) => setMobileNumber(e.target.value)}
								/>
							) : (
								<div className="col-8">{doctorInfo?.user?.mobilenumber}</div>
							)}
						</div>
						<div className="row">
							<div className="col-4 col-md-3 mt-2 cardHeading">Email Id:</div>
							{editClicked ? (
								<input
									type="text"
									className="col-8 form-control"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							) : (
								<div className="col-8">{doctorInfo?.user?.email}</div>
							)}
						</div>
						<div className="row">
							<div className="col-4 col-md-3 mt-2 cardHeading">
								Date Of Birth:
							</div>
							{editClicked ? (
								<input
									type="date"
									className="col-8 form-control"
									value={dob}
									onChange={(e) => setDob(e.target.value)}
								/>
							) : (
								<div className="col-8">{doctorInfo?.user?.dob}</div>
							)}
						</div>
						<div className="row">
							<div className="col-4 col-md-3 mt-2 cardHeading">Gender:</div>
							{editClicked ? (
								// <input
								// 	type="text"
								// 	className="col-8 form-control"
								// 	value={gender}
								// 	onChange={(e) => setGender(e.target.value)}
								// />
								<div>
									<label>
										<input
											type="radio"
											value="male"
											checked={gender === "Male"}
											onChange={() => setGender("Male")}
										/>
										Male
									</label>

									<label>
										<input
											type="radio"
											value="female"
											checked={gender === "Female"}
											onChange={() => setGender("Female")}
										/>
										Female
									</label>

									{/* Add more checkboxes for other genders if needed */}
								</div>
							) : (
								<div className="col-8">{doctorInfo?.user?.gender}</div>
							)}
						</div>
						<div className="row">
							<div className="col-4 col-md-3 mt-2 cardHeading">Speciality:</div>
							{editClicked ? (
								<input
									type="text"
									className="col-8 form-control"
									value={speciality}
									onChange={(e) => setSpeciality(e.target.value)}
								/>
							) : (
								<div className="col-8">{doctorInfo?.user?.speciality}</div>
							)}
						</div>
						<div className="row">
							<div className="col-4 col-md-3 mt-2 cardHeading">
								Designation:
							</div>
							{editClicked ? (
								<input
									type="text"
									className="col-8 form-control"
									value={designation}
									onChange={(e) => setDesignation(e.target.value)}
								/>
							) : (
								<div className="col-8">
									{doctorInfo?.user?.aboutMe?.designation}
								</div>
							)}
						</div>
						<div className="row">
							<div className="col-4 col-md-3 mt-2 cardHeading">Education:</div>
							{editClicked ? (
								<input
									type="text"
									className="col-8 form-control"
									value={education}
									onChange={(e) => setEducation(e.target.value)}
								/>
							) : (
								<div className="col-8">
									{doctorInfo?.user?.aboutMe?.education}
								</div>
							)}
						</div>
						<div className="row">
							<div className="col-4 col-md-3 mt-2 cardHeading">Experience:</div>
							{editClicked ? (
								<input
									type="date"
									className="col-8 form-control"
									value={experience}
									onChange={(e) => setExperience(e.target.value)}
								/>
							) : (
								<div className="col-8">
									{doctorInfo?.user?.aboutMe?.experience}
								</div>
							)}
						</div>
						<div className="row">
							<div className="col-4 col-md-3 mt-2 cardHeading">
								Personality:
							</div>
							{editClicked ? (
								<input
									type="text"
									className="col-8 form-control"
									value={personality}
									onChange={(e) => setPersonality(e.target.value)}
								/>
							) : (
								<div className="col-8">
									{doctorInfo?.user?.aboutMe?.personality}
								</div>
							)}
						</div>
						<div className="row">
							<div className="col-4 col-md-3 mt-2 cardHeading">Address:</div>
							{editClicked ? (
								<textarea
									type="text"
									className="col-8 form-control"
									value={address}
									onChange={(e) => setAddress(e.target.value)}
								></textarea>
							) : (
								<div className="col-8">{doctorInfo?.user?.address}</div>
							)}
						</div>
						<div className="row">
							<div className="col-4 col-md-3 mt-2 cardHeading">
								OpConsultation Fee:
							</div>
							{editClicked ? (
								<textarea
									type="number"
									className="col-8 form-control"
									value={opConsultationFee}
									onChange={(e) => setOpConsultationFee(e.target.value)}
								></textarea>
							) : (
								<div className="col-8">
									{doctorInfo?.user?.opConsultationFee}
								</div>
							)}
						</div>
						{/*<div>
							{Object.keys(days).map((day) => (
								<DayInput
									key={day}
									day={day}
									isWorking={days[day].isWorking}
									onToggle={toggleWorkingDay}
									startTime={days[day].startTime}
									endTime={days[day].endTime}
									breakTime={days[day].breakTime}
									maxAppointmentsperhour={days[day].maxAppointmentsperhour}
									onInputChange={handleInputChange}
								/>
							))}
						</div>
						*/}
					</div>
				</div>
				{editClicked && (
					<div className="row">
						<button
							className="btn btn-danger col-4 offset-1 col-md-2 offset-md-4"
							onClick={handleDiscard}
						>
							Discard Changes
						</button>
						<button
							className="btn btn-success col-4 col-md-2 offset-1"
							onClick={handleSubmit}
						>
							Submit Changes
						</button>
					</div>
				)}
			</div>
		</div>
	);
};

export default MyProfileScreen;

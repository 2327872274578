/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import Meta from "../../../Components/Meta";
import Message from "../../../Components/Message";
import Form from "react-bootstrap/Form";
import { Card, Button } from "react-bootstrap";
import { Col, Row, Container } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Modal } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import {
	getInventoryCategory,
	createInventoryItem,
	getInventoryItemsForBilling,
	editInventoryItem,
} from "../../../redux/inventoryRedux/inventoryApiCalls";
import { useParams } from "react-router-dom";

const AddInventoryScreens = () => {
	const { clinicName } = useParams();

	const dispatch = useDispatch();

	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => {
		setShow(true);
		setName("");
		setDescription("");
		setUnits("");
		setSellingPrice("");
		setCategory("Choose Category");
		setCategoryId("");
		setMinStockLevel("");
		setInventoryId("");
		setStatus("");
	};

	const [name, setName] = useState("");
	const [category, setCategory] = useState("Choose Category");
	const [categoryId, setCategoryId] = useState("");
	const [sellingPrice, setSellingPrice] = useState("");
	const [description, setDescription] = useState("");
	const [units, setUnits] = useState("");
	const [minStockLevel, setMinStockLevel] = useState("");
	const [inventoryId, setInventoryId] = useState("");
	const [status, setStatus] = useState("");

	useEffect(() => {
		dispatch(getInventoryCategory(clinicName));
		dispatch(getInventoryItemsForBilling(clinicName));
	}, []);

	const { getInventorycategory } = useSelector(
		(state) => state.getInventoryCategory
	);

	const { getInventoryItemsForbilling } = useSelector(
		(state) => state.getInventoryItemsForBilling
	);

	console.log(getInventoryItemsForbilling);

	console.log(getInventorycategory);

	const submitHandler = () => {
		if (
			name === "" ||
			category === "Choose Category" ||
			sellingPrice === "" ||
			units === "" ||
			minStockLevel === ""
		) {
			alert("Please fill all the required fields");
		} else {
			dispatch(
				createInventoryItem(
					name,
					description,
					units,
					sellingPrice,
					category,
					categoryId,
					minStockLevel,
					clinicName
				)
			);
			setName("");
			setDescription("");
			setUnits("");
			setSellingPrice("");
			setCategory("Choose Category");
			setCategoryId("");
			setMinStockLevel("");
			setStatus("");
			setInventoryId("");
			setShow(false);
		}
	};

	const [editClicked, setEditClicked] = useState(false);

	const updateInv = (
		name,
		units,
		description,
		category,
		categoryId,
		selling_price,
		minStockLevel,
		id,
		status
	) => {
		setName(name);
		setDescription(description);
		setUnits(units);
		setSellingPrice(selling_price);
		setCategory(category);
		setCategoryId(categoryId);
		setMinStockLevel(minStockLevel);
		setInventoryId(id);
		setShow(true);
		setEditClicked(true);
		setStatus(status);
	};

	const updateInvSubmit = () => {
		if (
			name === "" ||
			category === "Choose Category" ||
			sellingPrice === "" ||
			units === "" ||
			minStockLevel === ""
		) {
			alert("Please fill all the required fields");
		} else {
			dispatch(
				editInventoryItem(
					name,
					description,
					units,
					sellingPrice,
					category,
					categoryId,
					minStockLevel,
					0,
					inventoryId,
					status,
					clinicName
				)
			);
			setEditClicked(false);
			setName("");
			setDescription("");
			setUnits("");
			setSellingPrice("");
			setCategory("Choose Category");
			setCategoryId("");
			setMinStockLevel("");
			setShow(false);
			setStatus("");
			setInventoryId("");
		}
	};

	const deleteInv = (
		name,
		units,
		description,
		category,
		categoryId,
		selling_price,
		minStockLevel,
		id,
		status
	) => {
		dispatch(
			editInventoryItem(
				name,
				description,
				units,
				selling_price,
				category,
				categoryId,
				minStockLevel,
				1,
				id,
				status,
				clinicName
			)
		);
		setName("");
		setDescription("");
		setUnits("");
		setSellingPrice("");
		setCategory("Choose Category");
		setCategoryId("");
		setMinStockLevel("");
		setStatus("");
		setInventoryId("");
	};

	const enableInventory = (
		name,
		units,
		description,
		category,
		categoryId,
		selling_price,
		minStockLevel,
		id,
		status
	) => {
		dispatch(
			editInventoryItem(
				name,
				description,
				units,
				selling_price,
				category,
				categoryId,
				minStockLevel,
				0,
				id,
				"out-of-stock",
				clinicName
			)
		);
		setName("");
		setDescription("");
		setUnits("");
		setSellingPrice("");
		setCategory("Choose Category");
		setCategoryId("");
		setMinStockLevel("");
		setStatus("");
		setInventoryId("");
	};

	const editInventoryitem = useSelector((state) => state.editInventoryItem);
	const editInventoryitemLoading = editInventoryitem?.loading;

	const createInventoryitem = useSelector((state) => state.createInventoryItem);
	const createInventoryitemLoading = createInventoryitem.loading;

	useEffect(() => {
		dispatch(getInventoryItemsForBilling(clinicName));
	}, [editInventoryitemLoading, createInventoryitemLoading]);

	return (
		<>
			<Meta />
			<h1 className="header-center mt-3">Inventory Management</h1>
			<Container fluid className="mt-5">
				<Row className="justify-content-center">
					<Card className="w-100 ml-3 mr-3">
						<Card.Body>
							<div className="d-flex justify-content-between align-items-center mb-2">
								<Card.Title className="mb-0">Inventories List</Card.Title>
								<Button variant="primary" onClick={handleShow}>
									Add New Inventory
								</Button>
							</div>
							<hr style={{ margin: "0 -1.25rem 1rem -1.25rem" }} />
							<Table
								bordered
								className="custom-table"
								style={{ fontSize: "110%" }}
							>
								<thead>
									<tr>
										<th scope="col">S. No</th>
										<th scope="col">Name</th>
										<th scope="col">Category</th>
										<th scope="col">Price</th>
										<th scope="col">Stock</th>
										<th scope="col">Description</th>
										<th scope="col">Min Stock Level</th>
										<th scope="col">Action</th>
									</tr>
								</thead>
								<tbody>
									{getInventoryItemsForbilling?.data?.data?.map(
										(item, index) => (
											<tr
												className={
													item?.status === "discontinued"
														? "bg-light-danger text-light"
														: ""
												}
											>
												<td>{index + 1}</td>
												<td>{item?.name}</td>
												<td>{item?.category}</td>
												<td>{item?.selling_price}</td>
												{item?.status === "discontinued" ? (
													<td>
														<b>Discontinued</b>
													</td>
												) : item?.Totalquantity === 0 ? (
													<td className="text-danger">
														<b>Out Of Stock</b>
													</td>
												) : (
													<td>{item?.Totalquantity + " " + item?.unit}</td>
												)}

												<td>{item?.description}</td>
												<td>{item?.minStockLevel}</td>
												{item?.status !== "discontinued" ? (
													<td>
														<button
															className="btn btn-success"
															onClick={() =>
																updateInv(
																	item?.name,
																	item?.unit,
																	item?.description,
																	item?.category,
																	item?.categoryId,
																	item?.selling_price,
																	item?.minStockLevel,
																	item?._id,
																	item?.status
																)
															}
														>
															<i className="fa fa-edit"></i>
														</button>{" "}
														<button
															className="btn btn-danger"
															onClick={() =>
																deleteInv(
																	item?.name,
																	item?.unit,
																	item?.description,
																	item?.category,
																	item?.categoryId,
																	item?.selling_price,
																	item?.minStockLevel,
																	item?._id,
																	item?.status
																)
															}
														>
															<i className="fa fa-trash"></i>
														</button>
													</td>
												) : (
													<td>
														<button
															className="btn btn-light"
															onClick={() =>
																enableInventory(
																	item?.name,
																	item?.unit,
																	item?.description,
																	item?.category,
																	item?.categoryId,
																	item?.selling_price,
																	item?.minStockLevel,
																	item?._id,
																	item?.status
																)
															}
														>
															<i className="fa fa-arrow-circle-up"></i>
														</button>
													</td>
												)}
											</tr>
										)
									)}
								</tbody>
							</Table>
						</Card.Body>
					</Card>
				</Row>
			</Container>

			<Modal show={show} onHide={handleClose} size="lg">
				<Modal.Header closeButton>
					<Modal.Title>Add New Inventory</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form className="form-group" noValidate="true">
						<Container className="mt-3">
							<Row className="justify-content-center">
								<Col md="4" className=" mt-1">
									<label htmlFor="name">
										<b>Product name: </b>
										<span className="text-danger">
											<b>*</b>
										</span>
									</label>
								</Col>
								<Col md="6" className=" mt-1">
									<input
										className="form-control"
										id="speciality"
										type="text"
										required="required"
										value={name}
										onChange={(e) => setName(e.target.value)}
									/>
								</Col>
							</Row>
							<Row className="justify-content-center">
								<Col md="4" className=" mt-1">
									<label htmlFor="name">
										<b>Units of Measurement: </b>
										<span className="text-danger">
											<b>*</b>
										</span>
									</label>
								</Col>
								<Col md="6" className=" mt-1">
									<input
										className="form-control"
										id="speciality"
										type="text"
										required="required"
										value={units}
										onChange={(e) => setUnits(e.target.value)}
									/>
								</Col>
							</Row>
							<Row className="justify-content-center">
								<Col md="4" className=" mt-1">
									<label htmlFor="name">
										<b>Description: </b>
									</label>
								</Col>
								<Col md="6" className=" mt-1">
									<textarea
										className="form-control"
										id="speciality"
										type="text"
										required="required"
										value={description}
										onChange={(e) => setDescription(e.target.value)}
									></textarea>
								</Col>
							</Row>
							<Row className="justify-content-center">
								<Col md="4" className=" mt-1">
									<label htmlFor="name">
										<b>Category: </b>
										<span className="text-danger">
											<b>*</b>
										</span>
									</label>
								</Col>
								<Col md="6" className=" mt-1">
									<DropdownButton
										key="down-centered"
										id={`dropdown-button-drop-down-centered`}
										drop="down-centered"
										variant="success"
										title={category}
										onSelect={(eventKey) => {
											let arr = eventKey?.split("@@");
											setCategory(arr[0]);
											setCategoryId(arr[1]);
										}}
									>
										{getInventorycategory?.data?.data?.map((item) => {
											if (item?.name !== "hospital") {
												return (
													<Dropdown.Item
														eventKey={item?.name + "@@" + item?._id}
													>
														{item?.name}
													</Dropdown.Item>
												);
											}
											return null;
										})}
									</DropdownButton>
								</Col>
							</Row>
							<Row className="justify-content-center">
								<Col md="4" className=" mt-1">
									<label htmlFor="name">
										<b>Selling Price: </b>
										<span className="text-danger">
											<b>*</b>
										</span>
									</label>
								</Col>
								<Col md="6" className=" mt-1">
									<input
										className="form-control"
										id="speciality"
										type="number"
										required="required"
										value={sellingPrice}
										onChange={(e) => setSellingPrice(e.target.value)}
									/>
								</Col>
							</Row>
							<Row className="justify-content-center">
								<Col md="4" className=" mt-1">
									<label htmlFor="name">
										<b>Minimum Stock Level: </b>
										<span className="text-danger">
											<b>*</b>
										</span>
									</label>
								</Col>
								<Col md="6" className=" mt-1">
									<input
										className="form-control"
										id="speciality"
										type="number"
										required="required"
										value={minStockLevel}
										onChange={(e) => setMinStockLevel(e.target.value)}
									/>
								</Col>
							</Row>
						</Container>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
					<Button
						variant="primary"
						onClick={editClicked ? updateInvSubmit : submitHandler}
					>
						Save Changes
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default AddInventoryScreens;

/** @format */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import Loader from './Loader';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Card, Button, Form } from 'react-bootstrap';
import { Col, Row, Container } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

const HomePageContentScreen = () => {
	const { clinicName } = useParams();
	const dispatch = useDispatch();
	const history = useNavigate();

	const [fullClinicName, setFullClinicName] = useState('');
	const [clinicLocation, setClinicLocation] = useState('');
	const [clinicContactNumber, setClinicContactNumber] = useState('');
	const [clinicAddress, setClinicAddress] = useState('');
	const [clinicEmail, setClinicEmail] = useState('');

	const [clinicSocialMediaLinks, setClinicSocialMediaLinks] = useState([]);
	const [clinicServices, setClinicServices] = useState([]);
	const [clinicGallery, setClinicGallery] = useState([]);
	const [clinicAbout, setClinicAbout] = useState('');
	const [yearsOfExp, setYearsOfExp] = useState('');
	const [happyPatients, setHappyPatients] = useState('');
	const [team, setTeam] = useState('');
	const [clinicLogo, setClinicLogo] = useState('');
	const [doctorImage, setDoctorImage] = useState('');
	const [jumbotronImage, setJumbotronImage] = useState('');

	return (
		<div className='pageSize'>
			<h1 className='header-center'>Clinic Information </h1>
			<Form className='form-group mt-4 mb-5' noValidate='true'>
				<Container className='mt-3'>
					<Row>
						<Col md='3' className='mt-1'>
							<label htmlFor='name'>
								<b>Clinic Name: </b>
								<span className='text-danger'>
									<b>*</b>
								</span>
							</label>
						</Col>
						<Col md='6' className='mt-1'>
							<input
								className='form-control'
								id='speciality'
								type='text'
								required='required'
								value={fullClinicName}
								onChange={(e) => setFullClinicName(e.target.value)}
							/>
						</Col>
					</Row>
					<Row>
						<Col md='3' className='mt-1'>
							<label htmlFor='name'>
								<b>Clinic Contact Number: </b>
								<span className='text-danger'>
									<b>*</b>
								</span>
							</label>
						</Col>
						<Col md='6' className='mt-1'>
							<input
								className='form-control'
								id='speciality'
								type='text'
								required='required'
								value={clinicContactNumber}
								onChange={(e) => setClinicContactNumber(e.target.value)}
							/>
						</Col>
					</Row>
					<Row>
						<Col md='3' className='mt-1'>
							<label htmlFor='name'>
								<b>Clinic Email: </b>
								<span className='text-danger'>
									<b>*</b>
								</span>
							</label>
						</Col>
						<Col md='6' className='mt-1'>
							<input
								className='form-control'
								id='speciality'
								type='text'
								required='required'
								value={clinicEmail}
								onChange={(e) => setClinicEmail(e.target.value)}
							/>
						</Col>
					</Row>
					<Row>
						<Col md='3' className='mt-1'>
							<label htmlFor='name'>
								<b>Clinic Location (Google Maps Link): </b>
								<span className='text-danger'>
									<b>*</b>
								</span>
							</label>
						</Col>
						<Col md='6' className='mt-1'>
							<input
								className='form-control'
								id='speciality'
								type='text'
								required='required'
								value={clinicLocation}
								onChange={(e) => setClinicLocation(e.target.value)}
							/>
						</Col>
					</Row>
					<Row>
						<Col md='3' className='mt-1'>
							<label htmlFor='name'>
								<b>Clinic Address: </b>
								<span className='text-danger'>
									<b>*</b>
								</span>
							</label>
						</Col>
						<Col md='6' className='mt-1'>
							<input
								className='form-control'
								id='speciality'
								type='text'
								required='required'
								value={clinicAddress}
								onChange={(e) => setClinicAddress(e.target.value)}
							/>
						</Col>
					</Row>
					<Row>
						<Col md='3' className='mt-1'>
							<label htmlFor='name'>
								<b>Social Media Links: </b>
								<span className='text-danger'>
									<b>*</b>
								</span>
							</label>
						</Col>
						<Col md='6' className='mt-1'>
							<DropdownButton
								key='down-centered'
								id={`dropdown-button-drop-down-centered`}
								drop='down-centered'
								variant='success'
								title={'Instagram'}
								// onSelect={handleDateSelect}
							>
								<div className='dropScroll'>
									<Dropdown.Item></Dropdown.Item>
								</div>
							</DropdownButton>
							<input
								className='form-control'
								id='speciality'
								type='text'
								required='required'
								// value={clinicAddress}
								// onChange={(e) => setClinicAddress(e.target.value)}
							/>
						</Col>
					</Row>
				</Container>
			</Form>
		</div>
	);
};

export default HomePageContentScreen;

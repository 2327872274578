/** @format */

import { createSlice } from "@reduxjs/toolkit";

const editHistoryTemplateInDoctor = createSlice({
  name: "user",
  initialState: {},
  reducers: {
    editHistoryTemplateInDoctorStart: (state) => {
      state.loading = true;
    },
    editHistoryTemplateInDoctorSuccess: (state, action) => {
      state.loading = false;
      state.editHistoryTemplateInDoctor = action.payload;
      state.error = false;
      state.success = true;
    },
    editHistoryTemplateInDoctorFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    editHistoryTemplateInDoctorReset: (state, action) => {
      state.error = false;
      state.success = false;
    },
  },
});

export const {
  editHistoryTemplateInDoctorStart,
  editHistoryTemplateInDoctorSuccess,
  editHistoryTemplateInDoctorFailure,
  editHistoryTemplateInDoctorReset,
} = editHistoryTemplateInDoctor.actions;
export default editHistoryTemplateInDoctor.reducer;

/** @format */

import { createSlice } from "@reduxjs/toolkit";

const prescriptionCreator = createSlice({
  name: "prescriptionCreator",
  initialState: {},
  reducers: {
    prescriptionCreatorStart: (state) => {
      state.loading = true;
    },
    prescriptionCreatorSuccess: (state, action) => {
      state.loading = false;
      state.Leave = action.payload;
      state.error = false;
      state.success = true;
    },
    prescriptionCreatorFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    prescriptionCreatorReset: (state, action) => {
      state.error = false;
      state.success = false;
    },
  },
});

export const {
  prescriptionCreatorStart,
  prescriptionCreatorSuccess,
  prescriptionCreatorFailure,
  prescriptionCreatorReset,
} = prescriptionCreator.actions;
export default prescriptionCreator.reducer;

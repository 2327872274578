/** @format */
import React from 'react';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { getPrescription } from '../redux/prescriptionRedux/prescriptionApiCalls';
import jsPDF from 'jspdf';
import ReactDOMServer from 'react-dom/server';
import HomeScreens from '../Screens/HomeScreen';
import { getPrescriptionReset } from '../redux/prescriptionRedux/getPrescription';
import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from '../redux/allMessages';
import Loader from './Loader';
import Message from './Message';
import { useParams } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import {
	startPrescriptionToStartHistoryRequest,
	startPrescriptionToStartHistorySuccess,
	startPrescriptionToStartHistoryFailure,
	startPrescriptionToStartHistoryReset,
} from '../redux/UINavigationRedux/StartPrescriptionToStartHistory';
import { useReactToPrint } from 'react-to-print';
import { getUserDataById } from '../redux/prescriptionRedux/prescriptionApiCalls';

const ViewPrescriptionComponent = (uid) => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const location = useLocation();
	const { clinicName } = useParams();
	//success and failure
	const getPrescriptionData = useSelector((state) => state.getPrescription);
	const userdata = getPrescriptionData.userdata;
	const success = getPrescriptionData.success;
	const loadingpres = getPrescriptionData.loading;
	const error = getPrescriptionData.error;

	useEffect(() => {
		if (uid?.uid?.uid !== undefined)
			dispatch(getPrescription(uid?.uid?.uid, clinicName));
	}, [uid]);
	const presData = useSelector((state) => state.getPrescription);
	console.log(uid?.uid);
	console.log(presData);

	//ALerts
	useEffect(() => {
		dispatch(allMessagesStart());
	}, []);

	useEffect(() => {
		debugger;
		if (uid?.uid?.userId !== undefined) {
			console.log(uid?.uid?.uid, uid?.uid.name, uid?.uid?.index);
			dispatch(
				getUserDataById(
					uid?.uid?.userId,
					uid?.uid.name,
					uid?.uid?.index,
					clinicName
				)
			);
		}
	}, [uid]);

	console.log(uid?.uid?.uid, uid?.uid.name, uid?.uid?.index);

	const userdata1 = useSelector((state) => state.getUserDataById);
	const userdatainfo = userdata1.userdata;
	const userdataerror = userdata1.error;
	const userdatasuccess = userdata1.success;
	const userdataloading = userdata1.loading;

	console.log(userdatainfo);

	console.log(uid);

	console.log(presData);

	const reportTemplateRef = useRef(null);

	const handleDownloadPdf = useReactToPrint({
		content: () => reportTemplateRef.current,
		documentTitle: 'Visitor Pass',
		onBeforeGetContent: () => {
			// Apply styles to the content to add margins
			const content = reportTemplateRef.current;
			content.style.margin = '30mm 0 0 0'; // Adjust the margin as needed
			return content;
		},
		onAfterPrint: () => console.log('Printed PDF successfully!'),
	});

	const backButton = () => {
		history(-1);
	};

	const [flag, setFlag] = useState(true);

	const createHistory = () => {
		const data = {
			id: uid?.uid?.userId,
			name: uid?.uid?.name,
			date: '',
			specialityName:
				presData?.getPrescription?.data?.data?.speciality?.specialityName,
			speciality:
				presData?.getPrescription?.data?.data?.speciality?.specialityId,
			bookingId: '',
			activeTests: '',
			userIdx: presData?.getPrescription?.data?.data?.userIdx,
		};
		console.log(data);
		dispatch(startPrescriptionToStartHistoryReset());
		dispatch(startPrescriptionToStartHistoryRequest());
		dispatch(startPrescriptionToStartHistorySuccess(data));
		window.open('/history', '_blank');
	};

	return (
		<div>
			{loadingpres && <Loader />}
			<Container>
				<div className='row justify-content-end mt-3 mb-3'>
					<Col md='auto' className='mt-1'>
						<button className='btn btn-success' onClick={handleDownloadPdf}>
							Download as PDF
						</button>
					</Col>
					<Col md='auto' className='mt-1'>
						<button className='btn btn-success' onClick={createHistory}>
							View History
						</button>
					</Col>
				</div>
			</Container>
			<div ref={reportTemplateRef} className='mt-5'>
				<div className='mt-3 mb-3'>
					<div className='headi d-flex justify-content-between mt-3'>
						<img src='/kavithaslogo.png' alt='logo' width='110' height='100' />
						<h6 className='text-muted'>
							KAVITHA DENTAL CARE
							<br />
							Mobile : +91 7901456715
							{/* <br />
							Email :  */}
							<br />
							{/* Tirumala Apartments, Old Saibaba Temple Road, */}
							<br />
							{/* Behind New Bus stand, NGO'S Colony */}
						</h6>
					</div>
				</div>
				<h4 className='patient-name mt-3'>
					Patient Name: {presData?.getPrescription?.data?.data?.username}
				</h4>

				<Container className='mt-4'>
					<Table bordered className='custom-table'>
						<tbody>
							<tr>
								<td>
									<b>Date:</b>{' '}
									{presData?.getPrescription?.data?.data?.bookedDate.slice(
										0,
										10
									)}
								</td>
								<td>
									<b>Age:</b> {uid?.uid?.age}
								</td>
								<td>
									<b>Gender:</b> {uid?.uid?.gender}
								</td>
								<td>
									<b>Blood Group:</b> {uid?.uid?.bgrg}
								</td>
							</tr>
						</tbody>
					</Table>
				</Container>

				<Container className='mt-4'>
					<Table bordered className='custom-table'>
						<tbody>
							{presData?.getPrescription?.data?.data?.vitals
								?.reduce((rows, vital, index) => {
									// Determine if a new row should be started
									if (index % 3 === 0) {
										rows.push([]);
									}
									// Add the current vital to the last row
									rows[rows.length - 1].push(
										<td key={index}>
											<span className='cardHeading'>{vital?.vitalName}: </span>
											{vital?.vitalValue}
										</td>
									);
									return rows;
								}, [])
								.map((row, rowIndex) => (
									<tr key={rowIndex}>
										{row}
										{/* Fill empty cells if this row has less than 3 items */}
										{row.length < 3 &&
											Array(3 - row.length).fill(
												<td key={`empty-${rowIndex}-${row.length}`} />
											)}
									</tr>
								))}
						</tbody>
					</Table>
				</Container>

				<Container className='card mt-4'>
					<div className='row mt-4 mb-2 section'>
						<div className='col-md-4'>Title:</div>
					</div>
					<Row className='mb-4 mt-2'>
						<Col md='auto'></Col>
						<Col md='auto'>{presData?.getPrescription?.data?.data?.title}</Col>
					</Row>
				</Container>

				{presData?.getPrescription?.data?.data?.prescriptionData?.map(
					(page, index) => {
						const templateName =
							presData?.getPrescription?.data?.data?.templateName[index];

						if (templateName !== 'Medication') {
							var f = 1;
							return (
								<Container className='card mt-4' key={index}>
									<div className='row mt-4 mb-2 section'>
										<div className='col-md-4'>{templateName}</div>
									</div>
									<Table bordered className='custom-table'>
										<tbody>
											{Array?.isArray(page) &&
												page?.map((item, idx) => {
													if (f === 0) {
														f = 1;
													} else if (
														item?.type === 'Header' ||
														item?.type === 'Paragraph' ||
														item?.type === 'textarea'
													) {
														return (
															<tr key={`${item?.label}-${idx}`}>
																<td colSpan={4}>
																	{item.label === 'header' ? (
																		<h3>
																			{Array.isArray(item?.value)
																				? item?.value?.join(', ')
																				: item?.value}
																		</h3>
																	) : item?.type === 'textarea' ? (
																		<p>
																			<b>{item?.label}: </b>{' '}
																			{Array.isArray(item?.value)
																				? item?.value?.join(', ')
																				: item?.value}
																		</p>
																	) : (
																		<p>
																			{Array.isArray(item?.value)
																				? item?.value?.join(', ')
																				: item?.value}
																		</p>
																	)}
																</td>
															</tr>
														);
													} else {
														return (
															item?.value !== '' && (
																<tr key={`${item?.label}-${idx}`}>
																	<td>
																		<b>{item?.label}: </b>
																	</td>
																	<td>
																		{item?.value !== undefined &&
																		item?.value?.length > 0 &&
																		Array.isArray(item.value) &&
																		item?.value !== '' &&
																		item?.type === 'multiselect'
																			? item?.value?.length > 0 &&
																			  item?.value
																					?.map((option) => option.label)
																					?.join(', ')
																			: item?.type === 'checkbox'
																			? item?.value?.length > 0 &&
																			  item?.value
																					.map((option) => option)
																					.join(', ')
																			: item?.value}
																	</td>
																	{page[idx + 1]?.value !== '' ? (
																		page[idx + 1] &&
																		page[idx + 1]?.type !== 'Header' &&
																		page[idx + 1]?.type !== 'Paragraph' &&
																		item.type !== 'textarea' ? (
																			<>
																				<div className='d-none'>{(f = 0)}</div>
																				<td>
																					<b>{page[idx + 1]?.label}: </b>
																				</td>
																				<td>
																					{page[idx + 1]?.value !== undefined &&
																					Array.isArray(page[idx + 1]?.value) &&
																					page[idx + 1]?.type === 'multiselect'
																						? page[idx + 1]?.value?.length >
																								0 &&
																						  page[idx + 1]?.value
																								.map((option) => option.label)
																								.join(', ')
																						: page[idx + 1]?.type === 'checkbox'
																						? page[idx + 1]?.value?.length >
																								0 &&
																						  page[idx + 1]?.value
																								.map((option) => option)
																								.join(', ')
																						: page[idx + 1]?.value}
																				</td>
																			</>
																		) : null
																	) : (
																		<div className='d-none'>{(f = 0)}</div>
																	)}
																</tr>
															)
														);
													}
												})}
										</tbody>
									</Table>
								</Container>
							);
						} else if (templateName === 'Medication') {
							return (
								<Container className='card mt-4' key={index}>
									<div className='row mt-4 mb-2 section'>
										<div className='col-md-4'>Medication</div>
									</div>
									<Table bordered className='custom-table'>
										<thead>
											<tr>
												<th>S.No</th>
												<th>Medicine</th>
												<th>Frequency</th>
												<th>Duration</th>
											</tr>
										</thead>
										<tbody>
											{presData?.getPrescription?.data?.data?.doctor?.medicines?.map(
												(medicine, idx) => (
													<tr key={idx}>
														<td>{idx + 1}</td>
														<td>{medicine?.name}</td>
														<td>
															{medicine?.dosage?.includes('O@@') ? (
																medicine?.dosage?.split('O@@')[1]
															) : (
																<>
																	{medicine?.dosage?.includes('A/F') ? (
																		<span className='medication-definition after-food'>
																			AF
																		</span>
																	) : (
																		<span className='medication-definition before-food'>
																			BF
																		</span>
																	)}
																	{medicine?.dosage
																		?.split(',')
																		?.includes('M') && (
																		<span className='medication-definition morning'>
																			M
																		</span>
																	)}
																	{medicine?.dosage
																		?.split(',')
																		?.includes('A') && (
																		<span className='medication-definition afternoon'>
																			AN
																		</span>
																	)}
																	{medicine?.dosage
																		?.split(',')
																		?.includes('N') && (
																		<span className='medication-definition night'>
																			N
																		</span>
																	)}
																</>
															)}
														</td>
														<td>
															{medicine?.duration.charAt(0) === '1'
																? medicine?.duration.replace('s', '')
																: medicine?.duration}
														</td>
													</tr>
												)
											)}
										</tbody>
									</Table>
									<div className='row mt-4 mb-2 section'>
										<div className='col-md-12'>
											<div className='medication-definition-box'>
												<span className='medication-definition before-food'>
													BF
												</span>
												<span className='mt-1'>Before Food</span>
												<span className='medication-definition after-food'>
													AF
												</span>
												<span className='mt-1'>After Food</span>
												<span className='medication-definition morning'>M</span>
												<span className='mt-1'>Morning</span>
												<span className='medication-definition afternoon'>
													AN
												</span>
												<span className='mt-1'>After Noon</span>
												<span className='medication-definition night'>N</span>
												<span className='mt-1'>Night</span>
											</div>
										</div>
									</div>
								</Container>
							);
						}
					}
				)}
			</div>
		</div>
	);
};

export default ViewPrescriptionComponent;

/** @format */

import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

const Meta = ({ title, description, keywords }) => {
	const location = useLocation();
	const currentUrl = location.pathname;
	console.log(currentUrl?.split('/')[2]);
	return (
		<Helmet>
			<title>{currentUrl?.split('/')[2]}</title>
			<meta name='description' content={description} />
			<meta name='keyword' content={keywords} />
		</Helmet>
	);
};

Meta.defaultProps = {
	title: "Bhuvan's Homeopathy Clinic",
	description: 'Hand in hand for a better life',
	keywords: 'Homeopathy, Bhuvans, care',
};

export default Meta;

/** @format */

import React, { useRef, useEffect, useState } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../Components/Loader';
import Meta from '../../../Components/Meta';
import SearchPatient from '../../../Components/SearchPatients';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { changeSlots } from '../../../redux/doctorRedux/doctorApiCalls';
import { Switch } from 'react-switch-input';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import Message from '../../../Components/Message';
import { editDoctorReset } from '../../../redux/doctorRedux/MyProfile_Management/editDoctor';
import { allMessagesStart } from '../../../redux/allMessages';
import { useParams } from 'react-router-dom';

const EditMySlots = () => {
	const appointmentData = [
		{
			startTime: '10:30',
			endTime: '19:00',
			breakTime: '15:30-17:00',
			maxAppointmentsperhour: 4,
			isWorking: true,
			onHoldBookingIds: [],
			timeSlots: [
				{
					timeSlot: '10.30-11.30',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '11.30-12.30',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '12.30-13.30',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '13.30-14.30',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '14.30-15.30',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '15:30-17:00',
					curCount: 0,
					isEmpty: false,
					onHoldCount: 0,
					maxAppointmentsperhour: 0,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '17.00-18.00',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '18.00-19.00',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
			],
		},
		{
			startTime: '10:30',
			endTime: '19:00',
			breakTime: '15:30-17:00',
			maxAppointmentsperhour: 4,
			isWorking: true,
			onHoldBookingIds: [],
			timeSlots: [
				{
					timeSlot: '10.30-11.30',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '11.30-12.30',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '12.30-13.30',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '13.30-14.30',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '14.30-15.30',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '15:30-17:00',
					curCount: 0,
					isEmpty: false,
					onHoldCount: 0,
					maxAppointmentsperhour: 0,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '17.00-18.00',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '18.00-19.00',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
			],
		},
		{
			startTime: '10:30',
			endTime: '19:00',
			breakTime: '15:30-17:00',
			maxAppointmentsperhour: 4,
			isWorking: true,
			onHoldBookingIds: [],
			timeSlots: [
				{
					timeSlot: '10.30-11.30',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '11.30-12.30',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '12.30-13.30',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '13.30-14.30',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '14.30-15.30',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '15:30-17:00',
					curCount: 0,
					isEmpty: false,
					onHoldCount: 0,
					maxAppointmentsperhour: 0,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '17.00-18.00',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '18.00-19.00',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
			],
		},
		{
			startTime: '10:30',
			endTime: '19:00',
			breakTime: '15:30-17:00',
			maxAppointmentsperhour: 4,
			isWorking: true,
			onHoldBookingIds: [],
			timeSlots: [
				{
					timeSlot: '10.30-11.30',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '11.30-12.30',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '12.30-13.30',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '13.30-14.30',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '14.30-15.30',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '15:30-17:00',
					curCount: 0,
					isEmpty: false,
					onHoldCount: 0,
					maxAppointmentsperhour: 0,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '17.00-18.00',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '18.00-19.00',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
			],
		},
		{
			startTime: '10:30',
			endTime: '19:00',
			breakTime: '15:30-17:00',
			maxAppointmentsperhour: 4,
			isWorking: true,
			onHoldBookingIds: [],
			timeSlots: [
				{
					timeSlot: '10.30-11.30',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '11.30-12.30',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '12.30-13.30',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '13.30-14.30',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '14.30-15.30',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '15:30-17:00',
					curCount: 0,
					isEmpty: false,
					onHoldCount: 0,
					maxAppointmentsperhour: 0,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '17.00-18.00',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '18.00-19.00',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
			],
		},
		{
			startTime: '10:30',
			endTime: '19:00',
			breakTime: '15:30-17:00',
			maxAppointmentsperhour: 4,
			isWorking: true,
			onHoldBookingIds: [],
			timeSlots: [
				{
					timeSlot: '10.30-11.30',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '11.30-12.30',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '12.30-13.30',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '13.30-14.30',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '14.30-15.30',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '15:30-17:00',
					curCount: 0,
					isEmpty: false,
					onHoldCount: 0,
					maxAppointmentsperhour: 0,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '17.00-18.00',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '18.00-19.00',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
			],
		},
		{
			startTime: '10:30',
			endTime: '19:00',
			breakTime: '15:30-17:00',
			maxAppointmentsperhour: 4,
			isWorking: true,
			onHoldBookingIds: [],
			timeSlots: [
				{
					timeSlot: '10.30-11.30',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '11.30-12.30',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '12.30-13.30',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '13.30-14.30',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '14.30-15.30',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '15:30-17:00',
					curCount: 0,
					isEmpty: false,
					onHoldCount: 0,
					maxAppointmentsperhour: 0,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '17.00-18.00',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
				{
					timeSlot: '18.00-19.00',
					curCount: 0,
					isEmpty: true,
					onHoldCount: 0,
					maxAppointmentsperhour: 4,
					isBlocked: false,
					bookingIds: [],
				},
			],
		},
	];
	const dispatch = useDispatch();
	const { doctorInfo, loading, error } = useSelector(
		(state) => state.doctorLogin
	);
	const { clinicName } = useParams();
	const [daysData, setDaysData] = useState(doctorInfo?.user?.days);
	console.log(clinicName);

	console.log(JSON.stringify(doctorInfo?.user?.days));

	useEffect(() => {
		let flag = 1;
		let count = 0;
		for (let i = 0; i < doctorInfo?.user?.days?.length; i++) {
			if (doctorInfo?.user?.days[i]?.isWorking) {
				count++;
			}
		}
		for (let i = 0; i < doctorInfo?.user?.days?.length; i++) {
			if (doctorInfo?.user?.days[i]?.isWorking) {
				if (
					doctorInfo?.user?.days[i]?.breakTime === '' ||
					doctorInfo?.user?.days[i]?.startTime === '' ||
					doctorInfo?.user?.days[i]?.endTime === '' ||
					doctorInfo?.user?.days[i]?.maxAppointmentsperhour === 0
				) {
					flag = 0;
				}
			}
		}
		if (flag === 0 || count === 0) {
			setDaysData(appointmentData);
			console.log(appointmentData);
		}
	}, [loading]);
	// State to track the active button
	const [activeButton, setActiveButton] = useState(0);
	const [switche, setSwitch] = useState(false);
	const [switchActivated, setSwitchActivated] = useState(null);

	// Function to handle button click
	const handleButtonClick = (index) => {
		setActiveButton(index);
	};

	const days = [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
	];

	const handleChange = (value, index, key) => {
		if (key === 'isWorking') {
			let modifiedDaysData = [...daysData];
			modifiedDaysData[index] = {
				...modifiedDaysData[index],
				isWorking: !value,
			};
			setDaysData(modifiedDaysData);
		} else if (key === 'maxAppointmentsperhour') {
			let modifiedDaysData = [...daysData];
			modifiedDaysData[index] = {
				...modifiedDaysData[index],
				maxAppointmentsperhour: value,
			};
			setDaysData(modifiedDaysData);
		} else if (key === 'startTime') {
			let modifiedDaysData = [...daysData];
			modifiedDaysData[index] = {
				...modifiedDaysData[index],
				startTime: value,
			};
			setDaysData(modifiedDaysData);
		} else if (key === 'endTime') {
			let modifiedDaysData = [...daysData];
			modifiedDaysData[index] = {
				...modifiedDaysData[index],
				endTime: value,
			};
			setDaysData(modifiedDaysData);
		} else if (key === 'startBreakTime') {
			let modifiedDaysData = [...daysData];
			const newBreakTime = modifiedDaysData.map((item, i) => {
				if (index === i) {
					let oldBreakTime = item.breakTime.split('-');
					oldBreakTime[0] = value;
					let updatedBreakTime = oldBreakTime.join('-');

					return { ...item, breakTime: updatedBreakTime };
				}
				return item;
			});
			setDaysData(newBreakTime);
		} else if (key === 'endBreakTime') {
			let modifiedDaysData = [...daysData];
			const newBreakTime = modifiedDaysData.map((item, i) => {
				if (index === i) {
					let oldBreakTime = item.breakTime.split('-');
					oldBreakTime[1] = value;
					let updatedBreakTime = oldBreakTime.join('-');

					return { ...item, breakTime: updatedBreakTime };
				}
				return item;
			});
			setDaysData(newBreakTime);
		}
	};

	const [singleSelectedDayswitch, setSingleSelectedDaySwitch] = useState([]);

	const handleSwitch = (e) => {
		console.log(activeButton, e?.target?.checked);
		let singleSelectedDayData = [];
		if (e?.target?.checked) {
			setSwitch(true);
			for (let i = 0; i < daysData?.length; i++) {
				singleSelectedDayData.push(daysData[activeButton]);
			}
			console.log(singleSelectedDayData);
			setSingleSelectedDaySwitch(singleSelectedDayData);
			setSwitchActivated(activeButton);
		} else {
			setSwitch(false);
			singleSelectedDayData = [];
			setSingleSelectedDaySwitch([]);
			setSwitchActivated(null);
		}
	};

	const discardHandler = () => {
		setSwitch(false);
		document.getElementById('switch').checked = false;
		setSingleSelectedDaySwitch([]);
		setSwitchActivated(null);
		setDaysData(doctorInfo?.user?.days);
		setActiveButton(0);
	};

	function isBreakTimeValid(breakTime) {
		const times = breakTime.split('-');

		if (times.length !== 2) {
			// Handle invalid format
			return false;
		}

		const time1 = times[0].trim();
		const time2 = times[1].trim();

		const [hour1, minute1] = time1.split(':').map(Number);
		const [hour2, minute2] = time2.split(':').map(Number);

		const date1 = new Date(0, 0, 0, hour1, minute1);
		const date2 = new Date(0, 0, 0, hour2, minute2);

		return date1 > date2;
	}

	const submitHandler = () => {
		console.log('111');
		let flag = false;

		daysData.map((day, index) => {
			if (isBreakTimeValid(day?.breakTime) === false) {
				flag = true;
			}
		});

		if (!switche) {
			dispatch(
				changeSlots(
					daysData,
					doctorInfo?.user?._id,
					doctorInfo?.user?.appointmentDates.length,
					doctorInfo?.user?.bookingPeriod,
					clinicName
				)
			);
		} else {
			dispatch(
				changeSlots(
					singleSelectedDayswitch,
					doctorInfo?.user?._id,
					doctorInfo?.user?.appointmentDates.length,
					doctorInfo?.user?.bookingPeriod,
					clinicName
				)
			);
		}
	};
	useEffect(() => {
		dispatch(allMessagesStart());
	}, []);

	const [showPopup, setShowPopup] = React.useState(false);
	const toggleShowInfoPopup = (e) => {
		setShowPopup(!showPopup);
		e.preventDefault();
		console.log('error');
		dispatch(editDoctorReset());
	};

	return (
		<div className='pageSize'>
			{error && (
				<Message variant='danger'>
					<button
						type='button'
						className='close'
						data-dismiss='alert'
						aria-label='Close'
						onClick={toggleShowInfoPopup}>
						<span aria-hidden='true'>&times;</span>
					</button>
					{error}
				</Message>
			)}
			{loading && <Loader />}

			<h1 className='header-center'>Edit My Slots</h1>
			<div className='card col-12 col-md-10 offset-md-1 mt-5 mb-3'>
				<div className='mt-3 offset-md-2'>
					{days.map((day, index) => (
						<button
							key={index}
							onClick={() => handleButtonClick(index)}
							style={{ borderRadius: '0px' }}
							className={
								activeButton === index ? 'btn btn-success' : 'btn btn-secondary'
							}>
							{day}
						</button>
					))}
				</div>
				{!switche &&
					daysData?.map(
						(slotData, index) =>
							index === activeButton && (
								<div className='mt-4'>
									<div className='row col-md-8 offset-md-2 form-group'>
										<Form.Check
											type='checkbox'
											className='offset-md-1'
											onChange={(e) =>
												handleChange(slotData?.isWorking, index, 'isWorking')
											}
											checked={slotData?.isWorking}
										/>
										<label
											className='form-label col-md-8'
											htmlFor='maxAppointmentsperhour'>
											<b>is Working? </b>
										</label>
									</div>
									<div className='row col-md-8 offset-md-2 form-group'>
										<label
											className='form-label col-md-3'
											htmlFor='maxAppointmentsperhour'>
											<b>Appointments / Hour </b>
											<span className='text-danger'>
												<b>*</b>
											</span>
										</label>
										<input
											className='form-control col-md-8'
											id='maxAppointmentsperhour'
											type='number'
											required='required'
											value={
												slotData?.isWorking && slotData?.maxAppointmentsperhour
											}
											onChange={(e) => {
												handleChange(
													e.target.value,
													index,
													'maxAppointmentsperhour'
												);
											}}
											disabled={!slotData?.isWorking}
										/>
									</div>
									<div className='row col-md-8 offset-md-2 form-group'>
										<label className='form-label col-md-3' htmlFor='startTime'>
											<b>Start time: </b>
											<span className='text-danger'>
												<b>*</b>
											</span>
										</label>
										<input
											className='form-control col-md-8'
											type='time'
											id='appt'
											name='appt'
											value={slotData?.isWorking && slotData?.startTime}
											onChange={(e) => {
												handleChange(e.target.value, index, 'startTime');
											}}
											disabled={!slotData?.isWorking}
										/>
									</div>
									<div className='row col-md-8 offset-md-2 form-group'>
										<label className='form-label col-md-3' htmlFor='endTime'>
											<b>End time: </b>
											<span className='text-danger'>
												<b>*</b>
											</span>
										</label>
										<input
											className='form-control col-md-8'
											type='time'
											id='appt'
											name='appt'
											value={slotData?.isWorking && slotData?.endTime}
											onChange={(e) => {
												handleChange(e.target.value, index, 'endTime');
											}}
											disabled={!slotData?.isWorking}
										/>
									</div>
									<div className='row col-md-8 offset-md-2 form-group'>
										<label className='form-label col-md-3' htmlFor='breakTime'>
											<b>Break time: </b>
											<span className='text-danger'>
												<b>*</b>
											</span>
										</label>
										<input
											className='form-control col-md-3'
											type='time'
											id='appt'
											name='appt'
											value={
												slotData?.isWorking &&
												slotData?.breakTime?.split('-')[0]
											}
											onChange={(e) =>
												handleChange(e.target.value, index, 'startBreakTime')
											}
											disabled={!slotData?.isWorking}
										/>
										<input
											className='form-control col-md-3 offset-md-1'
											type='time'
											id='appt'
											name='appt'
											value={
												slotData?.isWorking &&
												slotData?.breakTime?.split('-')[1]
											}
											onChange={(e) =>
												handleChange(e.target.value, index, 'endBreakTime')
											}
											disabled={!slotData?.isWorking}
										/>
									</div>
								</div>
							)
					)}
				{switche &&
					singleSelectedDayswitch?.map(
						(slotData, index) =>
							index === activeButton && (
								<div className='mt-4'>
									<div className='row col-md-8 offset-md-2 form-group'>
										<Form.Check
											type='checkbox'
											className='offset-md-1'
											onChange={(e) =>
												handleChange(slotData?.isWorking, index, 'isWorking')
											}
											checked={slotData?.isWorking}
										/>
										<label
											className='form-label col-md-8'
											htmlFor='maxAppointmentsperhour'>
											<b>is Working? </b>
										</label>
									</div>
									<div className='row col-md-8 offset-md-2 form-group'>
										<label
											className='form-label col-md-3'
											htmlFor='maxAppointmentsperhour'>
											<b>Appointments / Hour </b>
											<span className='text-danger'>
												<b>*</b>
											</span>
										</label>
										<input
											className='form-control col-md-8'
											id='maxAppointmentsperhour'
											type='number'
											required='required'
											value={
												slotData?.isWorking && slotData?.maxAppointmentsperhour
											}
											onChange={(e) => {
												handleChange(
													e.target.value,
													index,
													'maxAppointmentsperhour'
												);
											}}
											disabled={
												!slotData?.isWorking || index !== switchActivated
											}
										/>
									</div>
									<div className='row col-md-8 offset-md-2 form-group'>
										<label className='form-label col-md-3' htmlFor='startTime'>
											<b>Start time: </b>
											<span className='text-danger'>
												<b>*</b>
											</span>
										</label>
										<input
											className='form-control col-md-8'
											type='time'
											id='appt'
											name='appt'
											value={slotData?.isWorking && slotData?.startTime}
											onChange={(e) => {
												handleChange(e.target.value, index, 'startTime');
											}}
											disabled={
												!slotData?.isWorking || index !== switchActivated
											}
										/>
									</div>
									<div className='row col-md-8 offset-md-2 form-group'>
										<label className='form-label col-md-3' htmlFor='endTime'>
											<b>End time: </b>
											<span className='text-danger'>
												<b>*</b>
											</span>
										</label>
										<input
											className='form-control col-md-8'
											type='time'
											id='appt'
											name='appt'
											value={slotData?.isWorking && slotData?.endTime}
											onChange={(e) => {
												handleChange(e.target.value, index, 'endTime');
											}}
											disabled={
												!slotData?.isWorking || index !== switchActivated
											}
										/>
									</div>
									<div className='row col-md-8 offset-md-2 form-group'>
										<label className='form-label col-md-3' htmlFor='breakTime'>
											<b>Break time: </b>
											<span className='text-danger'>
												<b>*</b>
											</span>
										</label>
										<input
											className='form-control col-md-3'
											type='time'
											id='appt'
											name='appt'
											value={
												slotData?.isWorking &&
												slotData?.breakTime?.split('-')[0]
											}
											onChange={(e) =>
												handleChange(e.target.value, index, 'startBreakTime')
											}
											disabled={
												!slotData?.isWorking || index !== switchActivated
											}
										/>
										<input
											className='form-control col-md-3 offset-md-1'
											type='time'
											id='appt'
											name='appt'
											value={
												slotData?.isWorking &&
												slotData?.breakTime?.split('-')[1]
											}
											onChange={(e) =>
												handleChange(e.target.value, index, 'endBreakTime')
											}
											disabled={
												!slotData?.isWorking || index !== switchActivated
											}
										/>
									</div>
								</div>
							)
					)}

				<div className='row offset-md-2 form-group'>
					{switchActivated === null ? (
						<div className='col-md-6 row'>
							<Switch className='col-md-1' onChange={handleSwitch} />{' '}
							<label className='form-label col-md-11'>
								<b>Apply {days[activeButton]}'s configurations to all days</b>
							</label>
						</div>
					) : (
						<div className='col-md-6 row'>
							<Switch
								className='col-md-1'
								id='switch'
								onChange={handleSwitch}
							/>{' '}
							<label className='form-label col-md-10'>
								<b>
									Applied {days[switchActivated]}'s configurations to all days
								</b>
							</label>
						</div>
					)}
					<div className='row col-md-8 '>
						<button
							className='btn btn-danger col-md-4'
							onClick={discardHandler}>
							Discard Changes
						</button>
						<button
							className='col-md-4 btn btn-success offset-md-3'
							onClick={submitHandler}>
							Submit
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EditMySlots;

/** @format */

import Message from '../../../Components/Message';
import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../Components/Loader';
import Meta from '../../../Components/Meta';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Button from 'react-bootstrap/Button';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Pagination from 'react-bootstrap/Pagination';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
} from 'reactstrap';
import Form from 'react-bootstrap/Form';
import {
	createLeave,
	deleteLeave,
	getLeave,
	blockSlot,
	unBlockSlot,
} from '../../../redux/doctorRedux/doctorApiCalls';
import { getTimeslots } from '../../../redux/userApiCalls';
import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from '../../../redux/allMessages';

import { createLeaveReset } from '../../../redux/doctorRedux/Leave_Management/createLeave';
import { deleteLeavesReset } from '../../../redux/doctorRedux/Leave_Management/deleteLeave';
import { getLeavesReset } from '../../../redux/doctorRedux/Leave_Management/getLeaves';
import { blockSlotReset } from '../../../redux/doctorRedux/Slot_Management/blockSlots';
import { unBlockSlotReset } from '../../../redux/doctorRedux/Slot_Management/unBlockSlots';
import { TimeSlotGetReset } from '../../../redux/getTimeSlots';
import Table from 'react-bootstrap/Table';
import { useParams } from 'react-router-dom';

const DoctorDeleteLeavesScreen = () => {
	const { clinicName } = useParams();
	const dispatch = useDispatch();
	const history = useNavigate();
	const dt = new Date();
	var date1 = dt.getMonth() + 1 + '/' + dt.getDate() + '/' + dt.getFullYear();

	const months = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];
	const returnDefaultDateFormat =
		dt.getFullYear() + '-' + dt.getMonth() + 1 + '-' + dt.getDate();

	const doctorLogin = useSelector((state) => state.doctorLogin);
	const { doctorInfo } = doctorLogin;
	console.log(doctorInfo + 'line71');
	// success and failure Message
	const getTimeSlotsData = useSelector((state) => state.getTimeSlots);
	const getTimeSlotssuccess = getTimeSlotsData.success;
	const getTimeSlotserror = getTimeSlotsData.error;
	const getTimeSlotsloading = getTimeSlotsData.loading;

	const { getLeaves, success, error, loading } = useSelector(
		(state) => state.getLeaves
	);

	const deleteLeaveData = useSelector((state) => state.deleteLeaves);
	const deleteLeavesuccess = deleteLeaveData.success;
	const deleteLeaveerror = deleteLeaveData.error;
	const deleteLeaveloading = deleteLeaveData.loading;

	//Delete Leaves

	const [items, setItems] = useState([]);
	useEffect(() => {
		dispatch(getLeave(doctorInfo?.user?._id, clinicName));
		setItems(getLeaves?.data?.data);
	}, []);
	console.log(items);
	const [selectedItems, setSelectedItems] = useState([]);

	const toggleItemSelection = (item) => {
		const newSelectedItems = selectedItems.includes(item)
			? selectedItems.filter((i) => i !== item)
			: [...selectedItems, item];
		setSelectedItems(newSelectedItems);
	};
	console.log(selectedItems);
	const DateDifference = (date1Number, date2Number) => {
		// Date numbers

		// Convert to Date objects
		var date1 = new Date(
			Math.floor(date1Number / 10000),
			(Math.floor(date1Number / 100) % 100) - 1,
			date1Number % 100
		);
		var date2 = new Date(
			Math.floor(date2Number / 10000),
			(Math.floor(date2Number / 100) % 100) - 1,
			date2Number % 100
		);

		// Calculate the difference in milliseconds
		var differenceInMilliseconds = date1 - date2;

		// Calculate the difference in days
		var differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

		return differenceInDays;
	};
	const deleteSelected = () => {
		// const newItems = items.filter((item) => !selectedItems.includes(item));
		console.log(selectedItems);
		const startDate = Math.min(...selectedItems);
		// Create a new Date object for the current date
		var currentDate = new Date();

		// Get the year, month, and day as numeric values
		var year = currentDate.getFullYear();
		var month = currentDate.getMonth() + 1; // Adding 1 because months are zero-indexed
		var day = currentDate.getDate();

		// Concatenate the parts to get a numeric representation (yyyyMMdd)
		var numericDate = year * 10000 + month * 100 + day;
		console.log(numericDate, startDate + 'line116');
		const diff = DateDifference(startDate, numericDate);
		console.log(diff);
		if (diff >= doctorInfo?.user.bookingPeriod) {
			dispatch(deleteLeave(doctorInfo?.user?._id, selectedItems, clinicName));

			// dispatch(getLeave(doctorInfo?.user?._id));
			// setItems(getLeaves?.data?.data);
			setSelectedItems([]); // Clear selection after deletion
		} else {
			alert('Leaves cannot be deleted ');
		}
		// window.location.reload();
	};
	useEffect(() => {
		dispatch(getLeave(doctorInfo?.user?._id, clinicName));
	}, []);

	//ALerts//////////////////////////////////////////////////////////////////
	const [timerRunning, setTimerRunning] = useState(false);
	useEffect(() => {
		dispatch(allMessagesStart());
	}, []);
	const alertmsg = useSelector((state) => state.allMessages);
	const [showPopup2, setShowPopup2] = React.useState(false);
	const toggleShowInfoPopup2 = (e) => {
		setShowPopup2(!showPopup2);
		e.preventDefault();
		console.log('error');
		dispatch(deleteLeavesReset());
	};
	const [loaderClass, setLoaderClass] = useState(false);
	useEffect(() => {
		if (deleteLeavesuccess && !timerRunning) {
			setTimerRunning(true);
			setLoaderClass(true);
			const timer = setTimeout(() => {
				// Clear the alert and stop the timer after 3 seconds
				dispatch(deleteLeavesReset());

				setTimerRunning(false);
				setLoaderClass(false);
				window.location.reload();
			}, 3000);

			// Clean up the timer if the component unmounts or the alert changes
		}
	}, [deleteLeavesuccess, dispatch]);

	// useEffect(() => {
	//   if (deleteLeavesuccess) {
	//     dispatch(deleteLeavesReset());
	//   }
	// }, [deleteLeavesuccess, dispatch]);

	const [showPopup5, setShowPopup5] = React.useState(false);
	const toggleShowInfoPopup5 = (e) => {
		setShowPopup5(!showPopup5);
		e.preventDefault();
		console.log('error');
		dispatch(getLeavesReset());
	};

	const [showPopup6, setShowPopup6] = React.useState(false);
	const toggleShowInfoPopup6 = (e) => {
		setShowPopup6(!showPopup6);
		e.preventDefault();
		console.log('error');
		dispatch(TimeSlotGetReset());
	};

	return (
		<div className='pageSize'>
			<Meta />
			{error && (
				<Message variant='danger'>
					<button
						type='button'
						className='close'
						data-dismiss='alert'
						aria-label='Close'
						onClick={toggleShowInfoPopup5}>
						<span aria-hidden='true'>&times;</span>
					</button>
					{error}
				</Message>
			)}
			{getTimeSlotserror && (
				<Message variant='danger'>
					<button
						type='button'
						className='close'
						data-dismiss='alert'
						aria-label='Close'
						onClick={toggleShowInfoPopup6}>
						<span aria-hidden='true'>&times;</span>
					</button>
					{getTimeSlotserror}
				</Message>
			)}
			{deleteLeaveerror && (
				<Message variant='danger'>
					<button
						type='button'
						className='close'
						data-dismiss='alert'
						aria-label='Close'
						onClick={toggleShowInfoPopup2}>
						<span aria-hidden='true'>&times;</span>
					</button>
					{deleteLeaveerror}
				</Message>
			)}

			<Breadcrumb>
				<Breadcrumb.Item href={`/clinic/kavithadentalcare/doctor/createleaves`}>
					Apply Leaves
				</Breadcrumb.Item>
				<Breadcrumb.Item href={`/clinic/kavithadentalcare/doctor/deleteleaves`}>
					Cancel Leaves
				</Breadcrumb.Item>
			</Breadcrumb>

			<div>
				<div className='col-12 offset-md-3 col-md-6 mt-5'>
					<h4 className='header-center'>Cancel Leaves</h4>
					<button
						className='btn btn-primary offset-md-9 mb-3 mt-3'
						onClick={deleteSelected}>
						Delete Selected
					</button>
					{loading || loaderClass ? (
						<Loader />
					) : (
						<Table striped bordered hover className='custom-table'>
							<thead>
								<tr>
									<th>Select</th>
									<th>Date</th>
									<th>Reason</th>
								</tr>
							</thead>
							<tbody>
								{getLeaves?.data?.data?.map(({ date, reason }) => (
									<tr onClick={() => toggleItemSelection(date)}>
										<td>{selectedItems.includes(date) ? '✅' : ''}</td>
										<td>{`${date?.toString().substring(6, 8)}-${date
											?.toString()
											.substring(4, 6)}-${date
											?.toString()
											.substring(0, 4)}`}</td>
										<td>{reason}</td>
									</tr>
								))}
							</tbody>
						</Table>
					)}
				</div>
			</div>
		</div>
	);
};

export default DoctorDeleteLeavesScreen;

/** @format */

import { createSlice } from "@reduxjs/toolkit";

const getHistoryTemplateInDoctor = createSlice({
  name: "user",
  initialState: {},
  reducers: {
    getHistoryTemplateInDoctorStart: (state) => {
      state.loading = true;
    },
    getHistoryTemplateInDoctorSuccess: (state, action) => {
      state.loading = false;
      state.getHistoryTemplateIndoctor = action.payload;
      state.error = false;
      state.success = true;
    },
    getHistoryTemplateInDoctorFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getHistoryTemplateInDoctorReset: (state, action) => {
      state.error = false;
      state.success = false;
    },
  },
});

export const {
  getHistoryTemplateInDoctorStart,
  getHistoryTemplateInDoctorSuccess,
  getHistoryTemplateInDoctorFailure,
  getHistoryTemplateInDoctorReset,
} = getHistoryTemplateInDoctor.actions;
export default getHistoryTemplateInDoctor.reducer;

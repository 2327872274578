/** @format */

import React, { useRef, useEffect, useState } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Loader from './Loader';
import Meta from './Meta';
import Table from 'react-bootstrap/Table';
import Message from './Message';
import { useNavigate, useLocation } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { getUserDataById } from '../redux/prescriptionRedux/prescriptionApiCalls';
import { getUserDataInSearchPatient } from '../redux/adminRedux/adminApiCalls';
import { getUserBooking, deleteBooking } from '../redux/userApiCalls';

import { getUserDataInSearchPatientsReset } from '../redux/adminRedux/User_Management/getUserDataInSearchPatients';
import { UserBookingGetReset } from '../redux/getPatientBooking';
import { getUserDataByIdReset } from '../redux/prescriptionRedux/getUserDataById';
import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from '../redux/allMessages';
import { useParams } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { getPatientHistory } from '../redux/historyRedux/historyApiCalls';
import { getHistoryByIdxReset } from '../redux/historyRedux/History_Management/getHistoryByIdx';
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
} from 'reactstrap';

import {
	billStart,
	billSuccess,
	billFailure,
	billReset,
} from '../redux/doctorRedux/Bill_Management/bill';
const SearchPatient = (usertype) => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const location = useLocation();
	const doctorLogin = useSelector((state) => state.doctorLogin);
	const { receptionInfo } = useSelector((state) => state.receptionLogin);
	const { doctorInfo } = doctorLogin;
	const { clinicName } = useParams();
	//success and error //////////////////////////////
	const userbbokingsdata = useSelector((state) => state.getPatientBooking);
	const getBookingsall = userbbokingsdata.getBookingsall;
	const bookingerror = userbbokingsdata.error;
	const bookingsuccess = userbbokingsdata.success;
	const bookingloading = userbbokingsdata.loading;

	const data1 = useSelector((state) => state.getUserDataInSearchPatient);
	const { userData, loading, error, success } = data1;

	const userdata1 = useSelector((state) => state.getUserDataById);
	const userdata = userdata1.userdata;
	const userdataerror = userdata1.error;
	const userdatasuccess = userdata1.success;
	const userdataloading = userdata1.loading;

	const [mno, setMno] = useState('');
	const [userOptionClicked, setUserOptionClicked] = useState(false);
	const [newUserClicked, setnewUserClicked] = useState(false);
	const [searchClicked, setSearchClicked] = useState(false);

	let allBookings = [];

	console.log(getBookingsall?.data?.data);

	console.log(userData);
	let users_array = [];

	if (mno !== '') {
		users_array.push({
			name: userData?.data?.data?.name,
			id: userData?.data?.data?._id,
			bid: userData?.data?.data?.bookingId,
		});
		for (
			let i = 0;
			i < userData?.data?.data?.registered_patients_array?.length;
			i++
		) {
			users_array.push({
				name: userData?.data?.data?.registered_patients_array[i]?.name,
				id: userData?.data?.data?._id,
				bid: userData?.data?.data?.registered_patients_array[i]?.bookingId,
			});
		}
		if (getBookingsall) {
			allBookings = getBookingsall?.data?.data;
		}
		console.log(allBookings);
	}

	// useEffect(() => {
	// 	// If there's an alert message and the timer isn't running, start the timer
	// 	if ((error) ) {
	// 	//   setTimerRunning(true);
	// 	//   const timer = setTimeout(() => {
	// 	// 	// Clear the alert and stop the timer after 3 seconds
	// 	// 	dispatch(createSpecialityReset());

	// 	// 	setTimerRunning(false);
	// 	//   }, 3000);

	// 	  // Clean up the timer if the component unmounts or the alert changes
	// 	  dispatch(getUserDataErrorByCategoryReset())
	// 	}
	//   }, [error, dispatch]);
	const [showPopup, setShowPopup] = React.useState(false);
	const handleSearchPatient = (e) => {
		e.preventDefault();
		setUserOptionClicked(false);
		setSearchClicked(true);
		dispatch(getUserDataInSearchPatient(mno, clinicName));

		// dispatch(getUserBooking(arr));
	};
	const [uname, setUname] = useState('');
	const [uid, setUid] = useState('');
	const [uuserIdx, setUuserIdx] = useState('');
	const handelUserSelect = (id, bid, name, userIdx) => {
		console.log(bid);
		let arr = [bid];
		setUname(name);
		setUid(id);
		setUuserIdx(userIdx);
		setUserOptionClicked(true);
		dispatch(getUserBooking(arr, clinicName));
		dispatch(getUserDataById(id, name, userIdx, clinicName));
	};
	useEffect(() => {
		//dispatch(getSpecialities());

		if (mno !== '') {
			dispatch(getUserDataInSearchPatient(mno, clinicName));
		}
	}, [history, dispatch, bookingerror, bookingsuccess]);
	const toggleShowInfoPopup = (e) => {
		setShowPopup(!showPopup);
		e.preventDefault();

		console.log('error');
		dispatch(getUserDataInSearchPatientsReset());
	};
	const [showPopup1, setShowPopup1] = React.useState(false);

	const toggleShowInfoPopup1 = (e) => {
		setShowPopup1(!showPopup1);
		e.preventDefault();
		console.log('error');
		dispatch(UserBookingGetReset());
	};
	console.log(searchClicked);

	// View Prescription
	const [filterOption, setFilterOption] = useState('Date');
	const handleFilterSelect = (eventKey) => {
		setFilterOption(eventKey);
	};

	const [categoryOption, setCategoryOption] = useState('Choose Category');
	const [categoryDropdown, setcategoryDropdown] = useState('d-none');
	const [ind, setInd] = useState(-1);

	const handleCategoryNameSelect = (eventKey) => {
		setCategoryOption(eventKey);
		for (let i = 0; i < userdata?.data?.data?.prescCategory.length; i++) {
			if (eventKey === userdata?.data?.data?.prescCategory[i]?.category) {
				setInd(i);
			}
		}
	};

	const handleCategorySelect = () => {
		setcategoryDropdown('d-block');
	};

	const handleDateSelect = () => {
		setcategoryDropdown('d-none');
	};
	const handleDelete = (id) => {
		dispatch(deleteBooking(id, clinicName));
		dispatch(getUserDataInSearchPatient(mno, clinicName));
		setUserOptionClicked(false);
		setSearchClicked(false);
		// window.location.reload();
	};
	// Past Prescriptions

	console.log(userdata);

	const [bloodGroup, setBloodGroup] = useState('');

	useEffect(() => {
		userdata?.data?.data?.vitals?.map((vital, index) => {
			if (vital?.vitalName === 'BloodGroup') {
				setBloodGroup(vital?.vitalValue);
			}
		});
	}, [userdataloading]);

	const viewBtn = (id, age, gender, bgrp, name, activeTests) => {
		const data = {
			id: id,
			age: age,
			gender: gender,
			bgrp: bgrp,
			name: name,
			index: uuserIdx,
			userId: uid,
		};
		let url = '';
		if (usertype?.usertype === 'doctor') {
			url = `/clinic/kavithadentalcare/doctor/viewpres`;
		} else if (usertype?.usertype === 'reception') {
			url = `/clinic/kavithadentalcare/reception/viewpres`;
		}

		const newWindow = window.open(url, '_blank');
		newWindow.onload = () => {
			// Pass both data and unique identifier to the new window
			newWindow.postMessage(data, window.location.origin);
		};
	};

	const today = new Date();
	const year = today.getFullYear();
	const month = today.getMonth() + 1; // Months are zero-indexed, so add 1
	const day = today.getDate();

	const prescriptionByDate = userdata?.data?.data?.prescDate;
	console.log(prescriptionByDate + 'line 78');
	console.log(userdata?.data);
	let scheduledObj = '';
	for (let i = 0; i < userdata?.data?.data?.scheduledAppointment?.length; i++) {
		if (
			userdata?.data?.data?.scheduledAppointment[i].doctor ==
			doctorInfo?.user._id
		) {
			scheduledObj = userdata?.data?.data?.scheduledAppointment[i];
			console.log(scheduledObj);
			//   scheduledObj.date = userdata?.data?.data?.scheduledAppointment[i].date
			//     .split("-")
			//     .reverse()
			//     .join("-");
		}
	}
	console.log(scheduledObj);
	const startPrescription = () => {
		history(`/clinic/kavithadentalcare/startprescription`, {
			state: {
				id: userData?.data?.data?._id,
				name: uname,
				docname:
					doctorInfo === undefined
						? receptionInfo?.user?.doctorName
						: doctorInfo?.user?.name,
				date: day + '-' + month + '-' + year,
				hasPaid: false,
				userIdx: uuserIdx,
				bookingId: 'Emergency Booking',
			},
		});
	};
	//Alert messages//////////////////////////
	useEffect(() => {
		dispatch(allMessagesStart());
	}, []);

	const [showPopup4, setShowPopup4] = React.useState(false);
	const toggleShowInfoPopup4 = (e) => {
		setShowPopup4(!showPopup4);
		e.preventDefault();
		console.log('error');
		dispatch(getUserDataInSearchPatientsReset());
	};

	const [showPopup5, setShowPopup5] = React.useState(false);
	const toggleShowInfoPopup5 = (e) => {
		setShowPopup5(!showPopup5);
		e.preventDefault();
		console.log('error');
		dispatch(UserBookingGetReset());
	};
	const [showPopup6, setShowPopup6] = React.useState(false);
	const toggleShowInfoPopup6 = (e) => {
		setShowPopup6(!showPopup6);
		e.preventDefault();
		console.log('error');
		dispatch(getUserDataByIdReset());
	};

	const getData = (eventKey) => {
		console.log('111111');
		if (eventKey === 'history') {
			dispatch(getPatientHistory(userdata?.data?.data?.historyId, clinicName));
		}
	};

	useEffect(() => {
		if (userdata?.data?.data?.historyId) {
			dispatch(getHistoryByIdxReset());
			dispatch(getPatientHistory(userdata?.data?.data?.historyId, clinicName));
		}
	}, [userdataloading]);

	let { getHistoryByIdx } = useSelector((state) => state.getHistoryByIdx);

	const [open, setOpen] = useState([]);
	const toggle = (id) => {
		if (open.includes(id)) {
			setOpen(open.filter((key) => key !== id));
		} else {
			setOpen([...open, id]);
		}
	};

	const viewBill = (id) => {
		const billUserDetails = {
			billId: id,
			userdata: userdata,
		};
		dispatch(billReset());
		dispatch(billStart());
		dispatch(billSuccess(billUserDetails));
		window.open(`/clinic/kavithadentalcare/bill`, '_blank');
	};

	return (
		<div className='pageSize'>
			{error && (
				<Message variant='danger'>
					<button
						type='button'
						className='close'
						data-dismiss='alert'
						aria-label='Close'
						onClick={toggleShowInfoPopup4}>
						<span aria-hidden='true'>&times;</span>
					</button>
					{error}
				</Message>
			)}
			{loading && <Loader />}
			{bookingerror && (
				<Message variant='danger'>
					<button
						type='button'
						className='close'
						data-dismiss='alert'
						aria-label='Close'
						onClick={toggleShowInfoPopup5}>
						<span aria-hidden='true'>&times;</span>
					</button>
					{bookingerror}
				</Message>
			)}
			{bookingloading && <Loader />}
			{userdataerror && (
				<Message variant='danger'>
					<button
						type='button'
						className='close'
						data-dismiss='alert'
						aria-label='Close'
						onClick={toggleShowInfoPopup6}>
						<span aria-hidden='true'>&times;</span>
					</button>
					{userdataerror}
				</Message>
			)}
			{userdataloading && <Loader />}

			<h1 className='header-center'>Search Patients </h1>
			<form className='form-group' onSubmit={handleSearchPatient}>
				<Container className='header-center mt-5'>
					<Row className='justify-content-center'>
						<Col md='auto' className='text-center mt-1'>
							<h6>
								Enter Patient's Mobile Number{' '}
								<span className='text-danger'>
									<b>*</b>
								</span>
							</h6>
						</Col>
						<Col md='auto' className='text-center mt-1'>
							<input
								className='form-control'
								type='text'
								placeholder='9999999999'
								id='mobilenumber'
								required='required'
								onChange={(e) => {
									setMno(e.target.value);
									setSearchClicked(false);
								}}
								minlength='10'
								maxlength='10'
								pattern='^[0-9]*$'
							/>
						</Col>
						<Col md='auto' className='text-center mt-1'>
							<button type='submit' className='btn btn-primary'>
								Search
							</button>
						</Col>
						<Col md='auto' className='text-center mt-1'>
							{error && (
								<a
									role='button'
									href={`/clinic/kavithadentalcare/register`}
									target='_blank'
									type='submit'
									className='btn btn-danger'>
									Register new Patient
								</a>
							)}
						</Col>
					</Row>
				</Container>
			</form>
			<div className='row offset-md-2'>
				{!error &&
					searchClicked &&
					userData &&
					users_array.map((users, index) =>
						users.name === uname ? (
							<button
								onClick={() =>
									handelUserSelect(users.id, users.bid, users.name, index)
								}
								className='btn btn-secondary col-md-3 m-1'>
								{users.name}
							</button>
						) : (
							<button
								onClick={() =>
									handelUserSelect(users.id, users.bid, users.name, index)
								}
								className='btn btn-outline-secondary col-md-3 m-1'>
								{users.name}
							</button>
						)
					)}
			</div>
			{!error && searchClicked && userData && userOptionClicked ? (
				<div>
					<div className='col-md-10 container'>
						<div className='card mt-2 container'>
							<div className='row mt-3 mb-3'>
								<h6 className='col-3 col-md-2 offset-md-2'>
									Age: {userdata?.data?.data?.age}
								</h6>
								<h6 className='col-4 col-md-2 offset-md-1'>
									Sex: {userdata?.data?.data?.gender}
								</h6>
								<h6 className='col-5 col-md-2 offset-md-1'>
									Blood Group: {userdata?.data?.data?.vitals[4]?.vitalValue}
								</h6>
							</div>
						</div>
					</div>
					<div className='card container mt-2'>
						{scheduledObj?.message !== undefined && (
							<h6 className='text-danger header-center'>
								You have requested the user for a scheduled appointment on
								{' ' +
									scheduledObj?.date?.toString().slice(6, 8) +
									'-' +
									scheduledObj?.date?.toString().slice(4, 6) +
									'-' +
									scheduledObj?.date?.toString().slice(0, 4)}{' '}
								for the reason :{' ' + scheduledObj?.message}
							</h6>
						)}
						{usertype?.usertype === 'doctor' && (
							<div>
								<div className='row mt-5 mb-3'>
									<h6 className='col-3 col-md-2'>Date</h6>
									<h6 className='col-3 col-md-2'>Doctor</h6>
								</div>

								<div className='row mt-2 mb-2'>
									<h6 className='col-3 col-md-2'>{`${
										day?.toString()?.length === 1
											? '0' + day?.toString()
											: day?.toString()
									}-${
										month?.toString()?.length === 1
											? '0' + month?.toString()
											: month?.toString()
									}-${year}`}</h6>
									<h6 className='col-3 col-md-2'>{doctorInfo?.user?.name}</h6>
									<button
										className='col-3 col-md-3 btn btn-danger'
										onClick={() => startPrescription()}>
										Emergency Appointment
									</button>
								</div>
							</div>
						)}
					</div>
					<Container className='mt-3'>
						<Tabs
							onSelect={getData}
							defaultActiveKey='bookinginfo'
							id='uncontrolled-tab-example'
							className='mb-3'>
							<Tab eventKey='bookinginfo' title='Booking Info'>
								<div className='row'>
									{allBookings?.length ? (
										getBookingsall?.data?.data.map((bookings, index) => (
											<div className='col-md-7 container'>
												<div className='card col-12 mt-2 mb-3'>
													<div class='card-body'>
														<div className='card-text'>
															<div className='row'>
																<div className='col-4 cardHeading'>Name:</div>
																<div className='col-8'>{bookings.name}</div>
															</div>
															<div className='row'>
																<div className='col-4 cardHeading'>Doctor:</div>
																<div className='col-8'>
																	{bookings.doctorName}
																</div>
															</div>
															<div className='row'>
																<div className='col-4 cardHeading'>
																	Speciality:
																</div>
																<div className='col-8'>
																	{bookings.specialityName}
																</div>
															</div>
															<div className='row'>
																<div className='col-4 cardHeading'>
																	Scheduled Date:
																</div>
																<div className='col-8'>
																	{bookings.scheduledDate
																		.toString()
																		.slice(6, 8) +
																		'-' +
																		bookings.scheduledDate
																			.toString()
																			.slice(4, 6) +
																		'-' +
																		bookings.scheduledDate
																			.toString()
																			.slice(0, 4)}
																</div>
															</div>
															<div className='row'>
																<div className='col-4 cardHeading'>Slot:</div>
																<div className='col-8'>{bookings.slot}</div>
															</div>
															<div className='row'>
																<div className='col-4 cardHeading'>
																	Slot Count:
																</div>
																<div className='col-8'>
																	{bookings.slotCount}
																</div>
															</div>

															<div className='row'>
																<button
																	class='mt-2 btn btn-danger offset-md-4'
																	onClick={() => handleDelete(bookings._id)}>
																	<i className='fa fa-trash-o'></i>
																	{'   '}Delete
																</button>
															</div>
														</div>
													</div>
												</div>
											</div>
										))
									) : (
										<Container>
											<h5 className='header-center text-danger'>
												<b>No bookings scheduled for the selected patient</b>
											</h5>
										</Container>
									)}
								</div>
							</Tab>
							<Tab eventKey='prescriptioninfo' title='Prescription Info'>
								<div className='card container mt-2'>
									<Row className='mt-4 mb-4'>
										<Col md='auto' className='text-center mt-1'>
											<h6>
												Choose Filter
												<span className='text-danger'>
													<b>*</b>
												</span>
											</h6>
										</Col>
										<Col md='auto' className='text-center mt-1'>
											<DropdownButton
												key='down-centered'
												id={`dropdown-button-drop-down-centered`}
												drop='down-centered'
												variant='success'
												title={filterOption}
												onSelect={handleFilterSelect}>
												<Dropdown.Item
													eventKey={'Date'}
													onClick={handleDateSelect}>
													{'Date'}
												</Dropdown.Item>
												<Dropdown.Item
													eventKey={'Category'}
													onClick={handleCategorySelect}>
													{'Category'}
												</Dropdown.Item>
											</DropdownButton>
										</Col>
										<Col md='auto' className='text-center mt-1'>
											<DropdownButton
												className={categoryDropdown}
												key='down-centered'
												id={`dropdown-button-drop-down-centered`}
												drop='down-centered'
												variant='success'
												title={categoryOption}
												onSelect={handleCategoryNameSelect}>
												{userdata?.data?.data?.category?.map(
													(categories, index) => (
														<Dropdown.Item eventKey={categories}>
															{categories}
														</Dropdown.Item>
													)
												)}
											</DropdownButton>
										</Col>
									</Row>
									<div className='row mt-5 mb-3'>
										<h6 className='col-3 col-md-2'>Date</h6>
										<h6 className='col-3 col-md-2'>Doctor</h6>
										<h6 className='col-3 col-md-2'>Category</h6>
										<h6 className='col-3 col-md-5'>Issue</h6>
									</div>
									{filterOption === 'Date' &&
										userdata?.data?.data?.prescDate?.map((pres, index) => (
											<div className='row mt-2 mb-2'>
												<h6 className='col-3 col-md-2'>
													{pres?.bookedDate.toString().slice(6, 8) +
														'-' +
														pres?.bookedDate.toString().slice(4, 6) +
														'-' +
														pres?.bookedDate.toString().slice(0, 4)}
												</h6>
												<h6 className='col-3 col-md-2'>{pres?.doctorName}</h6>
												<h6 className='col-3 col-md-2'>{pres?.category}</h6>
												<h6 className='col-3 col-md-5 d-block d-md-none'>
													{' '}
													{pres?.title.slice(0, 20)}. . .
												</h6>
												<h6 className='col-3 col-md-5 d-none d-md-block'>
													{' '}
													{pres?.title.slice(0, 105)}. . .
												</h6>
												<button
													className='col-12 col-md-4 offset-md-4 btn btn-secondary'
													onClick={() =>
														viewBtn(
															pres?.prescriptionId,
															userdata?.data?.data?.age,
															userdata?.data?.data?.gender,
															bloodGroup,
															userdata?.data?.data?.name
														)
													}>
													View Prescription
												</button>
											</div>
										))}
									{filterOption === 'Category' &&
										userdata?.data?.data?.prescCategory[
											ind
										]?.prescriptionIds?.map((pres, index) => (
											<div className='row mt-2 mb-2'>
												<h6 className='col-3 col-md-2'>{`${pres.bookedDate
													.toString()
													.substr(6, 2)}-${pres.bookedDate
													.toString()
													.substr(4, 2)}-${pres.bookedDate
													.toString()
													.substr(0, 4)}`}</h6>
												<h6 className='col-3 col-md-2'>{pres?.doctorName}</h6>
												<h6 className='col-3 col-md-2'>
													{userdata?.data?.data?.prescCategory[ind]?.category}
												</h6>
												<h6 className='col-3 col-md-5 d-block d-md-none'>
													{' '}
													{pres?.title.slice(0, 20)}. . .
												</h6>
												<h6 className='col-3 col-md-5 d-none d-md-block'>
													{' '}
													{pres?.title.slice(0, 105)}. . .
												</h6>
												<button
													className='col-12 col-md-4 offset-md-4 btn btn-secondary'
													onClick={() =>
														viewBtn(
															pres?.prescriptionId,
															userdata?.data?.data?.age,
															userdata?.data?.data?.gender,
															bloodGroup,
															userdata?.data?.data?.name
														)
													}>
													View Prescription
												</button>
											</div>
										))}
								</div>
							</Tab>
							<Tab eventKey='history' title='History'>
								{getHistoryByIdx?.data?.data?.history?.map((history, i) => {
									let timestamp = Number(history?.createdAt);
									let date = new Date(timestamp);

									// Format the date to 12-hour format with AM/PM
									let options = {
										year: 'numeric',
										month: '2-digit',
										day: '2-digit',
										hour: '2-digit',
										minute: '2-digit',
										second: '2-digit',
										hour12: true,
									};
									let formattedDate = date.toLocaleString('en-US', options);
									var f = 1;
									return (
										<Container>
											<Accordion open={open} toggle={toggle} className='mt-1'>
												<AccordionItem>
													<AccordionHeader targetId={i}>
														{history?.doctorName} - {formattedDate}
													</AccordionHeader>
													<AccordionBody accordionId={i}>
														<>
															{history?.historyData?.map((page, pageIndex) => (
																<Table
																	bordered
																	className='custom-table'
																	key={`table-${pageIndex}`}>
																	<tbody>
																		{page.map((item, idx) => {
																			if (f === 0) {
																				f = 1;
																			} else if (
																				item.label === 'Header' ||
																				item.label === 'Paragraph'
																			) {
																				return (
																					<tr key={`${item.label}-${idx}`}>
																						<td colSpan={4}>
																							{item.label === 'Header' ? (
																								<h3>
																									{Array.isArray(item.value)
																										? item.value.join(', ')
																										: item.value}
																								</h3>
																							) : (
																								<p>
																									{Array.isArray(item.value)
																										? item.value.join(', ')
																										: item.value}
																								</p>
																							)}
																						</td>
																					</tr>
																				);
																			} else {
																				return (
																					<tr key={`${item.label}-${idx}`}>
																						<td>
																							<b>{item.label}: </b>
																						</td>
																						<td>
																							{item.type === 'textarea' &&
																							item.value !== undefined
																								? item.value
																								: Array.isArray(item.value) &&
																								  item.type === 'multiselect'
																								? Array.isArray(item.value) &&
																								  item.value
																										.map(
																											(option) => option.label
																										)
																										.join(', ')
																								: item.type === 'checkbox'
																								? Array.isArray(item.value) &&
																								  item.value
																										.map((option) => option)
																										.join(', ')
																								: item.value}
																						</td>
																						{page[idx + 1] &&
																						page[idx + 1].label !== 'Header' &&
																						page[idx + 1].label !==
																							'Paragraph' ? (
																							<>
																								<div className='d-none'>
																									{(f = 0)}
																								</div>
																								<td>
																									<b>{page[idx + 1].label}: </b>
																								</td>
																								<td>
																									{page[idx + 1].type ===
																										'textarea' &&
																									page[idx + 1].value !==
																										undefined
																										? page[idx + 1].value
																										: Array.isArray(
																												page[idx + 1].value
																										  ) &&
																										  page[idx + 1].type ===
																												'multiselect'
																										? Array.isArray(
																												page[idx + 1].value
																										  ) &&
																										  page[idx + 1].value
																												.map(
																													(option) =>
																														option.label
																												)
																												.join(', ')
																										: page[idx + 1].type ===
																										  'checkbox'
																										? Array.isArray(
																												page[idx + 1].value
																										  ) &&
																										  page[idx + 1].value
																												.map((option) => option)
																												.join(', ')
																										: page[idx + 1].value}
																								</td>
																							</>
																						) : null}
																					</tr>
																				);
																			}
																		})}
																	</tbody>
																</Table>
															))}
														</>
													</AccordionBody>
												</AccordionItem>
											</Accordion>
										</Container>
									);
								})}
							</Tab>
							<Tab eventKey='bills' title='Bills'>
								<Table bordered className='custom-table'>
									<thead>
										<tr>
											<th>S.No</th>
											<th>Date</th>
											<th>Amount</th>
											<th>Due Amount</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{userdata?.data?.data?.patient_bills?.map((bill, index) => (
											<tr>
												<td>{index + 1}</td>
												<td>{bill?.billDate}</td>
												<td>{bill?.paidAmount}</td>
												<td>{bill?.dueAmount}</td>
												<td>
													<button
														className='btn btn-success'
														onClick={() => viewBill(bill?.billId)}>
														View Bill
													</button>
												</td>
											</tr>
										))}
									</tbody>
								</Table>
							</Tab>
						</Tabs>
					</Container>
				</div>
			) : (
				<div></div>
			)}
		</div>
	);
};

export default SearchPatient;

/** @format */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { patientInventoryPurchase } from '../../../redux/inventoryRedux/inventoryApiCalls';
import { Breadcrumb } from 'react-bootstrap';
import Meta from '../../../Components/Meta';
import { Col, Row, Container } from 'react-bootstrap';
import { getUserDataByCategory } from '../../../redux/adminRedux/adminApiCalls';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Button from 'react-bootstrap/Button';
import { updateUserProfileByAdmin } from '../../../redux/userApiCalls';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { getInventoryItemsForBilling } from '../../../redux/inventoryRedux/inventoryApiCalls';
import { useParams } from 'react-router-dom';

const DynamicTestRow = ({
	index,
	inventoryData,
	values,
	handleChange,
	handleDelete,
	mobileNumber,
	userName,
}) => {
	const dispatch = useDispatch();
	console.log(values);
	console.log(inventoryData);
	const [productName, setProductName] = useState('Choose Product');
	const [selectedProductIndex, setSelectedProductIndex] = useState('');
	const [quantity, setQuantity] = useState('');

	const handleProductName = (eventKey) => {
		if (mobileNumber === '') {
			alert('Please enter the patient mobile number and search');
		} else if (userName === 'Select a user') {
			alert('Please select the user');
		} else {
			let arr = eventKey?.split('@@');
			if (arr[5] <= 0) {
				alert(`${arr[0]} is out of stock`);
			} else {
				setProductName(arr[0]);
				setSelectedProductIndex(arr[1]);
				handleChange(`productName-${index}`, arr[0]);
				handleChange(`productId-${index}`, arr[2]);
				handleChange(`categoryId-${index}`, arr[3]);
				handleChange(`category-${index}`, arr[4]);
				handleChange(
					`cost-${index}`,
					Number(values[`quantity-${index}`]) *
						Number(inventoryData?.data?.data[arr[1]]?.selling_price)
				);
			}
		}
	};

	useEffect(() => {
		handleChange(`quantity-${index}`, '1');
	}, []);

	const incr = (quan) => {
		if (mobileNumber === '') {
			alert('Please enter the patient mobile number and search');
		} else if (userName === 'Select a user') {
			alert('Please select the user');
		} else {
			if (
				Number(quan) >
				inventoryData?.data?.data[selectedProductIndex]?.Totalquantity
			) {
				alert(
					'Avaliable stock is only ' +
						inventoryData?.data?.data[selectedProductIndex]?.Totalquantity
				);
				handleChange(`quantity-${index}`, '1');
				handleChange(
					`cost-${index}`,
					1 *
						Number(
							inventoryData?.data?.data[selectedProductIndex]?.selling_price
						)
				);
			} else {
				let quan1 = Number(quan);
				quan1++;
				console.log(quan, 'hi');
				handleChange(`quantity-${index}`, quan1.toString());
				handleChange(
					`cost-${index}`,
					quan1 *
						Number(
							inventoryData?.data?.data[selectedProductIndex]?.selling_price
						)
				);
			}
		}
	};
	const decr = (quan) => {
		if (mobileNumber === '') {
			alert('Please enter the patient mobile number and search');
		} else if (userName === 'Select a user') {
			alert('Please select the user');
		} else {
			quan = Number(quan);
			quan--;
			handleChange(`quantity-${index}`, quan.toString());
			handleChange(
				`cost-${index}`,
				quan *
					Number(inventoryData?.data?.data[selectedProductIndex]?.selling_price)
			);
		}
	};

	const validateQuantity = (quan) => {
		if (mobileNumber === '') {
			alert('Please enter the patient mobile number and search');
		} else if (userName === 'Select a user') {
			alert('Please select the user');
		} else {
			if (
				Number(quan) >
				inventoryData?.data?.data[selectedProductIndex]?.Totalquantity
			) {
				alert(
					'Avaliable stock is only ' +
						inventoryData?.data?.data[selectedProductIndex]?.Totalquantity
				);
				handleChange(`quantity-${index}`, '1');
				handleChange(
					`cost-${index}`,
					1 *
						Number(
							inventoryData?.data?.data[selectedProductIndex]?.selling_price
						)
				);
			} else {
				handleChange(`quantity-${index}`, quan.toString());
				handleChange(
					`cost-${index}`,
					quan *
						Number(
							inventoryData?.data?.data[selectedProductIndex]?.selling_price
						)
				);
			}
		}
	};

	return (
		<>
			<Container className='header-center mt-5'>
				<Row className='justify-content-center'>
					<Col>
						<Col md='12' className='text-center'>
							<h6>Name:</h6>{' '}
						</Col>
						<Col md='12' className='text-center'>
							<DropdownButton
								key='down-centered'
								id={`dropdown-button-drop-down-centered`}
								drop='down-centered'
								variant='success'
								title={productName}
								onSelect={handleProductName}>
								<div className='dropButton'>
									{inventoryData?.data?.data?.length === 0 ? (
										<Dropdown.Item></Dropdown.Item>
									) : (
										inventoryData?.data?.data?.map(
											(item, index) =>
												item?.status !== 'discontinued' && (
													<Dropdown.Item
														eventKey={
															item.name +
															'@@' +
															index +
															'@@' +
															item?._id +
															'@@' +
															item?.categoryId +
															'@@' +
															item?.category +
															'@@' +
															item?.Totalquantity
														}>
														{item.name}
													</Dropdown.Item>
												)
										)
									)}
								</div>
							</DropdownButton>
						</Col>
					</Col>
					<Col>
						<Col md='12' className='text-center'>
							<h6>Quantity:</h6>
						</Col>
						<Col md='12' className='text-center'>
							<button
								className='btn btn-primary mr-1'
								onClick={() => decr(values[`quantity-${index}`])}>
								<i className='fas fa-minus'></i>
							</button>

							<input
								className='col-md-5'
								type='text'
								name={`quantity-${index}`}
								value={values[`quantity-${index}`]}
								onChange={(e) => {
									validateQuantity(e.target.value);
								}}
							/>
							<button
								className='btn btn-primary ml-1'
								onClick={() => incr(values[`quantity-${index}`])}>
								<i className='fas fa-plus'></i>
							</button>
						</Col>
					</Col>
					<Col>
						<Col md='12' className='text-center'>
							<h6>Price:</h6>
						</Col>
						<Col md='12' className='text-center'>
							<div>
								{values[`cost-${index}`] === '' ? '-' : values[`cost-${index}`]}
							</div>
						</Col>
					</Col>

					<Col>
						<Col md='12' className='text-center'>
							<button
								className='btn btn-danger'
								onClick={() => handleDelete(index)}>
								<i className='fa fa-trash fa-2x'></i>
							</button>
						</Col>
					</Col>
				</Row>
			</Container>
		</>
	);
};

const PatientPurchaseInventoryScreens = () => {
	const { clinicName } = useParams();

	const dispatch = useDispatch();
	const history = useNavigate();
	const data1 = useSelector((state) => state.getUserdataByCategory);
	const userData = data1.userData;
	const { error, loading, success } = data1;

	const adduserinfo = useSelector((state) => state.userUpdate);
	const adduserinfoerror = adduserinfo.error;
	const adduserinfosuccess = adduserinfo.success;
	const adduserinfoloading = adduserinfo.loading;

	const [bloodGroup, setBloodGroup] = useState('Select a blood group');
	const handleBloodGroup = (eventKey) => {
		setBloodGroup(eventKey);
	};

	const [lgShow, setLgShow] = useState(false);
	const [mno, setMno] = useState('');
	const handleSearchPatient = (e) => {
		e.preventDefault();
		setUserOption('Select a user');
		setUserIdx('');

		dispatch(getUserDataByCategory(mno, clinicName));
	};

	const [click, setClick] = useState(0);
	console.log(userData);
	const blood_groups = ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-', 'NA'];
	const [name, setName] = useState('');
	const [gender, setGender] = useState('');
	const [dob, setdob] = useState(new Date());

	const [userId, setUserId] = useState('');

	const addUserOnSubmit = (e) => {
		e.preventDefault();
		console.log(name + ' ' + gender + ' ' + dob + ' ' + mno);
		var letters = /^[A-Za-z\s]+$/;
		if (name === '') {
			alert('Username cannot be empty');
		} else if (!name.match(letters)) {
			alert('Username must contain only alphabets');
		} else if (gender === '') {
			alert('Please select gender, gender cannot be empty');
		} else if (dob === '') {
			alert('Please select dob, dob cannot be empty');
		} else if (new Date(dob) >= new Date(new Date())) {
			alert('Please select proper dob, dob should not exceed todays date');
		} else {
			if (bloodGroup == 'Select a blood group') {
				dispatch(
					updateUserProfileByAdmin(
						name.trim(),
						gender,
						dob,
						mno,
						'NA',
						'',
						clinicName
					)
				);
			} else {
				dispatch(
					updateUserProfileByAdmin(
						name.trim(),
						gender,
						dob,
						mno,
						bloodGroup,
						'',
						clinicName
					)
				);
			}
			setName('');
			setGender('');
			setdob('');
			setClick(1);
			setBloodGroup('Select a blood group');
		}
	};
	const users_array = [];
	// setUserId(userData?.data?.data?._id);
	users_array.push({
		name: userData?.data?.data?.name,
		id: userData?.data?.data?.bookingId,
		doctorBookings: userData?.data?.data?.bookingDoctorsArray,
	});
	for (
		let i = 0;
		i < userData?.data?.data?.registered_patients_array?.length;
		i++
	) {
		users_array.push({
			name: userData?.data?.data?.registered_patients_array[i]?.name,
			id: userData?.data?.data?.registered_patients_array[i]?.bookingId,
			doctorBookings:
				userData?.data?.data?.registered_patients_array[i]?.bookingDoctorsArray,
		});
	}
	console.log(users_array);
	const [userOption, setUserOption] = useState('Select a user');
	const [userIdx, setUserIdx] = useState('');

	const handleUserSelect = (eventKey) => {
		setUserOption(eventKey?.split('@@')[0]);
		setUserIdx(eventKey?.split('@@')[1]);
		setUserId(userData?.data?.data?._id);
	};

	useEffect(() => {
		//dispatch(getSpecialities());
		if (mno !== '') dispatch(getUserDataByCategory(mno, clinicName));
		// if(mno !== "") {
		//   dispatch(getUserDataByCategory(mno));
		// }
	}, [history, dispatch, click, adduserinfosuccess, adduserinfoerror]);

	function getCurrentDateIST() {
		const now = new Date();
		const ISTOffset = 330; // IST offset is UTC+5:30
		const ISTTime = new Date(now.getTime() + ISTOffset * 60 * 1000);
		return ISTTime.toISOString().split('T')[0];
	}
	// Set the maximum allowed date in IST
	const today = getCurrentDateIST();

	useEffect(() => {
		dispatch(getInventoryItemsForBilling(clinicName));
	}, []);

	const { getInventoryItemsForbilling } = useSelector(
		(state) => state.getInventoryItemsForBilling
	);

	console.log(getInventoryItemsForbilling);

	// products, billDetails, userId, userIdx, purchaseBasedonCategory, paidAmount;

	const [rows, setRows] = useState([]);
	const [values, setValues] = useState([]);

	const handleDeleteRow = (index) => {
		const updatedValues = { ...values };
		Object.keys(updatedValues)
			.filter((key) => key.includes(index))
			.forEach((key) => delete updatedValues[key]);

		setValues(updatedValues);

		setRows((prevRows) => prevRows.filter((rowIndex) => rowIndex !== index));
	};

	const handleChange = (name, value) => {
		setValues((prevValues) => ({ ...prevValues, [name]: value }));
	};

	const handleAddRow = () => {
		setRows((prevRows) => [...prevRows, Date.now()]);
	};

	useEffect(() => {
		handleAddRow();
	}, []);

	// const purchaseBasedonCategory1 = purchaseBasedonCategory.push({
	// 	amount: paidAmount,
	// 	categoryId: '5f4a2c3e8f7b1a2d34e1c6a9',
	// });
	// // Group updates by categoryId and sum amounts
	// const bulkOps = purchaseBasedonCategory1.map(({ categoryId, amount }) => ({
	// 	updateOne: {
	// 		filter: { _id: categoryId },
	// 		update: { $inc: { category_purchase_total: amount } },
	// 	},
	// }));

	// updateOne: {
	// 			filter: { _id: mongoose.Types.ObjectId(product.productId) },
	// 			update: {
	// 				$set: { stockBatches: product.updatedstockBatches },
	// 				$set: { minStockLevel: product.totalquantity},
	// 			},
	// 		},

	const [show, setShow] = useState(false);
	const [discount, setDiscount] = useState('');
	const [amount, setAmount] = useState('');
	const [discountReason, setDiscountReason] = useState('');
	const [totalAmountAfterDiscount, setTotalAmountAfterDiscount] = useState('');
	const [amountPaidCash, setAmountPaidCash] = useState('');
	const [amountPaidOnline, setAmountPaidOnline] = useState('');
	const [Result, setResult] = useState([]);
	const [SelectedProductQuantities, setSelectedProductQuantities] = useState(
		[]
	);

	const handleClose = () => {
		setShow(false);
	};

	function deepCopy(obj) {
		return JSON.parse(JSON.stringify(obj));
	}

	function reduceStockBatches(stockBatches, quantity) {
		const sortedStockBatches = deepCopy(stockBatches).sort(
			(a, b) => a.expiryDate - b.expiryDate
		);
		let remainingQuantity = quantity;

		for (let batch of sortedStockBatches) {
			if (remainingQuantity <= 0) break;
			if (batch.quantityLeft >= remainingQuantity) {
				batch.quantityLeft -= remainingQuantity;
				remainingQuantity = 0;
			} else {
				remainingQuantity -= batch.quantityLeft;
				batch.quantityLeft = 0;
			}
		}

		return sortedStockBatches;
	}

	const submitHandler = () => {
		console.log(values);

		const selectedProductKeys = Object.keys(values).filter((key) =>
			key.startsWith('productId-')
		);

		const selectedProductIds = Object.keys(values)
			.filter((key) => key.startsWith('productId-'))
			.map((key) => values[key]);

		const selectedProductQuantities = Object.keys(values)
			.filter((key) => key.startsWith('quantity-'))
			.map((key) => ({
				productId: values[key.replace('quantity', 'productId')],
				quantity: parseInt(values[key], 10),
			}));
		console.log(getInventoryItemsForbilling.data.data);
		const result = getInventoryItemsForbilling.data.data
			.filter((item) => selectedProductIds.includes(item._id))
			.map((item) => {
				const baseKey = selectedProductKeys
					.find((key) => values[key] === item._id)
					.split('productId-')[1];
				const quantityKey = `quantity-${baseKey}`;
				const costKey = `cost-${baseKey}`;
				const categoryIdKey = `categoryId-${baseKey}`;

				const selectedProduct = selectedProductQuantities.find(
					(sp) => sp.productId === item._id
				);

				const reducedStockBatches = selectedProduct
					? reduceStockBatches(item.stockBatches, selectedProduct.quantity)
					: item.stockBatches;

				return {
					productId: item._id,
					amount: parseFloat(values[costKey]),
					categoryId: values[categoryIdKey],
					stockBatches: reducedStockBatches,
					totalQuantity: item.Totalquantity - selectedProduct.quantity,
				};
			});

		const totalCost = Object.keys(values)
			.filter((key) => key.startsWith('cost-'))
			.reduce((sum, key) => sum + parseFloat(values[key]), 0);

		setAmount(totalCost);
		setTotalAmountAfterDiscount(totalCost);

		console.log(totalCost);
		console.log(selectedProductIds);
		console.log(result);

		console.log(result);
		setResult(result);
		setSelectedProductQuantities(selectedProductQuantities);
		setShow(true);
	};

	const handleDiscountChange = (event) => {
		if (event?.target?.value > 100) {
			alert('Discount cannot be greater than 100%');
		} else if (event?.target?.value < 0) {
			alert('Discount cannot be less than 0%');
		} else {
			const discountPercent = event?.target?.value;
			const updatedValues = { ...values };

			Object.keys(updatedValues).forEach((key) => {
				if (key.startsWith('cost-')) {
					const baseKey = key.split('cost-')[1];
					const originalCost = parseFloat(updatedValues[key]);
					const discountedCost = (
						originalCost -
						originalCost * discountPercent * 0.01
					).toFixed(2); // Ensure two decimal places
					updatedValues[`costAfterDiscount-${baseKey}`] =
						parseFloat(discountedCost).toFixed(2); // Round to two decimal places
				}
			});

			setDiscount(discountPercent);

			console.log(updatedValues);

			// Calculate total discounted amount with at most 2 decimals
			const totalDiscountedAmount = Object.keys(updatedValues)
				.filter((key) => key.startsWith('costAfterDiscount-'))
				.reduce((sum, key) => sum + parseFloat(updatedValues[key]), 0);

			setTotalAmountAfterDiscount(parseFloat(totalDiscountedAmount.toFixed(2))); // Round to two decimal places
			setValues(updatedValues);

			const resultWithDiscount = Result.map((item) => {
				const baseKey = Object.keys(updatedValues)
					.find((key) => updatedValues[key] === item.productId)
					.split('productId-')[1];
				const discountedCostKey = `costAfterDiscount-${baseKey}`;
				return {
					...item,
					totalAmountAfterDiscount: Number(
						parseFloat(updatedValues[discountedCostKey]).toFixed(2)
					), // Round to two decimal places
				};
			});

			setResult(resultWithDiscount);

			console.log(resultWithDiscount);
		}
	};

	const handleAmounts = (amount, type) => {
		if (amount > totalAmountAfterDiscount) {
			alert(
				`Amount cannot exceed ${parseFloat(
					totalAmountAfterDiscount.toFixed(2)
				)}`
			);
		} else {
			if (type === 'cash') {
				setAmountPaidOnline(0);
				setAmountPaidCash(Number(amount));
				const remamount = parseFloat(totalAmountAfterDiscount - amount).toFixed(
					2
				);
				setAmountPaidOnline(remamount);
			} else {
				setAmountPaidCash(0);
				setAmountPaidOnline(Number(amount));
				const remamount = parseFloat(totalAmountAfterDiscount - amount).toFixed(
					2
				);
				setAmountPaidCash(remamount);
			}
		}
	};

	const buildUpdatedProductInfo = () => {
		const updatedProductInfo = Result.map((item) => {
			const originalProduct = getInventoryItemsForbilling.data.data.find(
				(p) => p._id === item.productId
			);

			const selectedProduct = SelectedProductQuantities.find(
				(sp) => sp.productId === item.productId
			);

			return {
				productId: item.productId,
				Totalquantity: originalProduct.Totalquantity - selectedProduct.quantity,
				updatedstockBatches: item.stockBatches,
			};
		});

		return updatedProductInfo;
	};

	const handleCompletePayment = () => {
		let remainingCash = Number(amountPaidCash);
		let remainingOnline = Number(amountPaidOnline);

		Result.forEach((product) => {
			product.amountPaidInCash = 0;
			product.amountPaidOnline = 0;

			if (product?.totalAmountAfterDiscount) {
				if (remainingCash > 0) {
					if (remainingCash >= product.totalAmountAfterDiscount) {
						product.amountPaidInCash = product.totalAmountAfterDiscount;
						remainingCash -= parseFloat(
							product.totalAmountAfterDiscount
						).toFixed(2);
						product.amountPaidOnline = 0;
					} else {
						product.amountPaidInCash = Number(remainingCash);
						product.amountPaidOnline = Number(
							parseFloat(product.totalAmountAfterDiscount).toFixed(2)
						);
						remainingCash = 0;
					}
				} else if (remainingOnline > 0) {
					if (remainingOnline >= product.totalAmountAfterDiscount) {
						product.amountPaidOnline = Number(product.totalAmountAfterDiscount);
						remainingOnline -= Number(
							parseFloat(product.totalAmountAfterDiscount).toFixed(2)
						);
						product.amountPaidCash = 0;
					} else {
						product.amountPaidOnline = Number(remainingOnline);
						remainingOnline = 0;
					}
				}
			} else {
				if (remainingCash > 0) {
					if (remainingCash >= product?.amount) {
						product.amountPaidInCash = product?.amount;
						remainingCash -= parseFloat(product?.amount).toFixed(2);
						product.amountPaidOnline = 0;
					} else {
						product.amountPaidInCash = Number(remainingCash);
						product.amountPaidOnline = Number(
							parseFloat(product?.amount).toFixed(2)
						);
						remainingCash = 0;
					}
				} else if (remainingOnline > 0) {
					if (remainingOnline >= product?.amount) {
						product.amountPaidOnline = Number(product?.amount);
						remainingOnline -= Number(parseFloat(product?.amount).toFixed(2));
						product.amountPaidCash = 0;
					} else {
						product.amountPaidOnline = Number(remainingOnline);
						remainingOnline = 0;
					}
				}
			}
		});
		debugger;
		const productResults = Result.map((item) => {
			const baseKey = Object.keys(values)
				.find((key) => values[key] === item.productId)
				.split('productId-')[1];
			const quantityKey = `quantity-${baseKey}`;
			const costKey = `costAfterDiscount-${baseKey}`;
			const categoryIdKey = `categoryId-${baseKey}`;
			const costBeforeDisKey = `cost-${baseKey}`;

			return {
				productId: item.productId,
				amount: values[costKey]
					? Number(parseFloat(values[costKey]))
					: Number(parseFloat(values[costBeforeDisKey])),
				categoryId: values[categoryIdKey],
				stockBatches: item.stockBatches,
				totalQuantity: item.totalQuantity,
				amountPaidInCash: Number(item.amountPaidInCash),
				amountPaidInOnline: Number(item.amountPaidOnline),
				profit: values[costKey]
					? Number(parseFloat(values[costKey]))
					: Number(parseFloat(values[costBeforeDisKey])),
			};
		});

		console.log(productResults);

		const updatedProductInfoArray = buildUpdatedProductInfo();
		console.log(updatedProductInfoArray);
		// Implement the logic to mark as paid or update the payment status as needed

		// products, billDetails, userId, userIdx, purchaseBasedonCategory, paidAmount;

		let inventorDetails = [];
		Object.keys(values).forEach((key) => {
			let id = key.replace(/-\d+$/, '');

			if (key.includes('productId')) {
				let index = key.split('-')[1];
				let category = values[`category-${index}`];
				let categoryId = values[`categoryId-${index}`];
				let cost = values[`cost-${index}`];
				let productId = values[`productId-${index}`];
				let productName = values[`productName-${index}`];
				let quantity = values[`quantity-${index}`];
				let costAfterDiscount = values[`costAfterDiscount-${index}`];

				inventorDetails.push({
					categoryName: category,
					categoryId: categoryId,
					amount: Number(cost),
					inventory_items: {
						name: productName,
						price: Number(cost),
						quantity: quantity,
						amount: Number(costAfterDiscount),
					},
				});
			}
		});

		let bill = {
			AmountToBePaid: Number(amount),
			AmountPaid: Number(amountPaidCash) + Number(amountPaidOnline),
			AmountDue: 0,
			invoiceNumber:
				'INV' + getCurrentDateIST()?.split('-')?.join('') + mno + userIdx,
			prescription_charges: {},
			inventory_charges: {
				AmountToBePaid: Number(amount),
				AmountDue: 0,
				discount: {
					discountAmount: Number(
						parseFloat(amount - totalAmountAfterDiscount).toFixed(2)
					),
					discountReason: discountReason,
				},
				inventoryDetails: inventorDetails,
				additional_charges: [],
			},
			lab_charges: {},
			billDate: getCurrentDateIST(),
			amountPaidOnline: Number(amountPaidOnline),
			amountPaidCash: Number(amountPaidCash),
		};

		// dispatch(
		// 	patientInventoryPurchase(
		// 		updatedProductInfoArray,
		// 		bill,
		// 		userId,
		// 		Number(userIdx),
		// 		productResults,
		// 		parseFloat(totalAmountAfterDiscount.toFixed(2)),
		// 		clinicName
		// 	)
		// );
		setValues([]);
		setRows([]);
		handleClose();
		setDiscount('');
		setDiscountReason('');
		setAmountPaidCash('');
		setAmountPaidOnline('');
		setAmount('');
		setTotalAmountAfterDiscount('');
		setUserId('');
		setUserIdx('');
		setUserOption('Select a user');
		handleAddRow();
	};

	return (
		<>
			<Meta />
			<h1 className='header-center mt-3'>Sell Inventory Items</h1>
			<form className='form-group' onSubmit={handleSearchPatient}>
				<Container className='header-center mt-5'>
					<Row className='justify-content-center'>
						<Col md='auto' className='text-center mt-1'>
							<h6>
								Enter Patient's Mobile Number{' '}
								<span className='text-danger'>
									<b>*</b>
								</span>
							</h6>
						</Col>
						<Col md='auto' className='text-center mt-1'>
							<input
								className='form-control'
								type='text'
								placeholder='9999999999'
								id='mobilenumber'
								required='required'
								onChange={(e) => setMno(e.target.value)}
								minlength='10'
								maxlength='10'
								pattern='^[0-9]*$'
							/>
						</Col>
						<Col md='auto' className='text-center mt-1'>
							<Button type='submit' id='search' className='btn btn-primary'>
								Search
							</Button>
						</Col>
					</Row>
				</Container>
			</form>
			<Container className='header-center mt-5 mb-5 card col-md-9'>
				<Row className='mt-4 mb-4'>
					<Col md='auto' className='text-center mt-1'></Col>
					<Col md='auto' className='text-center mt-1'>
						<h6>
							Select a user{' '}
							<span className='text-danger'>
								<b>*</b>
							</span>
						</h6>
					</Col>
					<Col md='auto' className='text-center mt-1'>
						<DropdownButton
							key='down-centered'
							id={`dropdown-button-drop-down-centered`}
							drop='down-centered'
							variant='success'
							title={userOption}
							onSelect={handleUserSelect}>
							{error === 'No patient exists with that mobile number' ? (
								<Dropdown.Item></Dropdown.Item>
							) : (
								users_array.map((users, index) => (
									<Dropdown.Item eventKey={users.name + '@@' + index}>
										{users.name}
									</Dropdown.Item>
								))
							)}
						</DropdownButton>
					</Col>
					<Col md='auto' className='text-center mt-1'>
						{users_array.length >= 6 ? (
							<Button disabled className='btn btn-danger'>
								Users limit reached
							</Button>
						) : (
							<Button
								className='btn btn-secondary'
								onClick={() => setLgShow(true)}>
								Add User
							</Button>
						)}
					</Col>
				</Row>
				<Modal
					size='lg'
					show={lgShow}
					onHide={() => setLgShow(false)}
					aria-labelledby='example-modal-sizes-title-lg'>
					<Modal.Header closeButton>
						<Modal.Title id='example-modal-sizes-title-lg'>
							Add New User
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<form className='form-group' onSubmit={addUserOnSubmit}>
							<div className='row mt-3'>
								<label className='col-3 col-md-3' htmlFor='name'>
									<b>Name </b>{' '}
									<span className='text-danger'>
										<b>*</b>
									</span>
								</label>
								<input
									className='form-control col-4'
									id='name'
									type='name'
									required='required'
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</div>
							<div className='row mt-3'>
								<label htmlFor='gender' className='col-4 col-md-3'>
									<b>Gender </b>{' '}
									<span className='text-danger'>
										<b>*</b>
									</span>
								</label>
								<div className='row'>
									<b>
										<Form.Check
											onChange={() => {
												setGender('Male');
											}}
											checked={gender === 'Male'}
											className='form-check col-5 col-md-4'
											inline
											label='Male'
											type='radio'
											id={`inline-radio-1`}
										/>
										<Form.Check
											onChange={() => {
												setGender('Female');
											}}
											checked={gender === 'Female'}
											inline
											label='Female'
											className='form-check col-5 col-md-4'
											type='radio'
											id={`inline-radio-2`}
										/>
									</b>
								</div>
							</div>
							<div className='row mt-3'>
								<label htmlFor='date' className='col-md-3'>
									<b>Date of Birth </b>{' '}
									<span className='text-danger'>
										<b>*</b>
									</span>
								</label>
								<input
									type='date'
									className='col-md-4 form-control'
									selected={dob}
									value={dob}
									onChange={(e) => setdob(e.target.value)}
									max={today}
								/>
							</div>
							<div className='row mt-3'>
								<label for='bloodgroup' className='col-md-3'>
									<b>Blood Group:</b>
								</label>
								<DropdownButton
									className='col-md-1 btn'
									key='down-centered'
									id={`dropdown-button-drop-down-centered`}
									drop='down-centered'
									variant='success'
									title={bloodGroup}
									onSelect={handleBloodGroup}>
									{blood_groups.map((blood) => (
										<Dropdown.Item eventKey={blood}>{blood}</Dropdown.Item>
									))}
								</DropdownButton>
							</div>
							<div>
								<Button
									className='offset-5 offset-md-6 mt-5 btn'
									type='submit'
									onClick={() => setLgShow(false)}>
									Add
								</Button>
							</div>
						</form>
					</Modal.Body>
				</Modal>
			</Container>
			<Container className='header-center mt-5 mb-5 card col-md-9'>
				<Row className='mt-4'>
					<Col md='auto' className='text-center mt-1'></Col>
					<Col md='auto' className='text-center mt-1'>
						<h6>
							Add Inventory Items{' '}
							<span className='text-danger'>
								<b>*</b>
							</span>
						</h6>
					</Col>
				</Row>
				<Row className='mb-4'>
					{rows.map((rowIndex) => (
						<DynamicTestRow
							key={rowIndex}
							index={rowIndex}
							inventoryData={getInventoryItemsForbilling}
							values={values}
							handleChange={handleChange}
							handleDelete={handleDeleteRow}
							mobileNumber={mno}
							userName={userOption}
						/>
					))}
				</Row>
				<Row className='justify-content-center mb-4'>
					<Col md='auto' className='text-center mt-1'>
						<button className='btn btn-secondary' onClick={handleAddRow}>
							+ Add more items
						</button>
					</Col>
				</Row>
			</Container>
			<Container className='header-center mt-5'>
				<Row className='justify-content-center'>
					<Col md='auto' className='text-center'>
						<Button className='btn-success' onClick={submitHandler}>
							Complete Payment
						</Button>
					</Col>
				</Row>
			</Container>

			<Modal show={show} onHide={handleClose} size='lg'>
				<Modal.Header closeButton>
					<Modal.Title>Update Payment Status</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className='row mt-2'>
						<div className='col-4 col-md-3 cardHeading'>Amount:</div>
						<div className='col-8'>Rs {amount}</div>
					</div>

					<div className='row mt-2'>
						<div className='col-4 col-md-3 cardHeading'>Discount (%):</div>
						<input
							type='number'
							className='col-8 form-control'
							value={discount}
							onChange={handleDiscountChange}
						/>
					</div>
					<div className='row mt-2'>
						<div className='col-4 col-md-3 cardHeading'>Discount Reason:</div>
						<input
							type='text'
							className='col-8 form-control'
							value={discountReason}
							onChange={(e) => setDiscountReason(e.target.value)}
						/>
					</div>
					<div className='row mt-2'>
						<div className='col-4 col-md-3 cardHeading'>Total Amount:</div>
						<div className='col-8'>Rs {totalAmountAfterDiscount}</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<input
						type='number'
						className='col-3 form-control'
						placeholder='Amount Paid Cash'
						value={amountPaidCash}
						onChange={(e) => handleAmounts(e.target.value, 'cash')}
					/>
					<input
						type='number'
						className='col-3 form-control'
						placeholder='Amount Paid Online'
						value={amountPaidOnline}
						onChange={(e) => handleAmounts(e.target.value, 'online')}
					/>
					<Button variant='primary' onClick={handleCompletePayment}>
						Mark as Paid
					</Button>
					<Button variant='secondary' onClick={handleClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default PatientPurchaseInventoryScreens;

/** @format */

import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import {
  Button,
  Form,
  FormControl,
  FormLabel,
  FormCheck,
  Modal,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faPlus,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import {
  GetPrescriptionTemplateNames,
  GetPrescriptionTemplate,
} from "../../../redux/doctorRedux/doctorApiCalls";
import { Pagination } from "react-bootstrap";
import { getUserDataById } from "../../../redux/prescriptionRedux/prescriptionApiCalls";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { getHistoryTemplateInDoctor } from "../../../redux/historyRedux/historyApiCalls";
import {
  createHistoryForPatient,
  getPatientHistory,
} from "../../../redux/historyRedux/historyApiCalls";
import Table from "react-bootstrap/Table";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import { editHistoryByIdx } from "../../../redux/historyRedux/historyApiCalls";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Meta from "../../../Components/Meta";

const StartHistoryScreens = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();
  const { clinicName } = useParams();
  console.log(clinicName);
  const { doctorInfo } = useSelector((state) => state.doctorLogin);

  const { startPrescriptionToStartHistory } = useSelector(
    (state) => state.StartPrescriptionToStartHistory
  );
  const startPrescriptionToStartHistoryLoading = useSelector(
    (state) => state.StartPrescriptionToStartHistory
  );

  console.log(startPrescriptionToStartHistory);

  useEffect(() => {
    if (doctorInfo?.user?._id !== undefined)
      dispatch(GetPrescriptionTemplateNames(doctorInfo?.user?._id, clinicName));
  }, [startPrescriptionToStartHistoryLoading?.loading]);

  const userdataLoading = useSelector((state) => state.getUserDataById);

  useEffect(() => {
    if (userdata?.data?.data?.historyId) {
      console.log(userdata?.data?.data?.historyId);
      dispatch(getPatientHistory(userdata?.data?.data?.historyId, clinicName));
    }
  }, [userdataLoading?.loading]);

  let { getHistoryByIdx } = useSelector((state) => state.getHistoryByIdx);

  const Pasthistory = getHistoryByIdx?.data?.data?.history;
  const reversedHistory = Array.isArray(Pasthistory)
    ? [...Pasthistory].reverse()
    : [];

  if (Array.isArray(Pasthistory)) {
    getHistoryByIdx = {
      ...getHistoryByIdx,
      data: {
        ...getHistoryByIdx.data,
        data: {
          ...getHistoryByIdx.data.data,
          history: reversedHistory,
        },
      },
    };
  }

  const { getPrescriptionTemplateNames } = useSelector(
    (state) => state.GetPrescriptionTemplateNames
  );

  console.log(getPrescriptionTemplateNames);

  const { userdata } = useSelector((state) => state.getUserDataById);

  console.log(userdata);
  useEffect(() => {
    debugger;
    dispatch(
      getUserDataById(
        startPrescriptionToStartHistory?.id,
        startPrescriptionToStartHistory?.name,
        startPrescriptionToStartHistory?.userIdx,
        clinicName
      )
    );
  }, []);

  const [templateName, setTemplateName] = useState(["Choose Template"]);
  const [templateIdx, setTemplateIdx] = useState([""]);
  const [formValues, setFormValues] = useState({}); // State variable to hold form values

  let flag = false;

  const [getTemplateDataEdited, setGetTemplateDataEdited] = useState(false);

  const TemplateHandler = (temp) => {
    let arr = temp?.split("@@");

    let templates = [...templateName];
    templates[activePage - 1] = arr[0];
    let templatesidx = [...templateIdx];
    templatesidx[activePage - 1] = arr[1];
    setTemplateName(templates);
    setTemplateIdx(templatesidx);
    flag = true;
    setGetTemplateDataEdited(false);
    dispatch(
      getHistoryTemplateInDoctor(doctorInfo?.user?._id, arr[2], clinicName)
    );
  };

  const { getHistoryTemplateIndoctor, loading } = useSelector(
    (state) => state.getHistoryTemplateInDoctor
  );

  const [historyTemplate, setHistoryTemplate] = useState([""]);

  useEffect(() => {
    let templates = [...historyTemplate];
    templates[activePage - 1] =
      getHistoryTemplateIndoctor?.data?.data?.template;
    setHistoryTemplate(templates);
  }, [loading]);

  console.log(historyTemplate);

  console.log(getHistoryTemplateIndoctor);

  // Handler for input elements
  const handleInputChange = (event, label, index, eleindex) => {
    const templateArray = historyTemplate[activePage - 1];

    const updatedFormData = templateArray.map((element, arrIndex) => {
      if (arrIndex === eleindex) {
        return { ...element, value: event?.target?.value };
      }
      return element;
    });
    let templates = [...historyTemplate];
    templates[activePage - 1] = updatedFormData;

    setHistoryTemplate(templates);
  };

  // Handler for checkbox and radio elements
  const handleCheckboxRadioChange = (event, label, option, index, eleindex) => {
    if (event.target.type === "checkbox") {
      // For checkboxes, toggle the selected option
      const selectedOptions =
        historyTemplate[activePage - 1][eleindex]?.value || [];
      const updatedOptions = event.target.checked
        ? [...selectedOptions, option]
        : selectedOptions.filter((item) => item !== option);

      const templateArray = historyTemplate[activePage - 1];

      const updatedFormData = templateArray.map((element, arrIndex) => {
        if (arrIndex === eleindex) {
          return { ...element, value: updatedOptions };
        }
        return element;
      });
      let templates = [...historyTemplate];
      templates[activePage - 1] = updatedFormData;

      setHistoryTemplate(templates);
    } else if (event.target.type === "radio") {
      // For radio buttons, select only the clicked option
      const templateArray = historyTemplate[activePage - 1];

      const updatedFormData = templateArray.map((element, arrIndex) => {
        if (arrIndex === eleindex) {
          return { ...element, value: option };
        }
        return element;
      });
      let templates = [...historyTemplate];
      templates[activePage - 1] = updatedFormData;

      setHistoryTemplate(templates);
    }
  };

  const handleMultiselectChange = (selectedOptions, label, index, eleindex) => {
    // Extract labels and values from selectedOptions
    const selectedValues = selectedOptions.map((option) => ({
      label: option.label,
      value: option.value,
    }));

    const templateArray = historyTemplate[activePage - 1];

    const updatedFormData = templateArray.map((element, arrIndex) => {
      if (arrIndex === eleindex) {
        return { ...element, value: selectedValues };
      }
      return element;
    });
    let templates = [...historyTemplate];
    templates[activePage - 1] = updatedFormData;

    setHistoryTemplate(templates);
  };

  // Handler for select
  const handleSelectChange = (event, label, index, eleindex) => {
    const templateArray = historyTemplate[activePage - 1];

    const updatedFormData = templateArray.map((element, arrIndex) => {
      if (arrIndex === eleindex) {
        return { ...element, value: event?.target?.value };
      }
      return element;
    });
    let templates = [...historyTemplate];
    templates[activePage - 1] = updatedFormData;

    setHistoryTemplate(templates);
  };

  const handleSubmitForm = () => {
    const hasEmptyStringOrUndefinedInHistoryTemplate = historyTemplate.some(
      (item) => item === "" || item === undefined
    );

    if (hasEmptyStringOrUndefinedInHistoryTemplate) {
      alert("Empty page cannot be submitted");
    } else {
      console.log("Form submitted:", historyTemplate);
      setAddNewHistory(false);
      dispatch(
        createHistoryForPatient(
          startPrescriptionToStartHistory?.id,
          startPrescriptionToStartHistory?.userIdx,
          doctorInfo?.user?.name,
          doctorInfo?.user?._id,
          Date.now(),
          historyTemplate,
          userdata?.data?.data?.historyId,
          templateName,
          clinicName
        )
      );
      dispatch(getPatientHistory(userdata?.data?.data?.historyId, clinicName));
    }
  };

  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState([0]);
  const handlePageChange = (pageNumber) => {
    console.log(totalPages);
    setActivePage(pageNumber);
    // You can add logic here to fetch data for the selected page
  };
  const handleAddPage = () => {
    let pages = [...totalPages];
    let lastPage = totalPages[totalPages?.length - 1];
    let newPage = lastPage + 1;
    pages?.push(newPage);
    setTotalPages(pages);
    let templatesSelect = [...templateName];
    templatesSelect?.push("Choose Template");
    setTemplateName(templatesSelect);
    let templatesIdxSelect = [...templateIdx];
    templatesIdxSelect?.push("");
    setTemplateIdx(templatesIdxSelect);
    let prescriptionTemplatesSelect = [...historyTemplate];
    prescriptionTemplatesSelect?.push("");
    setHistoryTemplate(prescriptionTemplatesSelect);
    let editbuttons = [...editClicked];
    editbuttons?.push(false);
    setEditClicked(editbuttons);
    setActivePage(newPage + 1);
  };

  const [editClicked, setEditClicked] = useState([]);

  const editBtnClicked = () => {
    let editbuttons = [...editClicked];
    editbuttons[activePage - 1] = true;
    setEditClicked(editbuttons);
    setFormElements(historyTemplate[activePage - 1]);
  };

  const handleEditDisable = () => {
    let editbuttons = [...editClicked];
    editbuttons[activePage - 1] = false;
    setEditClicked(editbuttons);
  };

  console.log(editClicked);

  // Form Builder Code

  const [formElements, setFormElements] = useState([]);
  const [selectedElementIndex, setSelectedElementIndex] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editLabel, setEditLabel] = useState("");
  const [editOptions, setEditOptions] = useState([]);
  const [isRequired, setIsRequired] = useState(false);
  const [editHeaderValue, setEditHeaderValue] = useState("");
  const [editParagraphValue, setEditParagraphValue] = useState("");
  const [draggingElement, setDraggingElement] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [editModalOptions, setEditModalOptions] = useState([]);
  const [newOption, setNewOption] = useState("");
  const [showAddOptionInput, setShowAddOptionInput] = useState(false);

  console.log(selectedOptions);

  const addFormElement = (elementType, label = "Label Name") => {
    let defaultOptions = [];
    if (
      elementType === "radio" ||
      elementType === "checkbox" ||
      elementType === "select" ||
      elementType === "multiselect"
    ) {
      defaultOptions = ["Option 1", "Option 2", "Option 3"];
    }
    setFormElements([
      ...formElements,
      {
        type: elementType,
        label:
          elementType === "header"
            ? "Header"
            : elementType === "paragraph"
            ? "Paragraph"
            : label,
        value:
          elementType === "header"
            ? "Header"
            : elementType === "paragraph"
            ? "Paragraph"
            : "",
        options: elementType === "header" ? [] : defaultOptions,
        required: false,
      },
    ]);
  };

  const handleDragStart = (event, elementType) => {
    event.dataTransfer.setData("elementType", elementType);
    setDraggingElement(elementType);
  };

  const handleValueChange = (event, index) => {
    const updatedElements = [...formElements];
    updatedElements[index].value = event.target.value;
    setFormElements(updatedElements);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const elementType = event.dataTransfer.getData("elementType");
    if (elementType === "header") {
      addFormElement("header");
    } else if (elementType === "paragraph") {
      addFormElement("paragraph");
      setDraggingElement(false);
    } else {
      addFormElement(elementType);
    }
    setDraggingElement("");
  };

  const handleItemClick = (elementType) => {
    addFormElement(elementType);
  };

  const handleLabelChange = (event, index) => {
    const updatedElements = [...formElements];
    if (updatedElements[index]) {
      updatedElements[index].label = event.target.value;
      setFormElements(updatedElements);
    }
  };

  const handleOptionChange = (event, index) => {
    const updatedOptions = [...editOptions];
    updatedOptions[index] = event.target.value;
    setEditOptions(updatedOptions);
  };

  const handleAddOption = () => {
    setShowAddOptionInput(true);
  };

  const handleDeleteOption = (index) => {
    const updatedOptions = [...editOptions];
    updatedOptions.splice(index, 1);
    setEditOptions(updatedOptions);
  };

  const handleDeleteElement = (index) => {
    const updatedElements = [...formElements];
    updatedElements.splice(index, 1);
    setFormElements(updatedElements);
    setSelectedElementIndex(null);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
  };

  const handleEditModalSave = () => {
    if (selectedElementIndex !== null) {
      const updatedElements = [...formElements];
      if (updatedElements[selectedElementIndex]) {
        updatedElements[selectedElementIndex].label = editLabel;
        if (
          updatedElements[selectedElementIndex].type !== "header" &&
          updatedElements[selectedElementIndex].type !== "paragraph"
        ) {
          updatedElements[selectedElementIndex].options = editOptions;
          updatedElements[selectedElementIndex].required = isRequired;
        } else if (updatedElements[selectedElementIndex].type === "header") {
          updatedElements[selectedElementIndex].value = editHeaderValue;
        } else if (updatedElements[selectedElementIndex].type === "paragraph") {
          updatedElements[selectedElementIndex].value = editParagraphValue;
        }
        setFormElements(updatedElements);
      }
      handleEditModalClose();
    }
  };

  const openEditModal = (index) => {
    setSelectedElementIndex(index);
    if (formElements[index]) {
      setEditLabel(formElements[index].label);
      setEditOptions(formElements[index].options);
      setIsRequired(formElements[index].required);
      setEditHeaderValue(formElements[index].value);
      setEditParagraphValue(formElements[index].value);
    }
    setShowEditModal(true);
  };

  const handleSubmitFormBuilder = () => {
    console.log({ formElements });
    let prestemp = [...historyTemplate];
    prestemp[activePage - 1] = formElements;
    setHistoryTemplate(prestemp);
    let editbuttons = [...editClicked];
    editbuttons[activePage - 1] = false;
    setEditClicked(editbuttons);
  };

  const addHeaderToForm = () => {
    addFormElement("header");
  };

  const addParagraphToForm = () => {
    addFormElement("paragraph");
  };

  const handleHeaderEditModalSave = () => {
    if (selectedElementIndex !== null) {
      const updatedElements = [...formElements];
      if (updatedElements[selectedElementIndex]) {
        updatedElements[selectedElementIndex].value = editHeaderValue;
        setFormElements(updatedElements);
      }
      handleEditModalClose();
    }
  };

  const handleParagraphEditModalSave = () => {
    if (selectedElementIndex !== null) {
      const updatedElements = [...formElements];
      if (updatedElements[selectedElementIndex]) {
        updatedElements[selectedElementIndex].value = editParagraphValue;
        setFormElements(updatedElements);
      }
      handleEditModalClose();
    }
  };

  const [open, setOpen] = useState([]);
  const toggle = (id) => {
    if (editHistoryIndex !== "") {
      alert("Close all the edits.");
    } else if (open.includes(id)) {
      setOpen(open.filter((key) => key !== id));
    } else {
      setOpen([...open, id]);
    }
  };

  const [addNewHistory, setAddNewHistory] = useState(false);

  const [editHistoryIndex, setEditHistoryIndex] = useState("");

  const [activePageEdit, setActivePageEdit] = useState(1);

  const [finalHistoryTemplate, setFinalHistoryTemplate] = useState(
    getHistoryByIdx?.data?.data?.history[
      editHistoryIndex ? editHistoryIndex : 0
    ]?.historyData
  );

  console.log(getHistoryByIdx?.data?.data?.history[0]);

  const editHistoryClicked = (index) => {
    console.log(index);
    setEditHistoryIndex(index);
    setFormElements(
      getHistoryByIdx?.data?.data?.history[index]?.historyData[
        activePageEdit - 1
      ]
    );
    setOpen([index]);
  };

  const handlePageChangeEdit = (number) => {
    setFinalHistoryTemplate(
      getHistoryByIdx?.data?.data?.history[
        editHistoryIndex ? editHistoryIndex : 0
      ]?.historyData
    );
    let temptemplate = [
      ...getHistoryByIdx?.data?.data?.history[
        editHistoryIndex ? editHistoryIndex : 0
      ]?.historyData,
    ];
    temptemplate[activePageEdit - 1] = formElements;
    setFinalHistoryTemplate(temptemplate);
    setActivePageEdit(number);
    setFormElements(
      getHistoryByIdx?.data?.data?.history[editHistoryIndex]?.historyData[
        number - 1
      ]
    );
  };

  const handleSubmitFormBuilderEdit = () => {
    setFinalHistoryTemplate(
      getHistoryByIdx?.data?.data?.history[
        editHistoryIndex ? editHistoryIndex : 0
      ]?.historyData
    );
    let temptemplate = [
      ...getHistoryByIdx?.data?.data?.history[
        editHistoryIndex ? editHistoryIndex : 0
      ]?.historyData,
    ];
    temptemplate[activePageEdit - 1] = formElements;
    setFinalHistoryTemplate(temptemplate);
    console.log(temptemplate);
    setEditHistoryIndex("");
    dispatch(
      editHistoryByIdx(
        userdata?.data?.data?.historyId,
        getHistoryByIdx?.data?.data?.history?.length - 1 - editHistoryIndex,
        temptemplate,
        clinicName
      )
    );

    console.log(userdata?.data?.data?.historyId);
    dispatch(getPatientHistory(userdata?.data?.data?.historyId, clinicName));
  };

  const handleEditDisableEdit = () => {
    setAddNewHistory(false);

    setEditHistoryIndex("");

    setActivePageEdit(1);

    setFinalHistoryTemplate(
      getHistoryByIdx?.data?.data?.history[
        editHistoryIndex ? editHistoryIndex : 0
      ]?.historyData
    );

    setFormElements([]);
  };

  const handleValueChangeEdit = (event, index) => {
    setFormElements((prevElements) => {
      return prevElements.map((element, i) =>
        i === index ? { ...element, value: event.target.value } : element
      );
    });
  };

  const handleCheckboxRadioChangeEdit = (event, label, option, eleindex) => {
    if (event.target.type === "checkbox") {
      // For checkboxes, toggle the selected option
      const selectedOptions = formElements[eleindex]?.value || [];
      const updatedOptions = event.target.checked
        ? [...selectedOptions, option]
        : selectedOptions.filter((item) => item !== option);

      const templateArray = formElements;

      const updatedFormData = templateArray.map((element, arrIndex) => {
        if (arrIndex === eleindex) {
          return { ...element, value: updatedOptions };
        }
        return element;
      });
      let templates = [...formElements];
      templates = updatedFormData;

      setFormElements(templates);
    } else if (event.target.type === "radio") {
      // For radio buttons, select only the clicked option
      const templateArray = formElements;

      const updatedFormData = templateArray.map((element, arrIndex) => {
        if (arrIndex === eleindex) {
          return { ...element, value: option };
        }
        return element;
      });
      let templates = [...formElements];
      templates = updatedFormData;

      setFormElements(templates);
    }
  };

  const handleMultiselectChangeEdit = (selectedOptions, label, eleindex) => {
    // Extract labels and values from selectedOptions
    const selectedValues = selectedOptions.map((option) => ({
      label: option.label,
      value: option.value,
    }));

    const templateArray = formElements;

    const updatedFormData = templateArray.map((element, arrIndex) => {
      if (arrIndex === eleindex) {
        return { ...element, value: selectedValues };
      }
      return element;
    });
    let templates = [...formElements];
    templates = updatedFormData;

    setFormElements(templates);
  };

  // Handler for select
  const handleSelectChangeEdit = (event, label, eleindex) => {
    const templateArray = formElements;

    const updatedFormData = templateArray.map((element, arrIndex) => {
      if (arrIndex === eleindex) {
        return { ...element, value: event?.target?.value };
      }
      return element;
    });
    let templates = [...formElements];
    templates = updatedFormData;

    setFormElements(templates);
  };

  const handleHeaderParagrapghEdit = (event, label, eleindex) => {
    const templateArray = formElements;
    const updatedFormData = templateArray.map((element, arrIndex) => {
      if (arrIndex === eleindex) {
        return { ...element, value: event?.target?.value };
      }
      return element;
    });
    let templates = [...formElements];
    templates = updatedFormData;
    setFormElements(templates);
  };

  const handleNotParagraphEditModalSaveEdit = () => {
    console.log(editOptions);
    if (selectedElementIndex !== null) {
      const updatedElements = [...formElements];
      if (updatedElements[selectedElementIndex]) {
        // Create a new object with updated options to avoid mutation of a read-only object
        updatedElements[selectedElementIndex] = {
          ...updatedElements[selectedElementIndex],
          options: editOptions,
        };
        setFormElements(updatedElements);
      }
      handleEditModalClose();
    }
    setEditOptions([]);
  };

  // const reportTemplateRef = useRef();

  // const handleDownloadPdf = useReactToPrint({
  // 	content: () => reportTemplateRef.current,
  // 	documentTitle: "Visitor Pass",
  // 	onBeforeGetContent: () => {
  // 		// Apply styles to the content to add margins
  // 		const content = reportTemplateRef.current;
  // 		content.style.margin = "30mm 0 0 0"; // Adjust the margin as needed
  // 		return content;
  // 	},
  // 	onAfterPrint: () => console.log("Printed PDF successfully!"),
  // });

  // const handleDownloadPdf = async () => {
  // 	if (!reportTemplateRef.current) return;

  // 	const element = reportTemplateRef.current;
  // 	const { width: contentWidth, height: contentHeight } =
  // 		element.getBoundingClientRect();

  // 	const scaleFactor = 2; // Adjust scale factor if necessary

  // 	const canvas = await html2canvas(element, {
  // 		scale: scaleFactor,
  // 		useCORS: true,
  // 		width: contentWidth,
  // 		windowHeight: contentHeight,
  // 		scrollY: -window.scrollY, // Capture the entire content
  // 		logging: false,
  // 		letterRendering: true,
  // 		allowTaint: true,
  // 		imageTimeout: 15000,
  // 		removeContainer: true,
  // 		backgroundColor: "#ffffff",
  // 		dpi: window.devicePixelRatio * 96,
  // 	});

  // 	const imgData = canvas.toDataURL("image/jpeg", 1.0);
  // 	const pdf = new jsPDF({
  // 		orientation: "portrait",
  // 		unit: "mm",
  // 		format: "a4",
  // 	});

  // 	const pdfWidth = 210; // A4 width in mm
  // 	const pdfHeight = (canvas.height * pdfWidth) / canvas.width; // Maintain aspect ratio

  // 	// Ensure that the pdfHeight is within valid bounds for an A4 page
  // 	if (pdfHeight <= 297) {
  // 		// A4 height in mm
  // 		pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
  // 	} else {
  // 		// If the content height exceeds A4 size, we need to handle multiple pages
  // 		let position = 0;
  // 		while (position < canvas.height) {
  // 			const pageHeight = Math.min(
  // 				297,
  // 				((canvas.height - position) * pdfWidth) / canvas.width
  // 			);
  // 			const pageImage = canvas.toDataURL(
  // 				"image/jpeg",
  // 				1.0,
  // 				position,
  // 				0,
  // 				canvas.width,
  // 				pageHeight
  // 			);
  // 			pdf.addImage(pageImage, "JPEG", 0, 0, pdfWidth, pageHeight);
  // 			position += (pageHeight * canvas.width) / pdfWidth;

  // 			if (position < canvas.height) {
  // 				pdf.addPage();
  // 			}
  // 		}
  // 	}

  // 	pdf.save("download.pdf");
  // };

  const [selectedTables, setSelectedTables] = useState([]);

  const handleCheckboxChange = (pageIndex) => {
    if (selectedTables.includes(pageIndex)) {
      setSelectedTables(selectedTables.filter((index) => index !== pageIndex));
    } else {
      setSelectedTables([...selectedTables, pageIndex]);
    }
  };

  const handlePrint = () => {
    // Filter and print the selected tables
    const printContents = selectedTables
      .map(
        (pageIndex) => document.getElementById(`table-${pageIndex}`).outerHTML
      )
      .join("");
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };

  const [deletePageBool, setDeletePageBool] = useState(false);

  const deletePage = (index) => {
    if (index === 0) {
      alert("Atleast 1 page needs to be submitted");
    } else {
      // eslint-disable-next-line no-restricted-globals
      if (confirm("Do you really want to delete this page?")) {
        let pages = [...totalPages];
        let removedItem = pages?.pop();
        setTotalPages(pages);

        let templatesSelect = [...templateName];
        templatesSelect?.splice(index, 1);
        setTemplateName(templatesSelect);

        let templatesIdxSelect = [...templateIdx];
        templatesIdxSelect?.splice(index, 1);
        setTemplateIdx(templatesIdxSelect);

        let historyTemplatesSelect = [...historyTemplate];
        historyTemplatesSelect?.splice(index, 1);
        setHistoryTemplate(historyTemplatesSelect);

        let editbuttons = [...editClicked];
        editbuttons?.splice(index, 1);
        setEditClicked(editbuttons);
        // handlePageChange(1);
        if (index === totalPages?.length - 1) setDeletePageBool(true);
      }
    }
  };

  useEffect(() => {
    if (deletePageBool) {
      console.log("OK");
      console.log(totalPages);
      setActivePage(totalPages?.length);
      setDeletePageBool(false);
    }
  }, [deletePage]);

  console.log(activePage);

  return (
    <div className="pageSize">
      <Meta />
      <Container className="header-center mb-5 mt-3">
        {editHistoryIndex === "" && doctorInfo !== undefined && (
          <Row className="justify-content-end">
            <Col md="auto" className="text-center mt-1">
              <button
                className="btn btn-outline-secondary"
                onClick={() => {
                  setAddNewHistory(true);
                }}
              >
                Add New History
              </button>
            </Col>
          </Row>
        )}
        <div className="header-center card mt-3">
          <Row className="justify-content-center mb-4 mt-4">
            <Col md="3" className="text-center">
              <h6>Patient: {userdata?.data?.data?.name}</h6>
            </Col>
            <Col md="3" className="text-center">
              <h6>Age: {userdata?.data?.data?.age}</h6>
            </Col>
            <Col md="3" className="text-center">
              <h6>Sex: {userdata?.data?.data?.gender}</h6>
            </Col>
            <Col md="3" className="text-center">
              <h6>Contact: {userdata?.data?.data?.mobilenumber}</h6>
            </Col>
          </Row>
        </div>
        {addNewHistory && (
          <Row className="justify-content-center mt-3">
            <Col md="auto" className="text-center mt-1">
              <h6>
                Select the filters{" "}
                <span className="text-danger">
                  <b>*</b>
                </span>
              </h6>
            </Col>
            <Col md="auto" className="text-center mt-1">
              <DropdownButton
                key="down-centered"
                id={`dropdown-button-drop-down-centered`}
                drop="down-centered"
                variant="success"
                title={templateName[activePage - 1]}
                onSelect={TemplateHandler}
              >
                <div className="dropScroll">
                  {getPrescriptionTemplateNames?.data?.data?.historyTemplatesNamesArray.map(
                    (template, index) => (
                      <Dropdown.Item
                        eventKey={
                          template.templateName +
                          "@@" +
                          template._id +
                          "@@" +
                          index
                        }
                      >
                        {template.templateName}
                      </Dropdown.Item>
                    )
                  )}
                </div>
              </DropdownButton>
            </Col>
          </Row>
        )}
      </Container>

      {addNewHistory && templateName[activePage - 1] !== "Choose Template" && (
        <Container>
          <Row className="justify-content-end">
            <Col xs="auto">
              <Button
                variant="outline-success"
                className="mb-4"
                onClick={editBtnClicked}
              >
                Edit Form
              </Button>
            </Col>
          </Row>
        </Container>
      )}
      <Container>
        {addNewHistory &&
          templateName[activePage - 1] !== "Choose Template" &&
          !editClicked[activePage - 1] &&
          Array?.isArray(historyTemplate[activePage - 1]) &&
          historyTemplate[activePage - 1]?.map((element, index) => {
            return (
              <div key={index} className="form-element">
                {element.type === "header" || element.type === "paragraph" ? (
                  element?.type === "header" ? (
                    <h3 className="header-center">{element.value}</h3>
                  ) : (
                    <p>{element.value}</p>
                  )
                ) : (
                  <>
                    <Form.Label>{element.label}</Form.Label>
                    {element.type === "select" ? (
                      <Form.Control
                        as="select"
                        onChange={(e) =>
                          handleSelectChange(
                            e,
                            element.label,
                            activePage - 1,
                            index
                          )
                        }
                      >
                        {element.options.map((option, optionIndex) => (
                          <option key={optionIndex}>{option}</option>
                        ))}
                      </Form.Control>
                    ) : element.type === "multiselect" && element.options ? (
                      <MultiSelect
                        options={element.options.map((option) => ({
                          label: option,
                          value: option,
                        }))}
                        onChange={(selectedOptions) =>
                          handleMultiselectChange(
                            selectedOptions,
                            element.label,
                            activePage - 1,
                            index
                          )
                        }
                        value={element.value || []} // Ensure that formValues[element.label] is an array of objects with label and value properties
                        labelledBy="Select"
                      />
                    ) : element.type === "checkbox" ? (
                      <div>
                        {element.options.map((option, optionIndex) => (
                          <Form.Check
                            key={optionIndex}
                            type="checkbox"
                            label={option}
                            required={element.required}
                            onChange={(e) =>
                              handleCheckboxRadioChange(
                                e,
                                element.label,
                                option,
                                activePage - 1,
                                index
                              )
                            }
                            checked={historyTemplate[activePage - 1][
                              index
                            ]?.value?.includes(option)}
                          />
                        ))}
                      </div>
                    ) : // Inside the return statement of your component

                    element.type === "radio" ? (
                      <div>
                        {element.options.map((option, optionIndex) => (
                          <Form.Check
                            key={optionIndex}
                            type="radio"
                            label={option}
                            required={element.required}
                            onChange={(e) =>
                              handleCheckboxRadioChange(
                                e,
                                element.label,
                                option,
                                activePage - 1,
                                index
                              )
                            }
                            checked={element?.value === option} // Check if the option is selected
                          />
                        ))}
                      </div>
                    ) : element.type === "file" ? (
                      <input
                        type="file"
                        onChange={(e) =>
                          handleInputChange(
                            e,
                            element.label,
                            activePage - 1,
                            index
                          )
                        }
                        className="form-control"
                      />
                    ) : element.type !== "header" &&
                      element.type !== "paragraph" ? (
                      element.type === "input" ? (
                        <FormControl
                          type={element.type}
                          placeholder="Enter Value"
                          value={element?.value || ""}
                          onChange={(e) =>
                            handleInputChange(
                              e,
                              element.label,
                              activePage - 1,
                              index
                            )
                          }
                        />
                      ) : (
                        <textarea
                          className="form-control"
                          placeholder="Enter Value"
                          value={element?.value || ""}
                          onChange={(e) =>
                            handleInputChange(
                              e,
                              element.label,
                              activePage - 1,
                              index
                            )
                          }
                        ></textarea>
                      )
                    ) : null}
                  </>
                )}
              </div>
            );
          })}
        {addNewHistory &&
          templateName[activePage - 1] !== "Choose Template" &&
          editClicked[activePage - 1] &&
          Array?.isArray(historyTemplate[activePage - 1]) && (
            <div className="form-builder">
              <Row>
                <Col md={3} className="toolbox">
                  <h3>Toolbox</h3>
                  <div
                    className="toolbox-item"
                    draggable
                    onDragStart={(e) => handleDragStart(e, "input")}
                  >
                    Input
                  </div>
                  <div
                    className="toolbox-item"
                    draggable
                    onDragStart={(e) => handleDragStart(e, "textarea")}
                  >
                    Textarea
                  </div>
                  <div
                    className="toolbox-item"
                    draggable
                    onDragStart={(e) => handleDragStart(e, "select")}
                  >
                    Select
                  </div>
                  <div
                    className="toolbox-item"
                    draggable
                    onDragStart={(e) => handleDragStart(e, "checkbox")}
                  >
                    Checkbox
                  </div>
                  <div
                    className="toolbox-item"
                    draggable
                    onDragStart={(e) => handleDragStart(e, "radio")}
                  >
                    Radio
                  </div>
                  <div
                    className="toolbox-item"
                    draggable
                    onDragStart={(e) => handleDragStart(e, "header")}
                  >
                    Header
                  </div>
                  <div
                    className="toolbox-item"
                    draggable
                    onDragStart={(e) => handleDragStart(e, "paragraph")}
                  >
                    Paragraph
                  </div>
                  <div
                    className="toolbox-item"
                    draggable
                    onDragStart={(e) => handleDragStart(e, "multiselect")}
                  >
                    MultiSelect
                  </div>
                </Col>
                <Col md={9}>
                  <div
                    className={`form-canvas ${
                      draggingElement === "paragraph" ? "dragged-paragraph" : ""
                    }`}
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={handleDrop}
                  >
                    <Form>
                      {formElements.map((element, index) => (
                        <div
                          key={index}
                          className={`form-element ${
                            selectedElementIndex === index ? "selected" : ""
                          }`}
                        >
                          <div className="form-element-header">
                            <div className="label-edit-delete">
                              {element.type !== "header" &&
                              element.type !== "paragraph" ? (
                                <FormLabel className="label">
                                  {element.label}
                                </FormLabel>
                              ) : element.type !== "paragraph" ? (
                                <h3>{element.value}</h3>
                              ) : (
                                <p className="paragraph">{element.value}</p>
                              )}
                              <div className="edit-delete-buttons">
                                <button
                                  type="button"
                                  className="edit-icon btn"
                                  onClick={() => openEditModal(index)}
                                >
                                  <FontAwesomeIcon icon={faEdit} />
                                </button>
                                <button
                                  type="button"
                                  className="delete-icon btn"
                                  onClick={() => handleDeleteElement(index)}
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="form-element-input">
                            {element.type === "select" ? (
                              <Form.Control as="select">
                                {element.options.map((option, optionIndex) => (
                                  <option key={optionIndex}>{option}</option>
                                ))}
                              </Form.Control>
                            ) : element.type === "multiselect" ? (
                              <MultiSelect
                                options={element.options.map((option) => ({
                                  label: option,
                                  value: option,
                                }))}
                                value={selectedOptions}
                                onChange={setSelectedOptions}
                                labelledBy="Select"
                              />
                            ) : element.type === "checkbox" ? (
                              <div>
                                {element.options.map((option, optionIndex) => (
                                  <Form.Check
                                    key={optionIndex}
                                    type="checkbox"
                                    label={option}
                                    required={element.required}
                                  />
                                ))}
                              </div>
                            ) : element.type === "radio" ? (
                              <div>
                                {element.options.map((option, optionIndex) => (
                                  <Form.Check
                                    key={optionIndex}
                                    type="radio"
                                    label={option}
                                    required={element.required}
                                  />
                                ))}
                              </div>
                            ) : element.type !== "header" &&
                              element.type !== "paragraph" ? (
                              element.type === "input" ? (
                                <FormControl
                                  type={element.type}
                                  placeholder="Enter Value"
                                  value={element.value}
                                  onChange={(e) => handleValueChange(e, index)}
                                />
                              ) : (
                                <textarea
                                  rows={2}
                                  cols={100}
                                  className="form-control"
                                  onChange={(e) => handleValueChange(e, index)}
                                  value={element.value}
                                ></textarea>
                              )
                            ) : null}
                          </div>
                        </div>
                      ))}
                    </Form>
                  </div>
                </Col>
              </Row>

              <Modal show={showEditModal} onHide={handleEditModalClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Edit Form Element</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {formElements[selectedElementIndex]?.type === "header" ? (
                    <>
                      <FormLabel>Edit Value:</FormLabel>
                      <FormControl
                        type="text"
                        value={editHeaderValue}
                        onChange={(e) => setEditHeaderValue(e.target.value)}
                      />
                    </>
                  ) : formElements[selectedElementIndex]?.type ===
                    "paragraph" ? (
                    <>
                      <FormLabel>Edit Value:</FormLabel>
                      <FormControl
                        type="text"
                        value={editParagraphValue}
                        onChange={(e) => setEditParagraphValue(e.target.value)}
                      />
                    </>
                  ) : (
                    <>
                      <FormLabel>Edit Label:</FormLabel>
                      <FormControl
                        type="text"
                        value={editLabel}
                        onChange={(e) => setEditLabel(e.target.value)}
                      />
                      {formElements[selectedElementIndex]?.type !== "header" &&
                      formElements[selectedElementIndex]?.type !==
                        "paragraph" &&
                      (formElements[selectedElementIndex]?.type ===
                        "checkbox" ||
                        formElements[selectedElementIndex]?.type === "radio" ||
                        formElements[selectedElementIndex]?.type === "select" ||
                        formElements[selectedElementIndex]?.type ===
                          "multiselect") ? (
                        <>
                          <FormLabel>Edit Options:</FormLabel>
                          {editOptions.map((option, index) => (
                            <div key={index} className="header-center">
                              <Row className="mt-1">
                                <Col md="10">
                                  <FormControl
                                    type="text"
                                    value={option}
                                    onChange={(e) =>
                                      handleOptionChange(e, index)
                                    }
                                  />
                                </Col>
                                <Col md="1">
                                  <Button
                                    variant="danger"
                                    onClick={() => handleDeleteOption(index)}
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </Button>
                                </Col>
                              </Row>
                              {index === editOptions.length - 1 && (
                                <Button
                                  variant="primary"
                                  onClick={handleAddOption}
                                  className="mt-2 mb-2"
                                >
                                  <FontAwesomeIcon icon={faPlus} /> Add
                                </Button>
                              )}
                            </div>
                          ))}
                          {showAddOptionInput && (
                            <Row>
                              <Col md="10">
                                <FormControl
                                  type="text"
                                  value={newOption}
                                  onChange={(e) => setNewOption(e.target.value)}
                                  placeholder="New Option"
                                />
                              </Col>
                              <Col md="1">
                                <Button
                                  variant="primary"
                                  onClick={() => {
                                    setEditOptions([...editOptions, newOption]);
                                    setNewOption("");
                                    setShowAddOptionInput(false);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faCheck} />
                                </Button>
                              </Col>
                            </Row>
                          )}
                        </>
                      ) : null}
                      {formElements[selectedElementIndex]?.type !== "header" &&
                        formElements[selectedElementIndex]?.type !==
                          "paragraph" && (
                          <FormCheck
                            type="checkbox"
                            label="Required"
                            checked={isRequired}
                            onChange={() => setIsRequired(!isRequired)}
                          />
                        )}
                    </>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleEditModalClose}>
                    Close
                  </Button>
                  {formElements[selectedElementIndex]?.type === "header" ? (
                    <Button
                      variant="primary"
                      onClick={handleHeaderEditModalSave}
                    >
                      Save Changes
                    </Button>
                  ) : formElements[selectedElementIndex]?.type ===
                    "paragraph" ? (
                    <Button
                      variant="primary"
                      onClick={handleParagraphEditModalSave}
                    >
                      Save Changes
                    </Button>
                  ) : (
                    <Button variant="primary" onClick={handleEditModalSave}>
                      Save Changes
                    </Button>
                  )}
                </Modal.Footer>
              </Modal>
            </div>
          )}

        {addNewHistory &&
          templateName[activePage - 1] !== "Choose Template" &&
          (!editClicked[activePage - 1] ? (
            <Row className="justify-content-center">
              <Button variant="primary" onClick={handleSubmitForm}>
                Submit
              </Button>
            </Row>
          ) : (
            <Row className="justify-content-center">
              <Button variant="primary" onClick={handleSubmitFormBuilder}>
                Save Changes
              </Button>
              <Button
                variant="danger"
                className="ml-3"
                onClick={handleEditDisable}
              >
                Discard Changes
              </Button>
            </Row>
          ))}
      </Container>

      {!addNewHistory &&
        getHistoryByIdx?.data?.data?.history?.map((history, i) => {
          let timestamp = Number(history?.createdAt);
          let date = new Date(timestamp);

          // Format the date to 12-hour format with AM/PM
          let options = {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: true,
          };
          let formattedDate = date.toLocaleString("en-US", options);
          var f = 1;
          return (
            <Container>
              <Accordion open={open} toggle={toggle} className="mt-1">
                <AccordionItem>
                  <AccordionHeader targetId={i}>
                    <div className="d-flex justify-content-between w-100">
                      <div>Created By: {history?.doctorName}</div>
                      <div className="text-end">
                        Created On: {formattedDate}
                      </div>
                    </div>
                  </AccordionHeader>
                  <AccordionBody accordionId={i}>
                    {editHistoryIndex === "" ? (
                      <>
                        {doctorInfo !== undefined && (
                          <Row className="justify-content-end mb-2">
                            <Col>
                              <button
                                className="btn btn-outline-success mb-2"
                                onClick={() => editHistoryClicked(i)}
                              >
                                Edit
                              </button>
                            </Col>
                            {/* <Col>
														<button
															className='btn btn-success'
															onClick={handlePrint}>
															Print Selected
														</button>
													</Col> */}
                          </Row>
                        )}
                        {history?.historyData?.map((page, pageIndex) => (
                          <div key={`table-${pageIndex}`}>
                            {/* <input
															type='checkbox'
															onChange={() => handleCheckboxChange(pageIndex)}
															checked={selectedTables.includes(pageIndex)}
														/> */}
                            <Table
                              bordered
                              className="custom-table"
                              id={`table-${pageIndex}`}
                            >
                              <tbody>
                                {page.map((item, idx) => {
                                  if (f === 0) {
                                    f = 1;
                                  } else if (
                                    item.label === "Header" ||
                                    item.label === "Paragraph"
                                  ) {
                                    return (
                                      <tr key={`${item.label}-${idx}`}>
                                        <td colSpan={4}>
                                          {item.label === "Header" ? (
                                            <h3>
                                              {Array.isArray(item.value)
                                                ? item.value.join(", ")
                                                : item.value}
                                            </h3>
                                          ) : (
                                            <p>
                                              {Array.isArray(item.value)
                                                ? item.value.join(", ")
                                                : item.value}
                                            </p>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  } else {
                                    return (
                                      <tr key={`${item.label}-${idx}`}>
                                        <td>
                                          <b>{item.label}: </b>
                                        </td>
                                        <td>
                                          {item.type === "textarea" &&
                                          item.value !== undefined
                                            ? item.value
                                            : Array.isArray(item.value) &&
                                              item.type === "multiselect"
                                            ? Array.isArray(item.value) &&
                                              item.value
                                                .map((option) => option.label)
                                                .join(", ")
                                            : item.type === "checkbox"
                                            ? Array.isArray(item.value) &&
                                              item.value
                                                .map((option) => option)
                                                .join(", ")
                                            : item.value}
                                        </td>
                                        {page[idx + 1] &&
                                        page[idx + 1].label !== "Header" &&
                                        page[idx + 1].label !== "Paragraph" ? (
                                          <>
                                            <div className="d-none">
                                              {(f = 0)}
                                            </div>
                                            <td>
                                              <b>{page[idx + 1].label}: </b>
                                            </td>
                                            <td>
                                              {page[idx + 1].type ===
                                                "textarea" &&
                                              page[idx + 1].value !== undefined
                                                ? page[idx + 1].value
                                                : Array.isArray(
                                                    page[idx + 1].value
                                                  ) &&
                                                  page[idx + 1].type ===
                                                    "multiselect"
                                                ? Array.isArray(
                                                    page[idx + 1].value
                                                  ) &&
                                                  page[idx + 1].value
                                                    .map(
                                                      (option) => option.label
                                                    )
                                                    .join(", ")
                                                : page[idx + 1].type ===
                                                  "checkbox"
                                                ? Array.isArray(
                                                    page[idx + 1].value
                                                  ) &&
                                                  page[idx + 1].value
                                                    .map((option) => option)
                                                    .join(", ")
                                                : page[idx + 1].value}
                                            </td>
                                          </>
                                        ) : null}
                                      </tr>
                                    );
                                  }
                                })}
                              </tbody>
                            </Table>
                          </div>
                        ))}
                      </>
                    ) : (
                      Array?.isArray(history?.historyData) && (
                        <div className="form-builder">
                          <Row>
                            <Col md={3} className="toolbox">
                              <h3>Toolbox</h3>
                              <div
                                className="toolbox-item"
                                draggable
                                onDragStart={(e) => handleDragStart(e, "input")}
                              >
                                Input
                              </div>
                              <div
                                className="toolbox-item"
                                draggable
                                onDragStart={(e) =>
                                  handleDragStart(e, "textarea")
                                }
                              >
                                Textarea
                              </div>
                              <div
                                className="toolbox-item"
                                draggable
                                onDragStart={(e) =>
                                  handleDragStart(e, "select")
                                }
                              >
                                Select
                              </div>
                              <div
                                className="toolbox-item"
                                draggable
                                onDragStart={(e) =>
                                  handleDragStart(e, "checkbox")
                                }
                              >
                                Checkbox
                              </div>
                              <div
                                className="toolbox-item"
                                draggable
                                onDragStart={(e) => handleDragStart(e, "radio")}
                              >
                                Radio
                              </div>
                              <div
                                className="toolbox-item"
                                draggable
                                onDragStart={(e) =>
                                  handleDragStart(e, "header")
                                }
                              >
                                Header
                              </div>
                              <div
                                className="toolbox-item"
                                draggable
                                onDragStart={(e) =>
                                  handleDragStart(e, "paragraph")
                                }
                              >
                                Paragraph
                              </div>
                              <div
                                className="toolbox-item"
                                draggable
                                onDragStart={(e) =>
                                  handleDragStart(e, "multiselect")
                                }
                              >
                                MultiSelect
                              </div>
                            </Col>
                            <Col md={9}>
                              <div
                                className={`form-canvas ${
                                  draggingElement === "paragraph"
                                    ? "dragged-paragraph"
                                    : ""
                                }`}
                                onDragOver={(e) => e.preventDefault()}
                                onDrop={handleDrop}
                              >
                                <Form>
                                  {formElements.map((element, index) => (
                                    <div
                                      key={index}
                                      className={`form-element ${
                                        selectedElementIndex === index
                                          ? "selected"
                                          : ""
                                      }`}
                                    >
                                      <div className="form-element-header">
                                        <div className="label-edit-delete">
                                          {element.type !== "header" &&
                                          element.type !== "paragraph" ? (
                                            <FormLabel className="label">
                                              {element.label}
                                            </FormLabel>
                                          ) : element.type !== "paragraph" ? (
                                            <h3>{element.value}</h3>
                                          ) : (
                                            <p className="paragraph">
                                              {element.value}
                                            </p>
                                          )}
                                          <div className="edit-delete-buttons">
                                            <button
                                              type="button"
                                              className="edit-icon btn"
                                              onClick={() =>
                                                openEditModal(index)
                                              }
                                            >
                                              <FontAwesomeIcon icon={faEdit} />
                                            </button>
                                            <button
                                              type="button"
                                              className="delete-icon btn"
                                              onClick={() =>
                                                handleDeleteElement(index)
                                              }
                                            >
                                              <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="form-element-input">
                                        {element.type === "select" ? (
                                          <Form.Control
                                            as="select"
                                            value={element.value || []}
                                            onChange={(e) =>
                                              handleSelectChangeEdit(
                                                e,
                                                element.label,
                                                index
                                              )
                                            }
                                          >
                                            {element.options.map(
                                              (option, optionIndex) => (
                                                <option key={optionIndex}>
                                                  {option}
                                                </option>
                                              )
                                            )}
                                          </Form.Control>
                                        ) : element.type === "multiselect" ? (
                                          <MultiSelect
                                            options={element.options.map(
                                              (option) => ({
                                                label: option,
                                                value: option,
                                              })
                                            )}
                                            // value={selectedOptions}
                                            // onChange={setSelectedOptions}
                                            labelledBy="Select"
                                            value={element.value || []}
                                            onChange={(selectedOptions) =>
                                              handleMultiselectChangeEdit(
                                                selectedOptions,
                                                element.label,

                                                index
                                              )
                                            }
                                          />
                                        ) : element.type === "checkbox" ? (
                                          <div>
                                            {element.options.map(
                                              (option, optionIndex) => (
                                                <Form.Check
                                                  key={optionIndex}
                                                  type="checkbox"
                                                  label={option}
                                                  required={element.required}
                                                  checked={formElements[
                                                    index
                                                  ]?.value?.includes(option)}
                                                  onChange={(e) =>
                                                    handleCheckboxRadioChangeEdit(
                                                      e,
                                                      element.label,
                                                      option,
                                                      index
                                                    )
                                                  }
                                                />
                                              )
                                            )}
                                          </div>
                                        ) : element.type === "radio" ? (
                                          <div>
                                            {element.options.map(
                                              (option, optionIndex) => (
                                                <Form.Check
                                                  key={optionIndex}
                                                  type="radio"
                                                  label={option}
                                                  required={element.required}
                                                  checked={
                                                    element?.value === option
                                                  }
                                                  onChange={(e) =>
                                                    handleCheckboxRadioChangeEdit(
                                                      e,
                                                      element.label,
                                                      option,
                                                      index
                                                    )
                                                  }
                                                />
                                              )
                                            )}
                                          </div>
                                        ) : element.type !== "header" &&
                                          element.type !== "paragraph" ? (
                                          element.type === "input" ? (
                                            <FormControl
                                              type={element.type}
                                              placeholder="Enter Value"
                                              value={element.value}
                                              onChange={(e) =>
                                                handleValueChangeEdit(e, index)
                                              }
                                            />
                                          ) : (
                                            <textarea
                                              rows={2}
                                              cols={100}
                                              className="form-control"
                                              onChange={(e) =>
                                                handleValueChangeEdit(e, index)
                                              }
                                              value={element.value}
                                            ></textarea>
                                          )
                                        ) : null}
                                      </div>
                                    </div>
                                  ))}
                                </Form>
                              </div>
                            </Col>
                          </Row>

                          <Modal
                            show={showEditModal}
                            onHide={handleEditModalClose}
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>Edit Form Element</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              {formElements[selectedElementIndex]?.type ===
                              "header" ? (
                                <>
                                  <FormLabel>Edit Value:</FormLabel>
                                  <FormControl
                                    type="text"
                                    value={
                                      formElements[selectedElementIndex].value
                                    }
                                    onChange={(e) =>
                                      handleHeaderParagrapghEdit(
                                        e,
                                        formElements[selectedElementIndex]
                                          .label,
                                        selectedElementIndex
                                      )
                                    }
                                  />
                                </>
                              ) : formElements[selectedElementIndex]?.type ===
                                "paragraph" ? (
                                <>
                                  <FormLabel>Edit Value:</FormLabel>
                                  <FormControl
                                    type="text"
                                    value={
                                      formElements[selectedElementIndex].value
                                    }
                                    onChange={(e) =>
                                      handleHeaderParagrapghEdit(
                                        e,
                                        formElements[selectedElementIndex]
                                          .label,
                                        selectedElementIndex
                                      )
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  <FormLabel>Edit Label:</FormLabel>
                                  <FormControl
                                    type="text"
                                    value={editLabel}
                                    onChange={(e) =>
                                      setEditLabel(e.target.value)
                                    }
                                  />
                                  {formElements[selectedElementIndex]?.type !==
                                    "header" &&
                                  formElements[selectedElementIndex]?.type !==
                                    "paragraph" &&
                                  (formElements[selectedElementIndex]?.type ===
                                    "checkbox" ||
                                    formElements[selectedElementIndex]?.type ===
                                      "radio" ||
                                    formElements[selectedElementIndex]?.type ===
                                      "select" ||
                                    formElements[selectedElementIndex]?.type ===
                                      "multiselect") ? (
                                    <>
                                      <FormLabel>Edit Options:</FormLabel>
                                      {editOptions.map((option, index) => (
                                        <div
                                          key={index}
                                          className="header-center"
                                        >
                                          <Row className="mt-1">
                                            <Col md="10">
                                              <FormControl
                                                type="text"
                                                value={option}
                                                onChange={(e) =>
                                                  handleOptionChange(e, index)
                                                }
                                              />
                                            </Col>
                                            <Col md="1">
                                              <Button
                                                variant="danger"
                                                onClick={() =>
                                                  handleDeleteOption(index)
                                                }
                                              >
                                                <FontAwesomeIcon
                                                  icon={faTrash}
                                                />
                                              </Button>
                                            </Col>
                                          </Row>
                                          {index === editOptions.length - 1 && (
                                            <Button
                                              variant="primary"
                                              onClick={handleAddOption}
                                              className="mt-2 mb-2"
                                            >
                                              <FontAwesomeIcon icon={faPlus} />{" "}
                                              Add
                                            </Button>
                                          )}
                                        </div>
                                      ))}
                                      {showAddOptionInput && (
                                        <Row>
                                          <Col md="10">
                                            <FormControl
                                              type="text"
                                              value={newOption}
                                              onChange={(e) =>
                                                setNewOption(e.target.value)
                                              }
                                              placeholder="New Option"
                                            />
                                          </Col>
                                          <Col md="1">
                                            <Button
                                              variant="primary"
                                              onClick={() => {
                                                setEditOptions([
                                                  ...editOptions,
                                                  newOption,
                                                ]);
                                                setNewOption("");
                                                setShowAddOptionInput(false);
                                              }}
                                            >
                                              <FontAwesomeIcon icon={faCheck} />
                                            </Button>
                                          </Col>
                                        </Row>
                                      )}
                                    </>
                                  ) : null}
                                  {formElements[selectedElementIndex]?.type !==
                                    "header" &&
                                    formElements[selectedElementIndex]?.type !==
                                      "paragraph" && (
                                      <FormCheck
                                        type="checkbox"
                                        label="Required"
                                        checked={isRequired}
                                        onChange={() =>
                                          setIsRequired(!isRequired)
                                        }
                                      />
                                    )}
                                </>
                              )}
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                variant="secondary"
                                onClick={handleEditModalClose}
                              >
                                Close
                              </Button>
                              {formElements[selectedElementIndex]?.type ===
                              "header" ? (
                                <Button
                                  variant="primary"
                                  onClick={handleEditModalClose}
                                >
                                  Save Changes
                                </Button>
                              ) : formElements[selectedElementIndex]?.type ===
                                "paragraph" ? (
                                <Button
                                  variant="primary"
                                  onClick={handleEditModalClose}
                                >
                                  Save Changes
                                </Button>
                              ) : (
                                <Button
                                  variant="primary"
                                  onClick={handleNotParagraphEditModalSaveEdit}
                                >
                                  Save Changes
                                </Button>
                              )}
                            </Modal.Footer>
                          </Modal>
                        </div>
                      )
                    )}
                    {editHistoryIndex !== "" && (
                      <Container className="pagination-container mt-5">
                        <Pagination>
                          {getHistoryByIdx?.data?.data?.history[
                            editHistoryIndex
                          ]?.historyData?.map((data, number) => (
                            <Pagination.Item
                              key={number + 1}
                              active={number + 1 === activePageEdit}
                              onClick={() => handlePageChangeEdit(number + 1)}
                            >
                              {number + 1}
                            </Pagination.Item>
                          ))}
                        </Pagination>
                      </Container>
                    )}
                    {editHistoryIndex !== "" && (
                      <Row className="justify-content-center">
                        <Button
                          variant="primary"
                          onClick={handleSubmitFormBuilderEdit}
                        >
                          Save Changes
                        </Button>
                        <Button
                          variant="danger"
                          className="ml-3"
                          onClick={handleEditDisableEdit}
                        >
                          Discard Changes
                        </Button>
                      </Row>
                    )}
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
            </Container>
          );
        })}

      {addNewHistory &&
        (editClicked[activePage - 1] ? (
          <Container className="pagination-container mt-5">
            <Pagination>
              {totalPages?.map((number) => (
                <Pagination.Item
                  key={number + 1}
                  active={number + 1 === activePage}
                  onClick={() => handlePageChange(number + 1)}
                  disabled
                >
                  {number + 1} {templateName[number]}
                </Pagination.Item>
              ))}

              <Button
                variant="secondary"
                onClick={handleAddPage}
                className="ml-1"
                disabled
              >
                Add Page
              </Button>
              <button
                className="btn btn-danger"
                onClick={() => deletePage(activePage - 1)}
                disabled
              >
                Delete current page <i className="fa fa-trash"></i>
              </button>
            </Pagination>
          </Container>
        ) : (
          <Container className="pagination-container mt-5">
            <Pagination>
              {totalPages?.map((number) => (
                <Pagination.Item
                  key={number + 1}
                  active={number + 1 === activePage}
                  onClick={() => handlePageChange(number + 1)}
                >
                  {number + 1} {templateName[number]}
                </Pagination.Item>
              ))}

              <Button
                variant="secondary"
                onClick={handleAddPage}
                className="ml-1"
              >
                Add Page
              </Button>
              <button
                className="btn btn-danger"
                onClick={() => deletePage(activePage - 1)}
              >
                Delete current page <i className="fa fa-trash"></i>
              </button>
            </Pagination>
          </Container>
        ))}
    </div>
  );
};

export default StartHistoryScreens;

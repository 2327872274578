/** @format */

// /** @format */

// // /** @format */

// import React, { useState } from 'react';
// import {
// 	Button,
// 	Form,
// 	FormControl,
// 	FormLabel,
// 	FormCheck,
// 	Row,
// 	Col,
// 	Modal,
// } from 'react-bootstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {
// 	faEdit,
// 	faTrash,
// 	faPlus,
// 	faCheck,
// } from '@fortawesome/free-solid-svg-icons';
// import { MultiSelect } from 'react-multi-select-component';
// import '../../../styles/FormBuilder.css'; // Import your CSS file for styling
// import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import { PrescriptionCreator } from '../../../redux/doctorRedux/doctorApiCalls';

// const HistoryCreatorScreens = () => {
// 	const dispatch = useDispatch();
// 	const history = useNavigate();
// 	const { doctorInfo } = useSelector((state) => state.doctorLogin);
// 	const [templateName, setTemplateName] = useState('');
// 	const [formElements, setFormElements] = useState([]);
// 	const [selectedElementIndex, setSelectedElementIndex] = useState(null);
// 	const [showEditModal, setShowEditModal] = useState(false);
// 	const [editLabel, setEditLabel] = useState('');
// 	const [editOptions, setEditOptions] = useState([]);
// 	const [isRequired, setIsRequired] = useState(false);
// 	const [editHeaderValue, setEditHeaderValue] = useState('');
// 	const [editParagraphValue, setEditParagraphValue] = useState('');
// 	const [draggingElement, setDraggingElement] = useState('');
// 	const [selectedOptions, setSelectedOptions] = useState([]);
// 	const [editModalOptions, setEditModalOptions] = useState([]);
// 	const [newOption, setNewOption] = useState('');
// 	const [showAddOptionInput, setShowAddOptionInput] = useState(false);
// 	const [tableConfig, setTableConfig] = useState({ rows: 3, columns: 3 });
// 	const [showTableConfigModal, setShowTableConfigModal] = useState(false);
// 	const [editMode, setEditMode] = useState(false);
// 	const [selectedCell, setSelectedCell] = useState(null);
// 	const [editedContent, setEditedContent] = useState('');
// 	console.log(selectedOptions);
// 	const openTableConfigModal = () => {
// 		setShowTableConfigModal(true);
// 	};

// 	const closeTableConfigModal = () => {
// 		setShowTableConfigModal(false);
// 	};

// 	const enterEditMode = (cellIndex) => {
// 		setEditMode(true);
// 		setSelectedCell(cellIndex);
// 		setEditedContent(formElements[selectedElementIndex].value[cellIndex]);
// 	};

// 	const exitEditMode = () => {
// 		setEditMode(false);
// 		setSelectedCell(null);
// 		setEditedContent('');
// 	};

// 	const saveEditedContent = () => {
// 		const updatedElements = [...formElements];
// 		updatedElements[selectedElementIndex].value[selectedCell] = editedContent;
// 		setFormElements(updatedElements);
// 		exitEditMode();
// 	};

// 	const addFormElement = (elementType, label = 'Label Name') => {
// 		let defaultOptions = [];
// 		if (
// 			elementType === 'radio' ||
// 			elementType === 'checkbox' ||
// 			elementType === 'select' ||
// 			elementType === 'multiselect'
// 		) {
// 			defaultOptions = ['Option 1', 'Option 2', 'Option 3'];
// 		}
// 		setFormElements([
// 			...formElements,
// 			{
// 				type: elementType,
// 				label:
// 					elementType === 'header'
// 						? 'Header'
// 						: elementType === 'paragraph'
// 						? 'Paragraph'
// 						: label,
// 				value:
// 					elementType === 'header'
// 						? 'Header'
// 						: elementType === 'paragraph'
// 						? 'Paragraph'
// 						: '',
// 				options: elementType === 'header' ? [] : defaultOptions,
// 				required: false,
// 			},
// 		]);
// 	};

// 	const handleDragStart = (event, elementType) => {
// 		event.dataTransfer.setData('elementType', elementType);
// 		setDraggingElement(elementType);
// 		if (elementType === 'table') {
// 			handleAddTable();
// 		}
// 	};

// 	const handleValueChange = (event, index) => {
// 		const updatedElements = [...formElements];
// 		updatedElements[index].value = event.target.value;
// 		setFormElements(updatedElements);
// 	};

// 	const handleDrop = (event) => {
// 		event.preventDefault();
// 		const elementType = event.dataTransfer.getData('elementType');
// 		if (elementType === 'header') {
// 			addFormElement('header');
// 		} else if (elementType === 'paragraph') {
// 			addFormElement('paragraph');
// 			setDraggingElement(false);
// 		} else if (elementType === 'table') {
// 			// Add table element
// 			addFormElement('table');
// 		} else {
// 			addFormElement(elementType);
// 		}
// 		setDraggingElement('');
// 	};

// 	const handleItemClick = (elementType) => {
// 		addFormElement(elementType);
// 	};

// 	const handleFormLabelChange = (event) => {
// 		setTemplateName(event.target.value);
// 	};

// 	const handleLabelChange = (event, index) => {
// 		const updatedElements = [...formElements];
// 		if (updatedElements[index]) {
// 			updatedElements[index].label = event.target.value;
// 			setFormElements(updatedElements);
// 		}
// 	};

// 	const handleOptionChange = (event, index) => {
// 		const updatedOptions = [...editOptions];
// 		updatedOptions[index] = event.target.value;
// 		setEditOptions(updatedOptions);
// 	};

// 	const handleAddOption = () => {
// 		setShowAddOptionInput(true);
// 	};

// 	const handleDeleteOption = (index) => {
// 		const updatedOptions = [...editOptions];
// 		updatedOptions.splice(index, 1);
// 		setEditOptions(updatedOptions);
// 	};

// 	const handleDeleteElement = (index) => {
// 		const updatedElements = [...formElements];
// 		updatedElements.splice(index, 1);
// 		setFormElements(updatedElements);
// 		setSelectedElementIndex(null);
// 	};

// 	const handleEditModalClose = () => {
// 		setShowEditModal(false);
// 	};

// 	const handleEditModalSave = () => {
// 		if (selectedElementIndex !== null) {
// 			const updatedElements = [...formElements];
// 			if (updatedElements[selectedElementIndex]) {
// 				updatedElements[selectedElementIndex].label = editLabel;
// 				if (
// 					updatedElements[selectedElementIndex].type !== 'header' &&
// 					updatedElements[selectedElementIndex].type !== 'paragraph'
// 				) {
// 					updatedElements[selectedElementIndex].options = editOptions;
// 					updatedElements[selectedElementIndex].required = isRequired;
// 				} else if (updatedElements[selectedElementIndex].type === 'header') {
// 					updatedElements[selectedElementIndex].value = editHeaderValue;
// 				} else if (updatedElements[selectedElementIndex].type === 'paragraph') {
// 					updatedElements[selectedElementIndex].value = editParagraphValue;
// 				}
// 				setFormElements(updatedElements);
// 			}
// 			handleEditModalClose();
// 		}
// 	};

// 	const openEditModal = (index) => {
// 		setSelectedElementIndex(index);
// 		if (formElements[index]) {
// 			setEditLabel(formElements[index].label);
// 			setEditOptions(formElements[index].options);
// 			setIsRequired(formElements[index].required);
// 			setEditHeaderValue(formElements[index].value);
// 			setEditParagraphValue(formElements[index].value);
// 		}
// 		setShowEditModal(true);
// 	};

// 	const handleSubmitForm = () => {
// 		console.log({ templateName, formElements });
// 		// Submit form data here
// 		 ;
// 		// dispatch(
// 		// 	PrescriptionCreator(
// 		// 		templateName,
// 		// 		formElements,
// 		// 		doctorInfo?.data?.user?.specialityId
// 		// 	)
// 		// );
// 	};

// 	const addHeaderToForm = () => {
// 		addFormElement('header');
// 	};

// 	const addParagraphToForm = () => {
// 		addFormElement('paragraph');
// 	};

// 	const handleHeaderEditModalSave = () => {
// 		if (selectedElementIndex !== null) {
// 			const updatedElements = [...formElements];
// 			if (updatedElements[selectedElementIndex]) {
// 				updatedElements[selectedElementIndex].value = editHeaderValue;
// 				setFormElements(updatedElements);
// 			}
// 			handleEditModalClose();
// 		}
// 	};

// 	const handleParagraphEditModalSave = () => {
// 		if (selectedElementIndex !== null) {
// 			const updatedElements = [...formElements];
// 			if (updatedElements[selectedElementIndex]) {
// 				updatedElements[selectedElementIndex].value = editParagraphValue;
// 				setFormElements(updatedElements);
// 			}
// 			handleEditModalClose();
// 		}
// 	};
// 	const addTableToForm = (rows, columns) => {
// 		console.log(rows, columns);
// 		let table = '<table>';
// 		for (let i = 0; i < rows; i++) {
// 			table += '<tr>';
// 			for (let j = 0; j < columns; j++) {
// 				table += '<td>"a"</td>';
// 			}
// 			table += '</tr>';
// 		}
// 		table += '</table>';
// 		setFormElements([
// 			...formElements,
// 			{
// 				type: 'table',
// 				label: 'Table',
// 				value: table,
// 				options: [],
// 				required: false,
// 				row: rows,
// 				cell: columns,
// 			},
// 		]);
// 	};

// 	// This function will be called when the user wants to add a table
// 	const handleAddTable = () => {
// 		// You can prompt the user to specify the number of rows and columns
// 		// For simplicity, let's assume rows = 3 and columns = 3
// 		openTableConfigModal();
// 	};
// 	const handleTableConfigSave = () => {
// 		console.log(tableConfig.rows, tableConfig.columns + 'line 268');
// 		closeTableConfigModal();
// 		addTableToForm(tableConfig.rows, tableConfig.columns);
// 	};

// 	return (
// 		<>
// 			<Modal show={showTableConfigModal} onHide={closeTableConfigModal}>
// 				<Modal.Header closeButton>
// 					<Modal.Title>Table Configuration</Modal.Title>
// 				</Modal.Header>
// 				<Modal.Body>
// 					<Form.Group>
// 						<Form.Label>Rows</Form.Label>
// 						<Form.Control
// 							type='number'
// 							value={tableConfig.rows}
// 							onChange={(e) =>
// 								setTableConfig({
// 									...tableConfig,
// 									rows: parseInt(e.target.value),
// 								})
// 							}
// 						/>
// 					</Form.Group>
// 					<Form.Group>
// 						<Form.Label>Columns</Form.Label>
// 						<Form.Control
// 							type='number'
// 							value={tableConfig.columns}
// 							onChange={(e) =>
// 								setTableConfig({
// 									...tableConfig,
// 									columns: parseInt(e.target.value),
// 								})
// 							}
// 						/>
// 					</Form.Group>
// 				</Modal.Body>
// 				<Modal.Footer>
// 					<Button variant='secondary' onClick={closeTableConfigModal}>
// 						Cancel
// 					</Button>
// 					<Button variant='primary' onClick={handleTableConfigSave}>
// 						Save
// 					</Button>
// 				</Modal.Footer>
// 			</Modal>

// 			<div className='form-builder'>
// 				<h2>Form Builder</h2>
// 				<div className='offset-md-10'>
// 					<Button onClick={handleSubmitForm}>Submit Form</Button>
// 				</div>
// 				<div className='mt-3 mb-3'>
// 					<FormLabel>Template Name:</FormLabel>
// 					<FormControl
// 						type='text'
// 						value={templateName}
// 						onChange={handleFormLabelChange}
// 					/>
// 				</div>
// 				<Row>
// 					<Col md={3} className='toolbox'>
// 						<h3>Toolbox</h3>
// 						<div
// 							className='toolbox-item'
// 							draggable
// 							onDragStart={(e) => handleDragStart(e, 'input')}>
// 							Input
// 						</div>
// 						<div
// 							className='toolbox-item'
// 							draggable
// 							onDragStart={(e) => handleDragStart(e, 'textarea')}>
// 							Textarea
// 						</div>
// 						<div
// 							className='toolbox-item'
// 							draggable
// 							onDragStart={(e) => handleDragStart(e, 'select')}>
// 							Select
// 						</div>
// 						<div
// 							className='toolbox-item'
// 							draggable
// 							onDragStart={(e) => handleDragStart(e, 'checkbox')}>
// 							Checkbox
// 						</div>
// 						<div
// 							className='toolbox-item'
// 							draggable
// 							onDragStart={(e) => handleDragStart(e, 'radio')}>
// 							Radio
// 						</div>
// 						<div
// 							className='toolbox-item'
// 							draggable
// 							onDragStart={(e) => handleDragStart(e, 'file')}>
// 							File
// 						</div>
// 						<div
// 							className='toolbox-item'
// 							draggable
// 							onDragStart={(e) => handleDragStart(e, 'header')}>
// 							Header
// 						</div>
// 						<div
// 							className='toolbox-item'
// 							draggable
// 							onDragStart={(e) => handleDragStart(e, 'paragraph')}>
// 							Paragraph
// 						</div>
// 						<div
// 							className='toolbox-item'
// 							draggable
// 							onDragStart={(e) => handleDragStart(e, 'multiselect')}>
// 							MultiSelect
// 						</div>
// 						<div
// 							className='toolbox-item'
// 							draggable
// 							onDragStart={(e) => handleDragStart(e, 'table')}>
// 							Table
// 						</div>
// 					</Col>
// 					<Col md={9}>
// 						<div
// 							className={`form-canvas ${
// 								draggingElement === 'paragraph' ? 'dragged-paragraph' : ''
// 							}`}
// 							onDragOver={(e) => e.preventDefault()}
// 							onDrop={handleDrop}>
// 							<Form>
// 								{formElements.map((element, index) => (
// 									<div
// 										key={index}
// 										className={`form-element ${
// 											selectedElementIndex === index ? 'selected' : ''
// 										}`}>
// 										<div className='form-element-header'>
// 											<div className='label-edit-delete'>
// 												{element.type !== 'header' &&
// 												element.type !== 'paragraph' ? (
// 													<FormLabel className='label'>
// 														{element.label}
// 													</FormLabel>
// 												) : element.type !== 'paragraph' ? (
// 													<h3>{element.value}</h3>
// 												) : (
// 													<p className='paragraph'>{element.value}</p>
// 												)}
// 												<div className='edit-delete-buttons'>
// 													<button
// 														type='button'
// 														className='edit-icon btn'
// 														onClick={() => openEditModal(index)}>
// 														<FontAwesomeIcon icon={faEdit} />
// 													</button>
// 													<button
// 														type='button'
// 														className='delete-icon btn'
// 														onClick={() => handleDeleteElement(index)}>
// 														<FontAwesomeIcon icon={faTrash} />
// 													</button>
// 												</div>
// 											</div>
// 										</div>
// 										<div className='form-element-input'>
// 											{element.type === 'select' ? (
// 												<Form.Control as='select'>
// 													{element.options.map((option, optionIndex) => (
// 														<option key={optionIndex}>{option}</option>
// 													))}
// 												</Form.Control>
// 											) : element.type === 'multiselect' ? (
// 												<MultiSelect
// 													options={element.options.map((option) => ({
// 														label: option,
// 														value: option,
// 													}))}
// 													value={selectedOptions}
// 													onChange={setSelectedOptions}
// 													labelledBy='Select'
// 												/>
// 											) : element.type === 'checkbox' ? (
// 												<div>
// 													{element.options.map((option, optionIndex) => (
// 														<Form.Check
// 															key={optionIndex}
// 															type='checkbox'
// 															label={option}
// 															required={element.required}
// 														/>
// 													))}
// 												</div>
// 											) : element.type === 'table' ? (
// 												<table className='table'>
// 													<tbody>
// 														{[...Array(element.row).keys()].map((rowIndex) => (
// 															<tr key={rowIndex}>
// 																{[...Array(element.cell).keys()].map(
// 																	(cellIndex) => (
// 																		<td
// 																			key={cellIndex}
// 																			onClick={() => enterEditMode(cellIndex)}
// 																			className={
// 																				editMode && cellIndex === selectedCell
// 																					? 'editing'
// 																					: ''
// 																			}>
// 																			{editMode &&
// 																				cellIndex === selectedCell && (
// 																					<FormControl
// 																						type='text'
// 																						value={editedContent}
// 																						onChange={(e) =>
// 																							setEditedContent(e.target.value)
// 																						}
// 																						onBlur={exitEditMode}
// 																						autoFocus
// 																					/>
// 																				)}
// 																		</td>
// 																	)
// 																)}
// 															</tr>
// 														))}
// 													</tbody>
// 												</table>
// 											) : // <div
// 											// 	dangerouslySetInnerHTML={{ __html: element.value }}
// 											// />
// 											element.type === 'radio' ? (
// 												<div>
// 													{element.options.map((option, optionIndex) => (
// 														<Form.Check
// 															key={optionIndex}
// 															type='radio'
// 															label={option}
// 															required={element.required}
// 														/>
// 													))}
// 												</div>
// 											) : element.type !== 'header' &&
// 											  element.type !== 'paragraph' ? (
// 												<FormControl
// 													type={element.type}
// 													placeholder='Enter Value'
// 													value={element.value}
// 													onChange={(e) => handleValueChange(e, index)}
// 												/>
// 											) : null}
// 										</div>
// 									</div>
// 								))}
// 							</Form>
// 						</div>
// 					</Col>
// 				</Row>

// 				<Modal show={showEditModal} onHide={handleEditModalClose}>
// 					<Modal.Header closeButton>
// 						<Modal.Title>Edit Form Element</Modal.Title>
// 					</Modal.Header>
// 					<Modal.Body>
// 						{formElements[selectedElementIndex]?.type === 'header' ? (
// 							<>
// 								<FormLabel>Edit Value:</FormLabel>
// 								<FormControl
// 									type='text'
// 									value={editHeaderValue}
// 									onChange={(e) => setEditHeaderValue(e.target.value)}
// 								/>
// 							</>
// 						) : formElements[selectedElementIndex]?.type === 'paragraph' ? (
// 							<>
// 								<FormLabel>Edit Value:</FormLabel>
// 								<FormControl
// 									type='text'
// 									value={editParagraphValue}
// 									onChange={(e) => setEditParagraphValue(e.target.value)}
// 								/>
// 							</>
// 						) : (
// 							<>
// 								<FormLabel>Edit Label:</FormLabel>
// 								<FormControl
// 									type='text'
// 									value={editLabel}
// 									onChange={(e) => setEditLabel(e.target.value)}
// 								/>
// 								{formElements[selectedElementIndex]?.type !== 'header' &&
// 								formElements[selectedElementIndex]?.type !== 'paragraph' &&
// 								(formElements[selectedElementIndex]?.type === 'checkbox' ||
// 									formElements[selectedElementIndex]?.type === 'radio' ||
// 									formElements[selectedElementIndex]?.type === 'select' ||
// 									formElements[selectedElementIndex]?.type ===
// 										'multiselect') ? (
// 									<>
// 										<FormLabel>Edit Options:</FormLabel>
// 										{editOptions.map((option, index) => (
// 											<div key={index} className='header-center'>
// 												<Row className='mt-1'>
// 													<Col md='10'>
// 														<FormControl
// 															type='text'
// 															value={option}
// 															onChange={(e) => handleOptionChange(e, index)}
// 														/>
// 													</Col>
// 													<Col md='1'>
// 														<Button
// 															variant='danger'
// 															onClick={() => handleDeleteOption(index)}>
// 															<FontAwesomeIcon icon={faTrash} />
// 														</Button>
// 													</Col>
// 												</Row>
// 												{index === editOptions.length - 1 && (
// 													<Button
// 														variant='primary'
// 														onClick={handleAddOption}
// 														className='mt-2 mb-2'>
// 														<FontAwesomeIcon icon={faPlus} /> Add
// 													</Button>
// 												)}
// 											</div>
// 										))}
// 										{showAddOptionInput && (
// 											<Row>
// 												<Col md='10'>
// 													<FormControl
// 														type='text'
// 														value={newOption}
// 														onChange={(e) => setNewOption(e.target.value)}
// 														placeholder='New Option'
// 													/>
// 												</Col>
// 												<Col md='1'>
// 													<Button
// 														variant='primary'
// 														onClick={() => {
// 															setEditOptions([...editOptions, newOption]);
// 															setNewOption('');
// 															setShowAddOptionInput(false);
// 														}}>
// 														<FontAwesomeIcon icon={faCheck} />
// 													</Button>
// 												</Col>
// 											</Row>
// 										)}
// 									</>
// 								) : null}
// 								{formElements[selectedElementIndex]?.type !== 'header' &&
// 									formElements[selectedElementIndex]?.type !== 'paragraph' && (
// 										<FormCheck
// 											type='checkbox'
// 											label='Required'
// 											checked={isRequired}
// 											onChange={() => setIsRequired(!isRequired)}
// 										/>
// 									)}
// 							</>
// 						)}
// 					</Modal.Body>
// 					<Modal.Footer>
// 						<Button variant='secondary' onClick={handleEditModalClose}>
// 							Close
// 						</Button>
// 						{formElements[selectedElementIndex]?.type === 'header' ? (
// 							<Button variant='primary' onClick={handleHeaderEditModalSave}>
// 								Save Changes
// 							</Button>
// 						) : formElements[selectedElementIndex]?.type === 'paragraph' ? (
// 							<Button variant='primary' onClick={handleParagraphEditModalSave}>
// 								Save Changes
// 							</Button>
// 						) : (
// 							<Button variant='primary' onClick={handleEditModalSave}>
// 								Save Changes
// 							</Button>
// 						)}
// 					</Modal.Footer>
// 				</Modal>
// 			</div>
// 		</>
// 	);
// };

// export default HistoryCreatorScreens;

/** @format */

import React, { useState, useEffect } from 'react';
import {
	Button,
	Form,
	FormControl,
	FormLabel,
	FormCheck,
	Row,
	Col,
	Modal,
	Container,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faEdit,
	faTrash,
	faPlus,
	faCheck,
} from '@fortawesome/free-solid-svg-icons';
import { MultiSelect } from 'react-multi-select-component';
import '../../../styles/FormBuilder.css'; // Import your CSS file for styling
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { createHistoryTemplateInDoctor } from '../../../redux/historyRedux/historyApiCalls';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Meta from '../../../Components/Meta';

const HistoryCreatorScreens = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const { clinicName } = useParams();
	const { doctorInfo } = useSelector((state) => state.doctorLogin);
	const [templateName, setTemplateName] = useState('');
	const [formElements, setFormElements] = useState([]);
	const [selectedElementIndex, setSelectedElementIndex] = useState(null);
	const [showEditModal, setShowEditModal] = useState(false);
	const [editLabel, setEditLabel] = useState('');
	const [editOptions, setEditOptions] = useState([]);
	const [isRequired, setIsRequired] = useState(false);
	const [editHeaderValue, setEditHeaderValue] = useState('');
	const [editParagraphValue, setEditParagraphValue] = useState('');
	const [draggingElement, setDraggingElement] = useState('');
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [editModalOptions, setEditModalOptions] = useState([]);
	const [newOption, setNewOption] = useState('');
	const [showAddOptionInput, setShowAddOptionInput] = useState(false);

	console.log(selectedOptions);

	const addFormElement = (elementType, label = 'Label Name') => {
		let defaultOptions = [];
		if (
			elementType === 'radio' ||
			elementType === 'checkbox' ||
			elementType === 'select' ||
			elementType === 'multiselect'
		) {
			defaultOptions = ['Option 1', 'Option 2', 'Option 3'];
		}
		setFormElements([
			// If formElements has elements, spread them into the new array, otherwise start with an empty array
			...(formElements?.length > 0 ? formElements : []),

			// Create the new form element object
			{
				type: elementType, // Set the type to the current element type
				label:
					elementType === 'header'
						? 'Header'
						: elementType === 'paragraph'
						? 'Paragraph'
						: label, // Set the label based on the element type
				value:
					elementType === 'header'
						? 'Header'
						: elementType === 'paragraph'
						? 'Paragraph'
						: '', // Set the value based on the element type
				options: elementType === 'header' ? [] : defaultOptions, // Set options, empty array for headers
				required: false, // Set required to false by default
			},
		]);
	};

	const handleDragStart = (event, elementType) => {
		event.dataTransfer.setData('elementType', elementType);
		setDraggingElement(elementType);
	};

	const handleValueChange = (event, index) => {
		const updatedElements = [...formElements];
		updatedElements[index].value = event.target.value;
		setFormElements(updatedElements);
	};

	const handleDrop = (event) => {
		event.preventDefault();
		const elementType = event.dataTransfer.getData('elementType');
		if (elementType === 'header') {
			addFormElement('header');
		} else if (elementType === 'paragraph') {
			addFormElement('paragraph');
			setDraggingElement(false);
		} else {
			addFormElement(elementType);
		}
		setDraggingElement('');
	};

	const handleItemClick = (elementType) => {
		addFormElement(elementType);
	};

	const handleFormLabelChange = (event) => {
		setTemplateName(event.target.value);
	};

	const handleLabelChange = (event, index) => {
		const updatedElements = [...formElements];
		if (updatedElements[index]) {
			updatedElements[index].label = event.target.value;
			setFormElements(updatedElements);
		}
	};

	const handleOptionChange = (event, index) => {
		const updatedOptions = [...editOptions];
		updatedOptions[index] = event.target.value;
		setEditOptions(updatedOptions);
	};

	const handleAddOption = () => {
		setShowAddOptionInput(true);
	};

	const handleDeleteOption = (index) => {
		const updatedOptions = [...editOptions];
		updatedOptions.splice(index, 1);
		setEditOptions(updatedOptions);
	};

	const handleDeleteElement = (index) => {
		const updatedElements = [...formElements];
		updatedElements.splice(index, 1);
		setFormElements(updatedElements);
		setSelectedElementIndex(null);
	};

	const handleEditModalClose = () => {
		setShowEditModal(false);
	};

	const handleEditModalSave = () => {
		if (selectedElementIndex !== null) {
			const updatedElements = [...formElements];
			if (updatedElements[selectedElementIndex]) {
				updatedElements[selectedElementIndex].label = editLabel;
				if (
					updatedElements[selectedElementIndex].type !== 'header' &&
					updatedElements[selectedElementIndex].type !== 'paragraph'
				) {
					updatedElements[selectedElementIndex].options = editOptions;
					updatedElements[selectedElementIndex].required = isRequired;
				} else if (updatedElements[selectedElementIndex].type === 'header') {
					updatedElements[selectedElementIndex].value = editHeaderValue;
				} else if (updatedElements[selectedElementIndex].type === 'paragraph') {
					updatedElements[selectedElementIndex].value = editParagraphValue;
				}
				setFormElements(updatedElements);
			}
			handleEditModalClose();
		}
	};

	const openEditModal = (index) => {
		setSelectedElementIndex(index);
		if (formElements[index]) {
			setEditLabel(formElements[index].label);
			setEditOptions(formElements[index].options);
			setIsRequired(formElements[index].required);
			setEditHeaderValue(formElements[index].value);
			setEditParagraphValue(formElements[index].value);
		}
		setShowEditModal(true);
	};

	const handleSubmitForm = () => {
		console.log({ templateName, formElements });
		// Submit form data here
		dispatch(
			createHistoryTemplateInDoctor(
				formElements,
				templateName,
				doctorInfo?.user?._id,
				clinicName
			)
		);
		setTemplateName('');
		setFormElements([]);
	};

	const addHeaderToForm = () => {
		addFormElement('header');
	};

	const addParagraphToForm = () => {
		addFormElement('paragraph');
	};

	const handleHeaderEditModalSave = () => {
		if (selectedElementIndex !== null) {
			const updatedElements = [...formElements];
			if (updatedElements[selectedElementIndex]) {
				updatedElements[selectedElementIndex].value = editHeaderValue;
				setFormElements(updatedElements);
			}
			handleEditModalClose();
		}
	};

	const handleParagraphEditModalSave = () => {
		if (selectedElementIndex !== null) {
			const updatedElements = [...formElements];
			if (updatedElements[selectedElementIndex]) {
				updatedElements[selectedElementIndex].value = editParagraphValue;
				setFormElements(updatedElements);
			}
			handleEditModalClose();
		}
	};

	const [clonedTemplate, setClonedTemplate] = useState([]);
	const [selectedSpeciality, setSelectedSpeciality] = useState(0);

	const handleSpecSelect = (eventKey) => {
		setSelectedSpeciality(eventKey);
		// dispatch(
		// 	GetPrescriptionTemplateNames(
		// 		doctorInfo?.data?.user?.specialityId[eventKey]
		// 	)
		// );
	};

	const handleClonedTemplate = (eventKey) => {
		setClonedTemplate(eventKey);
		// dispatch(
		// 	GetPrescriptionTemplate(
		// 		doctorInfo?.data?.user?.specialityId[selectedSpeciality],
		// 		eventKey?.value
		// 	)
		// );
	};

	// const { getPrescriptionTemplate, loading } = useSelector(
	// 	(state) => state.GetPrescriptionTemplate
	// );

	// useEffect(() => {
	// 	setFormElements(
	// 		getPrescriptionTemplate?.data?.data?.prescriptionTemplates[0]?.template
	// 	);
	// }, [loading]);

	// useEffect(() => {
	// 	handleSpecSelect(0);
	// }, []);

	const { getPrescriptionTemplateNames } = useSelector(
		(state) => state.GetPrescriptionTemplateNames
	);

	console.log(getPrescriptionTemplateNames);

	return (
		<div className='form-builder'>
			<Meta />
			<h2 className='header-center'>History Builder</h2>
			<div className='offset-md-10'>
				<Button onClick={handleSubmitForm}>Submit Form</Button>
			</div>
			<div className='mt-3 mb-3'>
				<FormLabel>Template Name:</FormLabel>
				<FormControl
					type='text'
					value={templateName}
					onChange={handleFormLabelChange}
				/>
			</div>
			<Row>
				<Col md={3} className='toolbox'>
					<h3>Toolbox</h3>
					<div
						className='toolbox-item'
						draggable
						onDragStart={(e) => handleDragStart(e, 'input')}>
						Input
					</div>
					<div
						className='toolbox-item'
						draggable
						onDragStart={(e) => handleDragStart(e, 'textarea')}>
						Textarea
					</div>
					<div
						className='toolbox-item'
						draggable
						onDragStart={(e) => handleDragStart(e, 'select')}>
						Select
					</div>
					<div
						className='toolbox-item'
						draggable
						onDragStart={(e) => handleDragStart(e, 'checkbox')}>
						Checkbox
					</div>
					<div
						className='toolbox-item'
						draggable
						onDragStart={(e) => handleDragStart(e, 'radio')}>
						Radio
					</div>
					<div
						className='toolbox-item'
						draggable
						onDragStart={(e) => handleDragStart(e, 'header')}>
						Header
					</div>
					<div
						className='toolbox-item'
						draggable
						onDragStart={(e) => handleDragStart(e, 'paragraph')}>
						Paragraph
					</div>
					<div
						className='toolbox-item'
						draggable
						onDragStart={(e) => handleDragStart(e, 'multiselect')}>
						MultiSelect
					</div>
				</Col>
				<Col md={9}>
					<div
						className={`form-canvas ${
							draggingElement === 'paragraph' ? 'dragged-paragraph' : ''
						}`}
						onDragOver={(e) => e.preventDefault()}
						onDrop={handleDrop}>
						<Form>
							{formElements?.map((element, index) => (
								<div
									key={index}
									className={`form-element ${
										selectedElementIndex === index ? 'selected' : ''
									}`}>
									<div className='form-element-header'>
										<div className='label-edit-delete'>
											{element.type !== 'header' &&
											element.type !== 'paragraph' ? (
												<FormLabel className='label'>{element.label}</FormLabel>
											) : element.type !== 'paragraph' ? (
												<h3>{element.value}</h3>
											) : (
												<p className='paragraph'>{element.value}</p>
											)}
											<div className='edit-delete-buttons'>
												<button
													type='button'
													className='edit-icon btn'
													onClick={() => openEditModal(index)}>
													<FontAwesomeIcon icon={faEdit} />
												</button>
												<button
													type='button'
													className='delete-icon btn'
													onClick={() => handleDeleteElement(index)}>
													<FontAwesomeIcon icon={faTrash} />
												</button>
											</div>
										</div>
									</div>
									<div className='form-element-input'>
										{element.type === 'select' ? (
											<Form.Control as='select'>
												{element.options.map((option, optionIndex) => (
													<option key={optionIndex}>{option}</option>
												))}
											</Form.Control>
										) : element.type === 'multiselect' ? (
											<MultiSelect
												options={element.options.map((option) => ({
													label: option,
													value: option,
												}))}
												value={selectedOptions}
												onChange={setSelectedOptions}
												labelledBy='Select'
											/>
										) : element.type === 'checkbox' ? (
											<div>
												{element.options.map((option, optionIndex) => (
													<Form.Check
														key={optionIndex}
														type='checkbox'
														label={option}
														required={element.required}
													/>
												))}
											</div>
										) : element.type === 'radio' ? (
											<div>
												{element.options.map((option, optionIndex) => (
													<Form.Check
														key={optionIndex}
														type='radio'
														label={option}
														required={element.required}
													/>
												))}
											</div>
										) : element.type !== 'header' &&
										  element.type !== 'paragraph' ? (
											element.type === 'input' ? (
												<FormControl
													type={element.type}
													placeholder='Enter Value'
													value={element.value}
													onChange={(e) => handleValueChange(e, index)}
												/>
											) : (
												<textarea
													rows={2}
													cols={100}
													className='form-control'
													onChange={(e) => handleValueChange(e, index)}
													value={element.value}></textarea>
											)
										) : null}
									</div>
								</div>
							))}
						</Form>
					</div>
				</Col>
			</Row>

			<Modal show={showEditModal} onHide={handleEditModalClose}>
				<Modal.Header closeButton>
					<Modal.Title>Edit Form Element</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{formElements?.[selectedElementIndex]?.type === 'header' ? (
						<>
							<FormLabel>Edit Value:</FormLabel>
							<FormControl
								type='text'
								value={editHeaderValue}
								onChange={(e) => setEditHeaderValue(e.target.value)}
							/>
						</>
					) : formElements?.[selectedElementIndex]?.type === 'paragraph' ? (
						<>
							<FormLabel>Edit Value:</FormLabel>
							<FormControl
								type='text'
								value={editParagraphValue}
								onChange={(e) => setEditParagraphValue(e.target.value)}
							/>
						</>
					) : (
						<>
							<FormLabel>Edit Label:</FormLabel>
							<FormControl
								type='text'
								value={editLabel}
								onChange={(e) => setEditLabel(e.target.value)}
							/>
							{formElements?.[selectedElementIndex]?.type !== 'header' &&
							formElements?.[selectedElementIndex]?.type !== 'paragraph' &&
							(formElements?.[selectedElementIndex]?.type === 'checkbox' ||
								formElements?.[selectedElementIndex]?.type === 'radio' ||
								formElements?.[selectedElementIndex]?.type === 'select' ||
								formElements?.[selectedElementIndex]?.type ===
									'multiselect') ? (
								<>
									<FormLabel>Edit Options:</FormLabel>
									{editOptions.map((option, index) => (
										<div key={index} className='header-center'>
											<Row className='mt-1'>
												<Col md='10'>
													<FormControl
														type='text'
														value={option}
														onChange={(e) => handleOptionChange(e, index)}
													/>
												</Col>
												<Col md='1'>
													<Button
														variant='danger'
														onClick={() => handleDeleteOption(index)}>
														<FontAwesomeIcon icon={faTrash} />
													</Button>
												</Col>
											</Row>
											{index === editOptions.length - 1 && (
												<Button
													variant='primary'
													onClick={handleAddOption}
													className='mt-2 mb-2'>
													<FontAwesomeIcon icon={faPlus} /> Add
												</Button>
											)}
										</div>
									))}
									{showAddOptionInput && (
										<Row>
											<Col md='10'>
												<FormControl
													type='text'
													value={newOption}
													onChange={(e) => setNewOption(e.target.value)}
													placeholder='New Option'
												/>
											</Col>
											<Col md='1'>
												<Button
													variant='primary'
													onClick={() => {
														setEditOptions([...editOptions, newOption]);
														setNewOption('');
														setShowAddOptionInput(false);
													}}>
													<FontAwesomeIcon icon={faCheck} />
												</Button>
											</Col>
										</Row>
									)}
								</>
							) : null}
							{formElements?.[selectedElementIndex]?.type !== 'header' &&
								formElements?.[selectedElementIndex]?.type !== 'paragraph' && (
									<FormCheck
										type='checkbox'
										label='Required'
										checked={isRequired}
										onChange={() => setIsRequired(!isRequired)}
									/>
								)}
						</>
					)}
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={handleEditModalClose}>
						Close
					</Button>
					{formElements?.[selectedElementIndex]?.type === 'header' ? (
						<Button variant='primary' onClick={handleHeaderEditModalSave}>
							Save Changes
						</Button>
					) : formElements?.[selectedElementIndex]?.type === 'paragraph' ? (
						<Button variant='primary' onClick={handleParagraphEditModalSave}>
							Save Changes
						</Button>
					) : (
						<Button variant='primary' onClick={handleEditModalSave}>
							Save Changes
						</Button>
					)}
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default HistoryCreatorScreens;

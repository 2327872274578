/** @format */

import { configureStore, combineReducers } from '@reduxjs/toolkit';

import userRegisterRedux from './userRegister';

import userUpdateReducer from './userUpdate';

import createDoctorReducer from './adminRedux/Doctor_Management/createDoctor';

import TimeSlotGetReducer from './getTimeSlots';
import createBookingReducer from './createBooking';
import getPatientBookingReducer from './getPatientBooking';
import doctorBookingGetReducer from './adminRedux/Booking_Management/getBooking';

import editUserReducer from './userEdit';
import deleteBookingReducer from './deleteBooking';

import changeOnHoldReducer from './adminRedux/Booking_Management/changeOnHold';
import changeSlotReducer from './adminRedux/Booking_Management/changeSlot';
import adminCreateBookingReducer from './adminRedux/Booking_Management/adminCreateBooking';
import getUserDataByCategoryReducer from './adminRedux/User_Management/getUserDataByCategory';
import DoctorGetMyAppointmentsReducer from './doctorRedux/Appointment_Management/getMyAppointments';
import DoctorGetMyTimeSlotsReducer from './doctorRedux/Appointment_Management/getMyTimeslots';
import getUserDataInSearchPatientReducer from './adminRedux/User_Management/getUserDataInSearchPatients';
import doctorVerifyReducer from './doctorRedux/MyProfile_Management/VerifyCode';
import createCategoryReducer from './createCategory';

import createPrescriptionReducer from './prescriptionRedux/createPrescription';
import editPrescriptionReducer from './prescriptionRedux/editPrescription';
import getCategoriesVitalsReducer from './prescriptionRedux/getCategoriesVitals';
import getVitalsReducer from './adminRedux/Doctor_Management/getVitalsInDoctor';
import viewPatientHistoryReducer from './prescriptionRedux/viewPatientHistory';
import getUserDataByIdReducer from './prescriptionRedux/getUserDataById';
import getPrescriptionReducer from './prescriptionRedux/getPrescription';
import prescriptionCreatorReducer from './doctorRedux/Prescription_Management/prescriptionCreator';
import GetPrescriptionTemplateNamesReducer from './doctorRedux/Prescription_Management/GetPrescriptionTemplateNames';
import GetPrescriptionTemplateReducer from './doctorRedux/Prescription_Management/GetPrescriptionTemplate';

import getTemplateNamesReducer from './doctorRedux/Template_Management/getTemplateNames';
import getTemplateReducer from './doctorRedux/Template_Management/getTemplate';
import createTemplateReducer from './doctorRedux/Template_Management/createTemplate';
import updateTemplateReducer from './doctorRedux/Template_Management/updateTemplate';
import deleteTemplateReducer from './doctorRedux/Template_Management/deleteTemplate';
import updateDoctorPasswordReducer from './doctorRedux/MyProfile_Management/updatePassword';
import getLeavesReducer from './doctorRedux/Leave_Management/getLeaves';
import deleteLeavesReducer from './doctorRedux/Leave_Management/deleteLeave';
import createLeavesReducer from './doctorRedux/Leave_Management/createLeave';
import getPrescriptionByCategoryReducer from './userRedux/getPrescriptionByCategory';
import getPrescriptionIdsofCategoryReducer from './userRedux/getPrescriptionIdsofCategory';
import getPrescriptionByDateReducer from './userRedux/getPrescriptionByDate';
import onSpotPrescriptionReducer from './prescriptionRedux/onSpotPrescription';
import StartPrescriptionToStartHistoryReducer from './UINavigationRedux/StartPrescriptionToStartHistory';
import resendOtpDoctorReducer from './doctorRedux/MyProfile_Management/resendOtp';

import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import getLeavesForAdminReducer from './doctorRedux/Leave_Management/getLeaves';
import getRegisteredPatientsReducer from './userRedux/getRegisteredPatients';
import doctorLoginReducer from './doctorRedux/MyProfile_Management/doctorLogin';
import getDoctorVisitedPatientsforParticularDateReducer from './adminRedux/Doctor_Management/getDoctorVisitedPatientsforParticularDate';
import allMessagesReducer from './allMessages';

//Reception Redux

import receptionLoginReducer from './receptionRedux/MyProfile_Management/receptionLogin';
import receptionforgotPasswordReducer from './receptionRedux/MyProfile_Management/forgotPassword';
import receptionresendOtpReducer from './receptionRedux/MyProfile_Management/resendOtp';
import receptionresetPasswordReducer from './receptionRedux/MyProfile_Management/resetPassword';
import receptionupdatePasswordReducer from './receptionRedux/MyProfile_Management/updatePassword';
import receptionVerifyCodeReducer from './receptionRedux/MyProfile_Management/VerifyCode';
import deleteReceptionReducer from './adminRedux/Reception_Management/deleteReception';
//block slots
import blockSlotsReducer from './doctorRedux/Slot_Management/blockSlots';
import unBlockSlotsReducer from './doctorRedux/Slot_Management/unBlockSlots';

import getAllReceptionsReducer from './adminRedux/Reception_Management/getAllReceptions';
import scheduleAppointmentDateByDoctorReducer from './prescriptionRedux/scheduleAppointmentDateByDoctor';
import getScheduleAppointmentsForNotificationToDoctorReceptionReducer from './prescriptionRedux/getScheduleAppointmentsForNotificationToDoctorReception';
import getuserByIdForPrescriptionReducer from './prescriptionRedux/getuserByIdForPrescription';
import rescheduleAppointmentForPatientinBlockedSlotReducer from './receptionRedux/Appointment_Management/rescheduleAppointmentForPatientinBlockedSlot';
import getPatientsInBlockedSlotReducer from './receptionRedux/Appointment_Management/getPatientsInBlockedSlot';
import createReceptionist from './adminRedux/Reception_Management/addReceptionist';

//doctor
import editDoctorReducer from './doctorRedux/MyProfile_Management/editDoctor';
import paymentStatusReducer from './paymentStatus';
import paymentStatusvisitedPatientsReducer from './updatePaymentStatusinVisitedPatient';
import getRevenueForYearReducer from './doctorRedux/MyProfile_Management/getRevenueForYear';
import getRevenueForParticularYearReducer from './doctorRedux/MyProfile_Management/getRevenueForParticularYear';
import editHistoryByIdxReducer from './historyRedux/History_Management/editHistoryByIdx';
import getHistoryByIdxReducer from './historyRedux/History_Management/getHistoryByIdx';
import editClinicDetailsReducer from './doctorRedux/MyClinic/editClinicDetails';

//history reducers
import createHistoryTemplateInDoctorReducer from './historyRedux/History_Management/createHistoryTemplateInDoctor';
import getHistoryTemplateInDoctorReducer from './historyRedux/History_Management/getHistoryTemplateInDoctor';

import createInventoryCategoryReducer from './inventoryRedux/CategoryRedux/createInventoryCategory';
import getInventoryCategoryReducer from './inventoryRedux/CategoryRedux/getInventoryCategory';
import createInventoryItemReducer from './inventoryRedux/InventoryItem/createInventoryItem';
import getInventoryItemsForBillingReducer from './inventoryRedux/InventoryItem/getInventoryItemsForBilling';
import editInventoryItemReducer from './inventoryRedux/InventoryItem/editInventoryItem';
import createSupplierReducer from './inventoryRedux/SupplierRedux/createSupplier';
import getSuppliersReducer from './inventoryRedux/SupplierRedux/getSuppliers';
import getPurchaseByIdReducer from './inventoryRedux/SupplierRedux/getPurchaseById';
import getAllPurchasesReducer from './inventoryRedux/SupplierRedux/getAllPurchases';

import getBillReducer from './inventoryRedux/InventoryItem/getBill';
import billReducer from './doctorRedux/Bill_Management/bill';

const persistConfig = {
	key: 'root',
	version: 1,
	storage,
};

const rootReducer = combineReducers({
	//reception Functions

	receptionLogin: receptionLoginReducer,
	receptionforgotPassword: receptionforgotPasswordReducer,
	receptionresendOtp: receptionresendOtpReducer,
	receptionresetPassword: receptionresetPasswordReducer,
	receptionupdatePassword: receptionupdatePasswordReducer,
	receptionVerifyCode: receptionVerifyCodeReducer,
	createReception: createReceptionist,
	deleteReception: deleteReceptionReducer,
	getAllReception: getAllReceptionsReducer,
	rescheduleAppointmentForPatientinBlockedSlot:
		rescheduleAppointmentForPatientinBlockedSlotReducer,
	getPatientsInBlockedSlots: getPatientsInBlockedSlotReducer,
	//doctor functions
	resendOtpDoctor: resendOtpDoctorReducer,
	updateDoctorPassword: updateDoctorPasswordReducer,

	getRevenueForYear: getRevenueForYearReducer,
	getRevenueForParticularYear: getRevenueForParticularYearReducer,
	editHistoryByIdx: editHistoryByIdxReducer,
	getHistoryByIdx: getHistoryByIdxReducer,
	//leaves management
	getLeaves: getLeavesReducer,
	deleteLeaves: deleteLeavesReducer,
	createLeaves: createLeavesReducer,
	blockSlots: blockSlotsReducer,
	unBlockSlots: unBlockSlotsReducer,
	//Template management
	getTemplateNames: getTemplateNamesReducer,
	getTemplate: getTemplateReducer,
	createTemplate: createTemplateReducer,
	updateTemplate: updateTemplateReducer,
	deleteTemplate: deleteTemplateReducer,
	//prescritpion management
	createCategory: createCategoryReducer,
	getPrescriptionByCategory: getPrescriptionByCategoryReducer,
	getPrescriptionByDate: getPrescriptionByDateReducer,
	onSpotPrescription: onSpotPrescriptionReducer,
	StartPrescriptionToStartHistory: StartPrescriptionToStartHistoryReducer,
	getPrescriptionIdsofCategory: getPrescriptionIdsofCategoryReducer,
	createPrescription: createPrescriptionReducer,
	editPrescription: editPrescriptionReducer,
	getCategoriesVitals: getCategoriesVitalsReducer,
	getVitals: getVitalsReducer,
	scheduleAppointmentDateByDoctor: scheduleAppointmentDateByDoctorReducer,
	getScheduleAppointmentsForNotificationToDoctorReception:
		getScheduleAppointmentsForNotificationToDoctorReceptionReducer,
	getuserByIdForPrescription: getuserByIdForPrescriptionReducer,
	prescriptionCreator: prescriptionCreatorReducer,
	GetPrescriptionTemplateNames: GetPrescriptionTemplateNamesReducer,
	GetPrescriptionTemplate: GetPrescriptionTemplateReducer,
	//doctor
	doctorLogin: doctorLoginReducer,
	doctorVerifyCode: doctorVerifyReducer,
	getUserdataByCategory: getUserDataByCategoryReducer,
	DoctorGetMyAppointments: DoctorGetMyAppointmentsReducer,
	DoctorGetMyTimeSlots: DoctorGetMyTimeSlotsReducer,
	getDoctorBooking: doctorBookingGetReducer,
	editDoctor: editDoctorReducer,
	paymentStatus: paymentStatusReducer,
	paymentStatusvisitedPatients: paymentStatusvisitedPatientsReducer,
	//user Functions
	userRegister: userRegisterRedux,

	userUpdate: userUpdateReducer,

	getRegisteredPatients: getRegisteredPatientsReducer,

	deleteBooking: deleteBookingReducer,

	getPatientBooking: getPatientBookingReducer,
	//admin fucntions

	editUser: editUserReducer,
	createDoctor: createDoctorReducer,
	getLeavesForAdmin: getLeavesForAdminReducer,

	getUserDataInSearchPatient: getUserDataInSearchPatientReducer,
	changeOnHold: changeOnHoldReducer,
	changeSlot: changeSlotReducer,
	adminCreateBooking: adminCreateBookingReducer,

	getDoctorVisitedPatientsforParticularDate:
		getDoctorVisitedPatientsforParticularDateReducer,

	//common functions
	getUserDataById: getUserDataByIdReducer,
	viewPatientHistory: viewPatientHistoryReducer,
	getPrescription: getPrescriptionReducer,

	getTimeSlots: TimeSlotGetReducer,
	createBooking: createBookingReducer,

	allMessages: allMessagesReducer,

	editClinicDetails: editClinicDetailsReducer,
	//history fucntions
	createHistoryTemplateInDoctor: createHistoryTemplateInDoctorReducer,
	getHistoryTemplateInDoctor: getHistoryTemplateInDoctorReducer,

	getInventoryCategory: getInventoryCategoryReducer,
	createInventoryCategory: createInventoryCategoryReducer,
	createInventoryItem: createInventoryItemReducer,
	getInventoryItemsForBilling: getInventoryItemsForBillingReducer,
	editInventoryItem: editInventoryItemReducer,
	getSuppliers: getSuppliersReducer,
	createSupplier: createSupplierReducer,
	getPurchaseById: getPurchaseByIdReducer,
	getAllPurchases: getAllPurchasesReducer,

	getBill: getBillReducer,
	billData: billReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const doctorInfoFromStorage = localStorage.getItem('doctorInfo')
	? JSON.parse(localStorage.getItem('doctorInfo'))
	: null;

const receptionInfoFromStorage = localStorage.getItem('receptionInfo')
	? JSON.parse(localStorage.getItem('receptionInfo'))
	: null;

const initialState = {
	doctorLogin: { doctorInfo: doctorInfoFromStorage },
	receptionLogin: { receptionInfo: receptionInfoFromStorage },
};
export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}),
	initialState: initialState,
});

export let persistor = persistStore(store);

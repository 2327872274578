/** @format */

import { React, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Nav } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
const SideBar = () => {
	const location = useLocation();
	const { pathname } = location;

	const currentURL = pathname;
	const pattern = /\/clinic\/([^/]+)/;
	const match = currentURL.match(pattern);
	var clinicName;
	if (match) {
		clinicName = match[1];
		console.log(clinicName); // Output: Clinic1
	} else {
		console.log('Clinic name not found in the URL');
	}
	const { doctorInfo } = useSelector((state) => state.doctorLogin);

	const { receptionInfo } = useSelector((state) => state.receptionLogin);

	const [allDau, setAllDau] = useState([]);

	// Use an object to store the collapse state for each item
	const [openItems, setOpenItems] = useState({});

	let carray = [];

	const handleThisLogin = (carray) => {
		setAllDau(carray);
	};

	useEffect(() => {
		if (doctorInfo?.user?.name) {
			carray = [
				{
					name: 'My Appointments',
					link: `/clinic/kavithadentalcare/doctor/patientstobevisited`,
					icon: 'far fa-calendar-check', // Updated icon
					dropdownItems: [],
				},
				{
					name: 'Book Appointment',
					link: `/clinic/kavithadentalcare/doctor/booking`,
					icon: 'far fa-calendar-plus', // Updated icon
					dropdownItems: [],
				},
				// {
				// 	name: 'Sell Inventory Items',
				// 	link: `/clinic/kavithadentalcare/doctor/patientpurchaseinventory`,
				// 	dropdownItems: [],
				// 	icon: 'fa fa-tags',
				// },
				{
					name: 'Search Patients',
					link: `/clinic/kavithadentalcare/doctor/searchPatients`,
					icon: 'fas fa-search', // Updated icon
					dropdownItems: [],
				},
				{
					name: 'Patient Management',
					// link: "/doctor/register",
					// icon: "fas fa-user-plus",
					// Updated icon
					icon: 'fas fa-hospital-user',
					dropdownItems: [
						{
							name: 'Add User',
							link: `/clinic/kavithadentalcare/doctor/Adduser`,
							icon: 'fas fa-user-plus',
						},
						{
							name: 'Edit Patient',
							link: `/clinic/kavithadentalcare/doctor/editPatient`,
							icon: 'fas fa-edit',
						},
						{
							name: 'Register Patient',
							link: `/clinic/kavithadentalcare/register`,
							icon: 'fas fa-user-plus',
						},
					],
				},
				{
					name: 'Slots Management',
					// link: "/doctor/blockslots",
					icon: 'far fa-clock', // Updated icon
					dropdownItems: [
						{
							name: 'Block Slots',
							link: `/clinic/kavithadentalcare/doctor/blockslots`,
							icon: 'fas fa-lock', // Updated icon
						},
						{
							name: 'UnBlock Slots',
							link: `/clinic/kavithadentalcare/doctor/unblockslots`,
							icon: 'fas fa-unlock', // Updated icon
						},
						{
							name: 'Edit My Slots',
							link: `/clinic/kavithadentalcare/doctor/EditMySlots`,
							icon: 'far fa-edit', // Updated icon
						},
						{
							name: 'Edit Booking Period',
							link: `/clinic/kavithadentalcare/doctor/EditBookingPeriod`,
							icon: 'far fa-calendar-alt', // Updated icon
						},
					],
				},
				{
					name: 'Leaves Management',
					// link: "/doctor/createleaves",
					icon: 'far fa-calendar-times', // Updated icon for applying leave
					dropdownItems: [
						{
							name: 'Apply Leaves',
							link: `/clinic/kavithadentalcare/doctor/createleaves`,
							icon: 'fas fa-calendar-plus', // Updated icon for applying leave
						},
						{
							name: 'Cancel Leaves',
							link: `/clinic/kavithadentalcare/doctor/deleteleaves`,
							icon: 'far fa-trash-alt', // Updated icon for deleting leave
						},
					],
				},
				{
					name: 'Precription Templates',
					dropdownItems: [
						{
							name: 'Add Template',
							link: `/clinic/kavithadentalcare/doctor/prescritpioncreator`,
							icon: 'fa fa-file-alt',
						},
						{
							name: 'Edit Template',
							link: `/clinic/kavithadentalcare/doctor/prescriptioneditor`,
							icon: 'fas fa-edit',
						},
					],
					icon: 'fas fa-prescription',
				},
				{
					name: 'History Templates',
					dropdownItems: [
						{
							name: 'Add Template',
							link: `/clinic/kavithadentalcare/doctor/historycreator`,
							icon: 'fa fa-file-alt',
						},
						{
							name: 'Edit Template',
							link: `/clinic/kavithadentalcare/doctor/historyeditor`,
							icon: 'fas fa-edit',
						},
					],
					icon: 'fa fa-history',
				},

				{
					name: 'Medicine Templates',
					// link: "/doctor/templates",
					icon: 'fas fa-pills', // Updated icon
					dropdownItems: [
						{
							name: 'Add Medicine Template',
							link: `/clinic/kavithadentalcare/doctor/addtemplates`,
							icon: 'far fa-file-alt',
						},
						{
							name: 'Edit Medicine Template',
							link: `/clinic/kavithadentalcare/doctor/edittemplates`,
							icon: 'fas fa-edit',
						},
						{
							name: 'Delete Medicine Template',
							link: `/clinic/kavithadentalcare/doctor/deletetemplates`,
							icon: 'fas fa-trash',
						},
					],
				},

				{
					name: 'Reception Management',
					// link: "/doctor/receptionManagement",
					icon: 'fas fa-users-cog', // Updated icon
					dropdownItems: [
						{
							name: 'Add Receptionist',
							link: `/clinic/kavithadentalcare/doctor/addReception`,
							icon: 'fas fa-user-plus',
						},
						{
							name: 'Delete Receptionist',
							link: `/clinic/kavithadentalcare/doctor/deleteReception`,
							icon: 'fas fa-trash',
						},
					],
				},
				{
					name: 'Inventory Management',
					dropdownItems: [
						{
							name: 'Add Category',
							link: `/clinic/kavithadentalcare/doctor/inventorycategory`,
							icon: 'fa fa-plus',
						},
						{
							name: 'Add Inventory',
							link: `/clinic/kavithadentalcare/doctor/addinventory`,
							icon: 'fa fa-plus',
						},
						{
							name: 'Purchase Inventory',
							link: `/clinic/kavithadentalcare/doctor/purchaseinventory`,
							icon: 'fa fa-cart-plus',
						},
					],
					icon: 'fas fa-warehouse',
				},
				{
					name: 'Analytics Hub',
					// link: "/doctor/register",
					icon: 'fas fa-chart-line', // Updated icon
					dropdownItems: [
						{
							name: 'Revenue Analytics',
							link: `/clinic/kavithadentalcare/doctor/hospitalStatistic`,
							icon: 'fas fa-rupee-sign',
						},
						{
							name: 'Patient Analytics',
							link: `/clinic/kavithadentalcare/doctor/patientStatistic`,
							icon: 'fas fa-users',
						},
					],
				},
				{
					name: 'My Clinic',
					link: `/clinic/kavithadentalcare/doctor/myclinic`,
					icon: 'fa fa-hospital-o', // Updated icon
					dropdownItems: [],
				},
			];
			handleThisLogin(carray);
		} else if (receptionInfo?.user?.name) {
			carray = [
				{
					name: 'Appointments',
					link: `/clinic/kavithadentalcare/reception/patientstobevisited`,
					icon: 'fa fa-clipboard',
					dropdownItems: [],
				},
				{
					name: 'Book Appointments',
					link: `/clinic/kavithadentalcare/reception/booking`,
					icon: 'fa fa-calendar-plus',
					dropdownItems: [],
				},
				{
					name: 'Search Patients',
					link: `/clinic/kavithadentalcare/reception/searchPatient`,
					icon: 'fa fa-search',
					dropdownItems: [],
				},
				{
					name: 'Register Patient',
					link: `/clinic/kavithadentalcare/register`,
					icon: 'fa fa-user-plus',
					dropdownItems: [],
				},
				{
					name: 'Scheduled Appointments',
					link: `/clinic/kavithadentalcare/reception/scheduledAppointments`,
					icon: 'fa fa-clock',
					dropdownItems: [],
				},

				{
					name: 'Blocked Slots',
					link: `/clinic/kavithadentalcare/reception/BlockedSlots`,
					icon: 'fa fa-ban',
					dropdownItems: [],
				},
				{
					name: 'Update Password',
					link: `/clinic/kavithadentalcare/reception/updatepassword`,
					icon: 'fa fa-key',
					dropdownItems: [],
				},
			];
			handleThisLogin(carray);
		}
	}, [doctorInfo?.user?.name, receptionInfo?.user?.name]);

	const handleToggle = (index) => {
		setOpenItems((prevOpenItems) => ({
			...prevOpenItems,
			[index]: !prevOpenItems[index],
		}));
	};
	return (
		<>
			<div className='sidebar'>
				<Nav defaultActiveKey='/home' className='flex-column'>
					{allDau?.map((items, index) => (
						<>
							{items?.dropdownItems?.length !== 0 ? (
								<>
									<Nav.Item>
										<Nav.Link
											className='sidebar-links'
											onClick={() => handleToggle(index)}
											aria-controls={`example-collapse-text-${index}`}
											aria-expanded={openItems[index]}>
											<i className={`${items.icon} mr-1`}></i> {items.name}{' '}
											<i class='fa fa-caret-down ml-1'></i>
										</Nav.Link>
									</Nav.Item>
									<Collapse
										in={openItems[index]}
										id={`example-collapse-text-${index}`}>
										<div>
											{items?.dropdownItems?.map(
												(dropdownItem, dropdownIndex) => (
													<Nav.Item key={dropdownIndex}>
														{dropdownItem &&
															dropdownItem.link &&
															dropdownItem.icon &&
															dropdownItem.name && (
																<Nav.Link
																	className='sidebar-sublinks header-left custom-nav-link'
																	href={dropdownItem?.link}>
																	<i
																		className={`${dropdownItem?.icon} mr-1`}></i>{' '}
																	{dropdownItem?.name}
																</Nav.Link>
															)}
													</Nav.Item>
												)
											)}
										</div>
									</Collapse>
								</>
							) : (
								<Nav.Item>
									<Nav.Link className='sidebar-links' href={items.link}>
										<i className={`${items.icon} mr-1`}></i> {items.name}
									</Nav.Link>
								</Nav.Item>
							)}
						</>
					))}
				</Nav>
			</div>
		</>
	);
};

export default SideBar;

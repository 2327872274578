/** @format */

import { createSlice } from '@reduxjs/toolkit';

const newPurchaseFromSupplier = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		newPurchaseFromSupplierStart: (state) => {
			state.loading = true;
		},
		newPurchaseFromSupplierSuccess: (state, action) => {
			state.loading = false;
			state.newPurchaseFromSupplier = action.payload;
			state.error = false;
			state.success = true;
		},
		newPurchaseFromSupplierFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		newPurchaseFromSupplierReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	newPurchaseFromSupplierStart,
	newPurchaseFromSupplierSuccess,
	newPurchaseFromSupplierFailure,
	newPurchaseFromSupplierReset,
} = newPurchaseFromSupplier.actions;
export default newPurchaseFromSupplier.reducer;

/** @format */

import Message from '../../Components/Message';
import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Components/Loader';
import Meta from '../../Components/Meta';
import { useNavigate, useLocation } from 'react-router-dom';

import { allMessagesStart } from '../../redux/allMessages';

import { scheduleAppointmentDateByDoctorReset } from '../../redux/prescriptionRedux/scheduleAppointmentDateByDoctor';
import { scheduleAppointmentDateByDoctorApiCall } from '../../redux/prescriptionRedux/prescriptionApiCalls';
import { useParams } from 'react-router-dom';

const NextAppointmentScreen = () => {
	const { clinicName } = useParams();
	const dispatch = useDispatch();
	const history = useNavigate();
	const location = useLocation();
	// success and error
	//const { allTimeSlots } = useSelector((state) => state.getTimeSlots);

	const scheduleAppointmentDateByDoctor = useSelector(
		(state) => state.scheduleAppointmentDateByDoctor
	);
	const scheduleAppointmentDateByDoctorerror =
		scheduleAppointmentDateByDoctor.error;
	const scheduleAppointmentDateByDoctorloading =
		scheduleAppointmentDateByDoctor.loading;
	const scheduleAppointmentDateByDoctorsuccess =
		scheduleAppointmentDateByDoctor.success;

	const { doctorInfo } = useSelector((state) => state.doctorLogin);
	const [nextDate, setNextDate] = useState('');
	const [nextMess, setNextMess] = useState('');
	console.log(nextDate + 'line36');

	function isDateGreaterThanToday(dateToCheck) {
		// Get today's date
		const today = new Date();

		// Get tomorrow's date
		const tomorrow = new Date();
		tomorrow.setDate(today.getDate());
		// Convert the input date string to a Date object
		const inputDate = new Date(dateToCheck);

		// Compare the input date with today's date
		return inputDate <= tomorrow;
	}

	const submitNextHandler = () => {
		// console.log(doctorInfo?.user?._id, doctorInfo?.user?.name);
		console.log(nextDate);
		if (new Date(nextDate) < new Date()) {
			alert("Selected date must be greater than today's date.");
		} else if (nextMess == '') {
			alert('Enter the message');
		} else {
			const isGreater = isDateGreaterThanToday(nextDate);
			if (isGreater) {
				alert('Date cannot be lesser than today');
			} else {
				dispatch(
					scheduleAppointmentDateByDoctorApiCall(
						location.state.id,
						location.state.name,
						parseInt(nextDate.replace(/-/g, '')),
						doctorInfo?.user?._id,
						doctorInfo?.user?.name,
						nextMess,
						clinicName
					)
				);
				setNextDate('');
				setNextMess('');
				if (!location?.state?.hasPaid) {
					alert(
						'Payment status not updated for the current patient. Please Navigate to visited patients page to update.'
					);
					// history('/doctor/patientsvisited');
				}
				history(`/clinic/kavithadentalcare/doctor/patientstobevisited`);
			}
		}
	};

	// ALerts////////////////////////////////////////////////////////
	const [timerRunning, setTimerRunning] = useState(false);
	useEffect(() => {
		dispatch(allMessagesStart());
	}, []);

	const [showPopup1, setShowPopup1] = React.useState(false);

	const toggleShowInfoPopup1 = (e) => {
		setShowPopup1(!showPopup1);
		e.preventDefault();
		console.log('error');
		dispatch(scheduleAppointmentDateByDoctorReset());
	};

	useEffect(() => {
		if (scheduleAppointmentDateByDoctorsuccess && !timerRunning) {
			setTimerRunning(true);

			const timer = setTimeout(() => {
				// Clear the alert and stop the timer after 3 seconds
				dispatch(scheduleAppointmentDateByDoctorReset());

				setTimerRunning(false);
			}, 3000);

			// Clean up the timer if the component unmounts or the alert changes
		}
	}, [scheduleAppointmentDateByDoctorsuccess, dispatch]);

	const handleClearData1 = () => {
		setNextMess('');
		setNextDate('');
	};
	// const today = new Date().toISOString().split("T")[0];
	// const options = { timeZone: "Asia/Kolkata" }; // 'Asia/Kolkata' is the timezone identifier for Indian Standard Time

	// const todayInIST = new Date().toLocaleString("en-US", options).split(",")[0];

	const ISTOffset = 330; // 5 hours and 30 minutes in minutes
	const todayInIST = new Date(new Date().getTime() + ISTOffset * 60000)
		.toISOString()
		.split('T')[0];
	return (
		<div className='pageSize'>
			<Meta />
			{scheduleAppointmentDateByDoctorerror && (
				<Message variant='danger'>
					<button
						type='button'
						className='close'
						data-dismiss='alert'
						aria-label='Close'
						onClick={toggleShowInfoPopup1}>
						<span aria-hidden='true'>&times;</span>
					</button>
					{scheduleAppointmentDateByDoctorerror}
				</Message>
			)}

			{scheduleAppointmentDateByDoctorloading && <Loader />}
			<a
				className='btn offset-1 back-button mt-2'
				role='button'
				href={`/clinic/kavithadentalcare/doctor/patientstobevisited`}>
				<i className='fa fa-angle-left custom fa-2x'></i>
			</a>

			<Container className='card mt-4'>
				<div className='row mt-4 mb-2 section'>
					<div className='col-md-4'>Schedule Next Appointment</div>
				</div>
				<div className='row mt-4 mb-2 section'>
					<div className='col-md-3'>
						<div className='col-md-6'>Date:</div>
						<input
							type='date'
							className='form-control'
							onChange={(e) => setNextDate(e.target.value)}
							value={nextDate}
							min={todayInIST}
						/>
					</div>
					<div className='col-md-8 offset-md-1'>
						<div className='col-md-6'>Reason:</div>
						<input
							type='text'
							placeholder='Enter Reason'
							className='form-control col-md-12'
							onChange={(e) => setNextMess(e.target.value)}
							value={nextMess}
						/>
					</div>
				</div>
				<div className='row mt-4 mb-2 offset-md-8 section'>
					<button className='btn btn-success' onClick={submitNextHandler}>
						Schedule Next Visit
					</button>
					<button
						className='offset-md-1 btn btn-danger'
						onClick={handleClearData1}>
						Clear
					</button>
				</div>
			</Container>
		</div>
	);
};

export default NextAppointmentScreen;

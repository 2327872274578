/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import Meta from "../../../Components/Meta";
import Message from "../../../Components/Message";
import Form from "react-bootstrap/Form";
import { Card, Button } from "react-bootstrap";
import { Col, Row, Container } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Modal } from "react-bootstrap";
import {
	createInventoryCategory,
	getInventoryCategory,
} from "../../../redux/inventoryRedux/inventoryApiCalls";
import { useParams } from "react-router-dom";

const InventoryCategoryScreens = () => {
	const { clinicName } = useParams();

	const dispatch = useDispatch();
	const [category, setCategory] = useState("");
	const [description, setDescription] = useState("");

	useEffect(() => {
		dispatch(getInventoryCategory(clinicName));
	}, []);

	const { getInventorycategory } = useSelector(
		(state) => state.getInventoryCategory
	);

	console.log(getInventorycategory);

	const { createInventorycategory, loading } = useSelector(
		(state) => state.createInventoryCategory
	);

	useEffect(() => {
		console.log(clinicName);
		dispatch(getInventoryCategory(clinicName));
	}, [loading]);

	const submitHandler = (e) => {
		e.preventDefault();
		console.log(category);

		dispatch(createInventoryCategory(category, description, clinicName));
		setShow(false);
		setCategory("");
		setDescription("");
	};

	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	let count = 0;

	return (
		<>
			<Meta />
			<h1 className="header-center mt-3">Inventory Category Management</h1>
			<Container fluid className="mt-5">
				<Row className="justify-content-center">
					<Card className="w-100 ml-3 mr-3">
						<Card.Body>
							<div className="d-flex justify-content-between align-items-center mb-2">
								<Card.Title className="mb-0">Categories List</Card.Title>
								<Button variant="primary" onClick={handleShow}>
									Add New Type
								</Button>
							</div>
							<hr style={{ margin: "0 -1.25rem 1rem -1.25rem" }} />
							<Table bordered className="custom-table">
								<thead>
									<tr>
										<th scope="col">S. No</th>
										<th scope="col"> Type Name</th>
										<th scope="col">Description</th>
									</tr>
								</thead>
								<tbody>
									{getInventorycategory?.data?.data?.map((item) => {
										if (item?.name !== "hospital") {
											count++;
											return (
												<tr key={count}>
													<td>{count}</td>
													<td>{item?.name}</td>
													<td>{item?.description}</td>
												</tr>
											);
										}
										return null;
									})}
								</tbody>
							</Table>
						</Card.Body>
					</Card>
				</Row>
			</Container>
			{/*  */}

			<Modal show={show} onHide={handleClose} size="lg">
				<Modal.Header closeButton>
					<Modal.Title>Add New Type</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form className="form-group" noValidate="true">
						<Container className="header-center mt-3">
							<Row className="justify-content-center">
								<Col md="3" className="text-center mt-1">
									<label htmlFor="name">
										<b>Category Name: </b>
										<span className="text-danger">
											<b>*</b>
										</span>
									</label>
								</Col>
								<Col md="6" className="text-center mt-1">
									<input
										className="form-control"
										id="speciality"
										type="text"
										required="required"
										value={category}
										onChange={(e) => setCategory(e.target.value)}
									/>
								</Col>
							</Row>
							<Row className="justify-content-center">
								<Col md="3" className="text-center mt-1">
									<label htmlFor="name">
										<b>Description: </b>
									</label>
								</Col>
								<Col md="6" className="text-center mt-1">
									<textarea
										className="form-control"
										id="speciality"
										type="text"
										required="required"
										value={description}
										onChange={(e) => setDescription(e.target.value)}
									></textarea>
								</Col>
							</Row>
						</Container>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
					<Button variant="primary" onClick={submitHandler}>
						Save Changes
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default InventoryCategoryScreens;

/** @format */

import Message from '../../../Components/Message';
import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../Components/Loader';
import Meta from '../../../Components/Meta';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Button from 'react-bootstrap/Button';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Pagination from 'react-bootstrap/Pagination';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
} from 'reactstrap';
import Form from 'react-bootstrap/Form';
import {
	createLeave,
	deleteLeave,
	getLeave,
	blockSlot,
	unBlockSlot,
} from '../../../redux/doctorRedux/doctorApiCalls';
import { getTimeslots } from '../../../redux/userApiCalls';
import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from '../../../redux/allMessages';

import { createLeaveReset } from '../../../redux/doctorRedux/Leave_Management/createLeave';
import { deleteLeavesReset } from '../../../redux/doctorRedux/Leave_Management/deleteLeave';
import { getLeavesReset } from '../../../redux/doctorRedux/Leave_Management/getLeaves';
import { blockSlotReset } from '../../../redux/doctorRedux/Slot_Management/blockSlots';
import { unBlockSlotReset } from '../../../redux/doctorRedux/Slot_Management/unBlockSlots';
import { TimeSlotGetReset } from '../../../redux/getTimeSlots';
import { useParams } from 'react-router-dom';
const DoctorCreateLeavesScreen = () => {
	const { clinicName } = useParams();
	const dispatch = useDispatch();
	const history = useNavigate();
	// success and failure Message
	const doctorLogin = useSelector((state) => state.doctorLogin);
	const { doctorInfo } = doctorLogin;
	const getTimeSlotsData = useSelector((state) => state.getTimeSlots);
	const getTimeSlotssuccess = getTimeSlotsData.success;
	const getTimeSlotserror = getTimeSlotsData.error;
	const getTimeSlotsloading = getTimeSlotsData.loading;

	const { getLeaves, success, error, loading } = useSelector(
		(state) => state.getLeaves
	);

	const createLeaveData = useSelector((state) => state.createLeaves);
	const createLeavesuccess = createLeaveData.success;
	const createLeaveerror = createLeaveData.error;
	const createLeaveloading = createLeaveData.loading;

	function convertToDate(inputNumber) {
		const inputString = inputNumber.toString();
		const year = inputString.slice(0, 4);
		const month = inputString.slice(4, 6);
		const day = inputString.slice(6, 8);

		const formattedDate = year + '-' + month + '-' + day;
		return formattedDate;
	}

	function addOneDay(inputDate) {
		const dateIP = convertToDate(
			doctorInfo?.user?.appointmentDates[
				doctorInfo?.user?.appointmentDates?.length - 1
			]?.date
		);
		const dateParts = dateIP.split('-');
		const year = parseInt(dateParts[0], 10);
		const month = parseInt(dateParts[1], 10) - 1; // Month is 0-indexed
		const day = parseInt(dateParts[2], 10);

		const originalDate = new Date(year, month, day);
		originalDate.setDate(originalDate.getDate() + 1);

		const yearNew = originalDate.getFullYear();
		const monthNew = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
		const dayNew = originalDate.getDate().toString().padStart(2, '0');

		const formattedDate = yearNew + '-' + monthNew + '-' + dayNew;
		return formattedDate;
	}

	// // Create Leaves
	// var date = new Date();
	// const dt = new Date(
	// 	date.getTime() + doctorInfo?.user?.bookingPeriod * 24 * 60 * 60 * 1000
	// );

	let temp = addOneDay(
		doctorInfo?.user?.appointmentDates[
			doctorInfo?.user?.appointmentDates?.length - 1
		]?.date
	);

	var date1 =
		temp.split('-')[1] + '-' + temp.split('-')[2] + '-' + temp.split('-')[0]; //mon,date,year

	const months = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];

	const returnDefaultDateFormat = addOneDay(
		doctorInfo?.user?.appointmentDates[
			doctorInfo?.user?.appointmentDates?.length - 1
		]?.date
	); //year,mon,date

	const [createStartDate, setCreateStartDate] = useState(
		returnDefaultDateFormat
	);
	const [createEndDate, setCreateEndDate] = useState(returnDefaultDateFormat);
	const [createReason, setCreateReason] = useState('');

	var actualDate = 0;
	const handleCallback = (start, end, label) => {
		//Start Date Format from input
		//Month Formating from input
		actualDate = start;
		let rSMon = (
			months.indexOf(start._d.toString().split(' ')[1]) + 1
		).toString();
		if (rSMon.length === 1) rSMon = 0 + '' + rSMon;
		//Date Formating from input
		let rSDate = start._d.toString().split(' ')[2].toString();
		//Year Formating from input
		let rSYear = start._d.toString().split(' ')[3].toString();
		let rSModDate = rSYear + '-' + rSMon + '-' + rSDate;

		//End Date Format from input
		//Month Formating from input
		let rEMon = (
			months.indexOf(end._d.toString().split(' ')[1]) + 1
		).toString();
		if (rEMon.length === 1) rEMon = 0 + '' + rEMon;
		//Date Formating from input
		let rEDate = end._d.toString().split(' ')[2].toString();
		//Year Formating from input
		let rEYear = end._d.toString().split(' ')[3].toString();
		let rEModDate = rEYear + '-' + rEMon + '-' + rEDate;

		setCreateStartDate(rSModDate);
		setCreateEndDate(rEModDate);
	};

	var days = [];
	const doctorAppointmentDates = doctorInfo?.user?.appointmentDates;
	const formattedDates = doctorAppointmentDates?.map((entry) => {
		const dateString = entry.date.toString();

		// Extract year, month, and day
		const year = dateString.slice(0, 4);
		const month = dateString.slice(4, 6);
		const day = ('0' + dateString.slice(6, 8)).slice(-2);

		// Create the formatted date string

		days.push({ currDay: `${day}-${month}-${year}`, key: entry.date });
	});
	console.log(days);

	var monthNames = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];
	console.log(days[days.length - 1]?.currDay);
	var parts = days[days.length - 1]?.currDay.split('-'); // Split the date string
	var day = parseInt(parts[0]); // Parse day as integer
	var month = parseInt(parts[1]) - 1; // Parse month as integer (subtract 1 because month is zero-based)
	var year = parseInt(parts[2]);
	console.log(day, month, year);
	var date = new Date(year, month, day);
	date.setDate(date.getDate() + 1); // Add one day to the date

	console.log(date);

	// Get the day, month, and year of the updated date
	var newDay = date.getDate();
	var newMonth = date.getMonth() + 1; // Add 1 because month is zero-based
	var newYear = date.getFullYear();

	// Construct the formatted date string
	var LeavesStart = newDay + ' ' + monthNames[newMonth - 1] + ' ' + newYear;

	console.log(LeavesStart);

	const submitCreateHandler = () => {
		const date1 = new Date(createStartDate);
		const date2 = new Date();
		const diffTime = Math.abs(date2 - date1);
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		console.log(createStartDate, createEndDate + 'line131');
		if (date1 < date2 || diffDays < 7) {
			alert('Booking for leaves must be starting from ' + LeavesStart);
			let datenw = addOneDay(days[days.length - 1]?.currDay);
			datenw =
				datenw.split('/')[2] +
				'-' +
				datenw.split('/')[0] +
				'-' +
				datenw.split('/')[1];
			setCreateStartDate(datenw);
			setCreateEndDate(datenw);
		} else if (createReason == '') {
			alert('Please submit the reason for leave');
		} else {
			dispatch(
				createLeave(
					doctorInfo?.user?._id,
					createStartDate,
					createEndDate,
					createReason,
					clinicName
				)
			);
			setCreateStartDate(returnDefaultDateFormat);
			setCreateEndDate(returnDefaultDateFormat);
			setCreateReason('');
		}
	};

	//ALerts//////////////////////////////////////////////////////////////////

	const [timerRunning, setTimerRunning] = useState(false);
	useEffect(() => {
		dispatch(allMessagesStart());
	}, []);
	const alertmsg = useSelector((state) => state.allMessages);

	const [showPopup1, setShowPopup1] = React.useState(false);
	const toggleShowInfoPopup1 = (e) => {
		setShowPopup1(!showPopup1);
		e.preventDefault();
		console.log('error');
		dispatch(createLeaveReset());
	};

	useEffect(() => {
		if (createLeavesuccess && !timerRunning) {
			setTimerRunning(true);

			const timer = setTimeout(() => {
				// Clear the alert and stop the timer after 3 seconds
				dispatch(createLeaveReset());

				setTimerRunning(false);
			}, 3000);

			// Clean up the timer if the component unmounts or the alert changes
		}
	}, [createLeavesuccess, dispatch]);

	const [showPopup5, setShowPopup5] = React.useState(false);
	const toggleShowInfoPopup5 = (e) => {
		setShowPopup5(!showPopup5);
		e.preventDefault();
		console.log('error');
		dispatch(getLeavesReset());
	};

	const [showPopup6, setShowPopup6] = React.useState(false);
	const toggleShowInfoPopup6 = (e) => {
		setShowPopup6(!showPopup6);
		e.preventDefault();
		console.log('error');
		dispatch(TimeSlotGetReset());
	};

	return (
		<div className='pageSize'>
			<Meta />
			{error && (
				<Message variant='danger'>
					<button
						type='button'
						className='close'
						data-dismiss='alert'
						aria-label='Close'
						onClick={toggleShowInfoPopup5}>
						<span aria-hidden='true'>&times;</span>
					</button>
					{error}
				</Message>
			)}
			{getTimeSlotserror && (
				<Message variant='danger'>
					<button
						type='button'
						className='close'
						data-dismiss='alert'
						aria-label='Close'
						onClick={toggleShowInfoPopup6}>
						<span aria-hidden='true'>&times;</span>
					</button>
					{getTimeSlotserror}
				</Message>
			)}
			{createLeaveerror && (
				<Message variant='danger'>
					<button
						type='button'
						className='close'
						data-dismiss='alert'
						aria-label='Close'
						onClick={toggleShowInfoPopup1}>
						<span aria-hidden='true'>&times;</span>
					</button>
					{createLeaveerror}
				</Message>
			)}
			{(loading || getTimeSlotsloading || createLeaveloading) && <Loader />}
			<Breadcrumb>
				<Breadcrumb.Item href={`/clinic/kavithadentalcare/doctor/createleaves`}>
					Apply Leaves
				</Breadcrumb.Item>
				<Breadcrumb.Item href={`/clinic/kavithadentalcare/doctor/deleteleaves`}>
					Cancel Leaves
				</Breadcrumb.Item>
			</Breadcrumb>
			<div>
				<h3 className='header-center'>Apply Leaves</h3>
				<Container className='header-center mt-4 mb-5'>
					<Row className='justify-content-center'>
						<Col md='3' className='text-center mt-1'>
							<label className='form-label' htmlFor='name'>
								<b>Select Date: </b>
								<span className='text-danger'>
									<b>*</b>
								</span>
							</label>
						</Col>
						<Col md='6' className='text-center mt-1'>
							<DateRangePicker
								initialSettings={{ startDate: date1, endDate: date1 }}
								onCallback={handleCallback}>
								<input className='form-control col-md-8' type='text' />
							</DateRangePicker>
						</Col>
					</Row>
					<Row className='justify-content-center'>
						<Col md='3' className='text-center mt-1'>
							<label className='form-label' htmlFor='name'>
								<b>Reason for leave: </b>
								<span className='text-danger'>
									<b>*</b>
								</span>
							</label>
						</Col>
						<Col md='6' className='text-center mt-1'>
							<input
								className='form-control col-md-8'
								id='desc'
								type='text'
								placeholder='Enter reason'
								required='required'
								value={createReason}
								onChange={(e) => setCreateReason(e.target.value)}
							/>
						</Col>
					</Row>
					<Row className='justify-content-center'>
						<h6 className='mt-5 header-center text-danger'>
							<b>Note: Leaves from {LeavesStart} are acceptable.</b>
						</h6>
					</Row>
				</Container>
				<Container className='header-center mt-4 mb-5'>
					<Row className='justify-content-center'>
						<Col md='auto' className='text-center mt-1'>
							<button className='btn btn-success' onClick={submitCreateHandler}>
								Apply
							</button>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
};

export default DoctorCreateLeavesScreen;

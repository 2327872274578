/** @format */

import React, { useState } from 'react';
import { Alert } from 'reactstrap';

const Message = ({ variant, children }) => {
	const [showPopup, setShowPopup] = React.useState(false);

	const toggleShowInfoPopup = () => {
		setShowPopup(!showPopup);
	};

	return (
		<>
			{(() => {
				if (!showPopup) {
					return (
						<>
							<div
								className={`alert alert-${variant} d-none d-md-block`}
								style={{
									position: 'fixed',
									width: '20%',
									right: '1rem',
								}}
								role='alert'>
								{children}
							</div>
							<div
								className={`alert alert-${variant} d-block d-md-none`}
								style={{
									zIndex: 9999, // Add this line to ensure the alert is on top
								}}
								role='alert'>
								{children}
							</div>
						</>
					);
				}
			})()}
		</>
	);
};

Message.defaultProps = {
	variant: 'danger',
};

export default Message;
